var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-popover',{attrs:{"offset":"17","placement":"top","popoverBaseClass":"table-header-popover tooltip popover","trigger":"manual","open":_vm.popoverOpen},on:{"hide":_vm.closePopover}},[_c('font-awesome-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
            content: _vm.headerValueFormat ? `Click for format options` : '',
            placement: 'bottom',
            offset: '10px',
        }),expression:"{\n            content: headerValueFormat ? `Click for format options` : '',\n            placement: 'bottom',\n            offset: '10px',\n        }"}],staticClass:"controls-icon",attrs:{"icon":['fad', 'pencil-ruler']},on:{"click":_vm.openPopover}}),_c('template',{slot:"popover"},[(_vm.headerValueFormat)?_c('div',{staticClass:"popover-content table-header-popover",on:{"mouseleave":_vm.closePopover}},[_c('div',{staticClass:"option"},[_c('div',{staticClass:"label"},[_vm._v("No. decimal places:")]),_c('div',{staticClass:"btn-wrapper"},_vm._l((_vm.numberOfDPlacesOptions),function(nDp){return _c('div',{key:nDp,staticClass:"option-btn",class:_vm.currentNumberOfDecimalPlaces === nDp
                                ? 'active'
                                : '',on:{"click":function($event){return _vm.updateNoDecimalPlace(nDp)}}},[_vm._v(" "+_vm._s(nDp)+" ")])}),0)]),_c('div',{staticClass:"option"},[_c('div',{staticClass:"label"},[_vm._v("Comma Separated:")]),_c('div',{staticClass:"btn-wrapper"},_vm._l(([true, false]),function(commaSeparated){return _c('div',{key:commaSeparated.toString(),staticClass:"option-btn",class:{
                            active: _vm.newFormat.hideCommas === commaSeparated,
                        },on:{"click":function($event){return _vm.updateCommaSeparated()}}},[(commaSeparated)?_c('font-awesome-icon',{staticClass:"icon check",attrs:{"icon":['fas', 'check']}}):_vm._e(),(!commaSeparated)?_c('font-awesome-icon',{staticClass:"icon time",attrs:{"icon":['fas', 'times']}}):_vm._e()],1)}),0)]),_c('div',{staticClass:"option"},[_c('div',{staticClass:"label"},[_vm._v("Currency:")]),_c('div',{staticClass:"btn-wrapper"},_vm._l((_vm.currencies),function(currency){return _c('div',{key:currency.name,staticClass:"option-btn",class:{
                            active: _vm.newFormat.prefix === currency.text,
                            wide: currency.text === '',
                        },on:{"click":function($event){return _vm.updateCurrency(currency.text)}}},[(currency.icon)?_c('font-awesome-icon',{staticClass:"icon",attrs:{"icon":['fas', currency.icon]}}):_c('div',{},[_vm._v(" "+_vm._s(currency.text ? currency.text : "None")+" ")])],1)}),0)]),_c('div',{staticClass:"option"},[_c('div',{staticClass:"label"},[_vm._v("Units:")]),_c('div',{staticClass:"btn-wrapper"},_vm._l((_vm.units),function(unit){return _c('div',{key:unit.name,staticClass:"option-btn",class:{
                            wide: unit.text === '',
                            active: _vm.newFormat.suffix === unit.text,
                        },on:{"click":function($event){return _vm.updateFormat(unit.name)}}},[(unit.icon)?_c('font-awesome-icon',{staticClass:"icon",attrs:{"icon":['fas', unit.icon]}}):_c('div',{},[_vm._v(" "+_vm._s(unit.text ? unit.text : "None")+" ")])],1)}),0)]),_c('div',{directives:[{name:"close-popover",rawName:"v-close-popover"}],staticClass:"btn",on:{"click":function($event){return _vm.submitFomatting()}}},[_vm._v(" "+_vm._s(_vm.modalSettings ? "Add new format" : "Apply Changes")+" ")])]):_vm._e()])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }