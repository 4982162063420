import Vue from 'vue';
import TagCategory from '@/common/TagCategory';
import UILabel from '@/common/UILabel';
import UIDrilldown from '@/common/UIDrilldown';
import TextName from '@/common/TextName';
import ValueFormat from '@/common/ValueFormat';
import JSZip from 'jszip';
import fileSave from 'file-saver';
import UIDrilldownOptions from '@/common/UIDrilldownOptions';
import ErrorModal from '@/common/ErrorModal';
import Choice from '@/common/Choice';

interface Store {
    alternateChart: boolean,
    chartLegend: boolean;
    columns: number,
    darkMode: boolean,
    showTour: boolean,
    drilldown: UIDrilldown[],
    drilldownOptions: { [key: string]: UIDrilldownOptions },
    errorModal: ErrorModal;
    exportChartsTrigger: number,
    exportingCharts: { fileName: string, chart: any }[],
    fromReservingClass: string
    hideXAxis: boolean,
    hideYAxis: boolean,
    inactivity: boolean,
    labels: UILabel[],
    loader: boolean,
    loaderText: string,
    noneTrend: boolean,
    pageWrapperHeight: number,
    pageWrapperWidth: number,
    projectionLoader: boolean,
    refreshPage: boolean,
    requestingData: boolean,
    reservingClassDetailModal: any;
    rows: number,
    showAdvancedChartExport: boolean;
    showDrilldown: boolean,
    showDrilldownBar: boolean,
    showPrintModal: boolean,
    showSettingsModal: boolean,
    pendingChanges: boolean,
    showSideBar: boolean,
    currentShortcut: Choice|null,
    shortcutChanges: boolean,
    sideBarType: string,
    tags: { [key: string]: TagCategory },
    updatingCharts: boolean,
    redirectOptions: { [key: string]: any },
    showAverages: boolean,
}

export const ui: Store = Vue.observable({
    alternateChart: false,
    chartLegend: false,
    columns: 3,
    darkMode: true,
    showTour: false,
    drilldown: [],
    drilldownOptions: {},
    errorModal: null,
    exportChartsTrigger: 0,
    exportingCharts: [],
    fromReservingClass: '',
    hideXAxis: false,
    hideYAxis: false,
    inactivity: false,
    labels: [],
    loader: false,
    loaderText: 'Loading',
    noneTrend: false,
    pageWrapperHeight: 0,
    pageWrapperWidth: 0,
    projectionLoader: false,
    refreshPage: false,
    requestingData: false,
    reservingClassDetailModal: null,
    rows: 2,
    showAdvancedChartExport: false,
    showDrilldown: false,
    showDrilldownBar: false,
    showPrintModal: false,
    showSettingsModal: false,
    showSideBar: false,
    pendingChanges: false,
    currentShortcut: null,
    shortcutChanges: false,
    sideBarType: '',
    tags: {},
    updatingCharts: false,
    redirectOptions: {},
    showAverages: false,
});

export const uiUtil = {
    getLabel: (key: string): string => {
        const index: number = ui.labels.findIndex((l: UILabel) => l.name === key);
        if (index !== -1) {
            return ui.labels[index].text;
        }
        return key;
    },
    getLabelValue: (keyLabel: string, keyValue: string): string => {
        let indexLabel: number = ui.labels.findIndex((l: UILabel) => l.name === keyLabel);
        if (indexLabel === -1) indexLabel = ui.labels.findIndex((l: UILabel) => l.text === keyLabel);
        if (indexLabel !== -1) {
            if (ui.labels[indexLabel].valueFormat) {
                const indexValue: number = ui.labels[indexLabel].valueFormat.findIndex((v: (TextName | ValueFormat)) => v.name === keyValue);
                if (indexValue !== -1) {
                    return ui.labels[indexLabel].valueFormat[indexValue].text;
                }
            }
        }
        return keyValue;
    },
    getFilterOptions: (keyLabel: string): (TextName | ValueFormat)[] => {
        const indexLabel: number = ui.labels.findIndex((l: UILabel) => l.name === keyLabel);
        if (indexLabel === -1) return [];

        let valueOptions = ui.labels[indexLabel].valueFormat.map((v) => v);

        valueOptions = valueOptions.sort((a: (TextName | ValueFormat), b: (TextName | ValueFormat)) => {
            if (a.text > b.text) {
                return 1;
            }
            if (b.text > a.text) {
                return -1;
            }
            return 0;
        });
        return valueOptions;
    },
    getFilterOptionsLength: (keyLabel: string): number => {
        const indexLabel: number = ui.labels.findIndex((l: UILabel) => l.name === keyLabel);
        if (indexLabel === -1) return 0;

        return Object.prototype.hasOwnProperty.call(ui.labels[indexLabel], 'valueFormat') ? ui.labels[indexLabel].valueFormat.length : -1;
    },
};

export const updateUI = {
    updateInactivity(setting: boolean) {
        ui.inactivity = setting;
    },
    setRows(count: number) {
        ui.rows = count;
    },
    setColumns(count: number) {
        ui.columns = count;
    },
    setLoader(bool: boolean, loaderText: string = 'Loading') {
        if (bool && loaderText.length > 0) {
            ui.loaderText = loaderText;
        }
        ui.loader = bool;
    },
    setProjectionLoader(bool: boolean) {
        ui.projectionLoader = bool;
    },
    setPendingChanges(bool: boolean) {
        ui.pendingChanges = bool;
    },
    setDarkMode(bool: boolean) {
        ui.darkMode = bool;
    },
    setShowTour(bool: boolean) {
        ui.showTour = bool;
    },
    setShowPrintModal(bool: boolean) {
        ui.showPrintModal = bool;
    },
    setRequestingData(bool: boolean) {
        ui.requestingData = bool;
    },
    toggleSideBar() {
        ui.showSideBar = !ui.showSideBar;
        if (ui.showSideBar === true) {
            ui.showDrilldownBar = false;
        }
    },
    toggleDrilldownBar() {
        ui.showDrilldownBar = !ui.showDrilldownBar;
        if (ui.showDrilldownBar === true) {
            ui.sideBarType = '';
            ui.showSideBar = false;
        }
    },
    setShowDrilldownBar(bool: boolean) {
        ui.showDrilldownBar = bool;
        if (ui.showDrilldownBar === true) {
            ui.sideBarType = '';
            ui.showSideBar = false;
        }
    },
    setShowDrilldown(bool: boolean) {
        ui.showDrilldown = bool;
        if (ui.showDrilldown === true) {
            ui.sideBarType = '';
            ui.showSideBar = false;
        }
    },
    setShowSideBar(bool: boolean) {
        ui.showSideBar = bool;
        if (ui.showSideBar === true) {
            ui.showDrilldownBar = false;
        }
    },
    updateLabels(labels: UILabel[]) {
        ui.labels = labels;
    },
    updateDrilldown(drilldowns: UIDrilldown[]) {
        ui.drilldown = drilldowns;
    },
    resetTags() {
        ui.tags = {};
    },
    updateTags(Tag: TagCategory) {
        Vue.set(ui, 'tags', Tag);
    },
    updateNoneTrend(value: boolean) {
        ui.noneTrend = value;
    },
    updateSideBarType(type: string) {
        ui.sideBarType = type;
    },
    setDrilldownOptions(options: { [key: string]: UIDrilldownOptions }) {
        Vue.set(ui, 'drilldownOptions', options);
    },
    updateHideXAxis(bool: boolean) {
        ui.hideXAxis = bool;
    },
    updateHideYAxis(bool: boolean) {
        ui.hideYAxis = bool;
    },
    updateAlternateChart(bool: boolean) {
        ui.alternateChart = bool;
    },
    updateChartLegend(bool: boolean) {
        ui.chartLegend = bool;
    },
    updateErrorModal(errorModalConfig: ErrorModal) {
        ui.errorModal = errorModalConfig;
    },
    updateCurrentShortcut(currentShortcut: Choice) {
        ui.currentShortcut = currentShortcut;
    },
    updateCurrentShortcutName(name: string) {
        ui.currentShortcut.text = name;
    },
    updateShortcutChanges(bool: boolean) {
        ui.shortcutChanges = bool;
    },
    toggleShowSettingsModal() {
        ui.showSettingsModal = !ui.showSettingsModal;
    },
    updateExportChartsTrigger() {
        ui.exportingCharts = [];
        ui.exportChartsTrigger += 1;
        if (ui.rows > 2) {
            const zip = new JSZip();
            setTimeout(() => {
                ui.exportingCharts.forEach((chart) => {
                    const fileName = chart.fileName;
                    zip.file(`${fileName}.svg`, chart.chart);
                });
                zip.generateAsync({ type: 'blob' }).then((content) => {
                    fileSave.saveAs(content, 'Charts.zip');
                });
            }, 4000);
        }
    },
    addExportChart(chart: { fileName: string, chart: any }) {
        ui.exportingCharts.push(chart);
    },
    resetRefreshPage() {
        ui.refreshPage = false;
    },
    updateRefreshPage() {
        ui.refreshPage = !ui.refreshPage;
    },
    setPageWrapperHeight(height: number): void {
        ui.pageWrapperHeight = height;
    },
    setPageWrapperWidth(width: number): void {
        ui.pageWrapperWidth = width;
    },
    setReservingClassDetailModal(reservingClass: any) {
        ui.reservingClassDetailModal = reservingClass;
    },
    setShowAdvancedChartExport(bool: boolean) {
        ui.showAdvancedChartExport = bool;
    },
    setUpdatingCharts(bool: boolean) {
        ui.updatingCharts = bool;
    },
    updateRedirectOptions(newOptions) {
        ui.redirectOptions = newOptions;
    },
    updateShowAverages(value: boolean) {
        ui.showAverages = value;
    },
    resetDefaults() {
        ui.alternateChart = false;
        ui.chartLegend = false;
        ui.columns = 3;
        ui.drilldown = [];
        ui.drilldownOptions = {};
        ui.errorModal = null;
        ui.exportChartsTrigger = 0;
        ui.fromReservingClass = '';
        ui.hideXAxis = false;
        ui.hideYAxis = false;
        ui.inactivity = false;
        ui.labels = [];
        ui.loader = false;
        ui.noneTrend = false;
        ui.projectionLoader = false;
        ui.requestingData = false;
        ui.rows = 2;
        ui.showDrilldown = false;
        ui.showDrilldownBar = false;
        ui.showPrintModal = false;
        ui.showSettingsModal = false;
        ui.showSideBar = false;
        ui.sideBarType = '';
        ui.currentShortcut = null;
        ui.shortcutChanges = false;
        ui.tags = {};
        ui.showAverages = false;
    },

};
