
import { Vue, Component } from 'vue-property-decorator';
import PrettyCheck from 'pretty-checkbox-vue/check';
import { ui, updateUI } from '@/observables/UI';
import { projections } from '@/observables/Projections';
import { VPopover } from 'v-tooltip';
import { IFileDownload } from '@/interfaces/IFileDownload';
import FileDownloadInput from '../../inputs/FileDownloadInput.vue';
import ProjectionsFileDownloadInput from '../../inputs/ProjectionsFileDownloadInput.vue';

@Component({
    components: {
        FileDownloadInput,
        ProjectionsFileDownloadInput,
        PrettyCheck,
        VPopover,
    },
})
export default class FileDownloads extends Vue {
    public uploadedDataNames: string[] = [
        'triangle_data.csv',
        'vector_data.csv',
        'segment_info.csv',
        'metrics.csv',
        'drilldownoptions.csv',
    ];

    public processedData: string[] = [
        'vectors_processed.csv',
        'entity_info_processed.csv',
        'chart_labels.csv',
    ]

    public tableDownloadRoutes: string[] = [
        'manageScenarios', 'reservingClass',
    ]

    public tableData: string[] = [
        'review_reserving_class_table.csv',
    ]

    get downloadTableId() {
        return projections.downloadTableId;
    }

    public extractAllReservingClassesInScenario: boolean = false;

    showPrintModal () {
        updateUI.setShowPrintModal(true);
    }

    get projectionStep () {
        let files = [];

        const commonFiles: Array<IFileDownload> = [
            { id: 'triangles', text: 'All Triangles' },
            { id: 'vectors', text: 'All Vectors' },
            { id: 'reservingClassInfo', text: 'Reserving Classes' },
        ];

        switch (this.$route.name) {
        case 'patternsSelect':
            files = [{ id: 'selectedPatterns', text: 'Selected Patterns' }, { id: 'developmentFactorModelUltimate', text: 'DFM Ultimate Calcs' }];
            break;
        case 'patternsTrending':
            files = [{ id: 'patternsTrending', text: 'Pattern Trending Components' }];
            break;
        case 'patternsExclusions':
            files = [{ id: 'patternsExclusions', text: 'Triangles and Exclusions' }];
            break;
        case 'initialExpecteds':
            files = [{ id: 'initialExpecteds', text: 'Initial Expecteds', addGranularity: true }];
            break;
        case 'methodSelection':
            files = [{ id: 'methodSelection', text: 'Method Selection', addGranularity: true }];
            break;
        case 'projectionsReview':
            files = [{ id: 'results', text: 'Projections Review', addGranularity: true }, { id: 'flightPath', text: 'Flight Path', addGranularity: true }];
            break;
        case 'reservingClass':
            files = [{ id: 'reservingClassTable', text: 'Review Reserving Class Table' }];
            break;
        case 'manageScenarios':
            files = [{ id: 'manageScenariosTable', text: 'Manage Scenarios Table' }];
            break;
        default:
            break;
        }

        if (this.tableDownloadRoutes.includes(this.$route.name)) return files;

        if (files.length > 0) {
            files = files.concat(commonFiles);
        }

        return files;
    }

    get loadingStatus () {
        return !ui.loader;
    }
}
