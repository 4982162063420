export default class ProjectionReviewSave {
    message: string;

    userQuestion: boolean;

    saved: boolean;

    scenarioId: string;

    constructor(message: string = '', userQuestion: boolean = false, saved: boolean = false, scenarioId: string = '') {
        this.message = message;
        this.userQuestion = userQuestion;
        this.saved = saved;
        this.scenarioId = scenarioId;
    }
}
