/* eslint-disable camelcase */
/* eslint-disable no-console */

import { Configuration, AuthenticationResult, AccountInfo } from '@azure/msal-browser';
import vue from 'vue';
import { AuthenticationService } from './AuthenticationService';

let authService: AuthenticationService;

interface AuthState {
    loggedIn: boolean;
    userInfo: AccountInfo | null;
    token: string;
    scopes?: Array<string>
}

export const authState = vue.observable<AuthState>({
    loggedIn: false,
    userInfo: null,
    token: '',
    scopes: [],
});

export const loggedIn = (): boolean => authState.loggedIn;
export const token = (): string => authState.token;
export const userInfo = (): unknown => authState.userInfo;

export function setLoggedIn (newToken: string): void {
    authState.loggedIn = !!newToken;
}

export function setUser (newUserInfo: AccountInfo | null): void {
    authState.userInfo = newUserInfo;
}

function setToken (newToken: string): void {
    authState.token = newToken;
}

export async function login (method: 'loginPopup' | 'loginRedirect' = 'loginPopup'): Promise<void | AuthenticationResult | boolean> {
    if (!authService) return false;
    return authService.login(method);
}

export function logout (): void | boolean {
    if (!authService) return false;
    return authService.logout();
}

export async function getToken (scopes?: Array<string> | null, method: 'loginPopup' | 'loginRedirect' = 'loginPopup'): Promise<string | false | null> {
    if (!authService) return false;
    const newToken = await (authService.getToken(scopes ?? authState.scopes ?? [], method) as Promise<void | AuthenticationResult>).catch(
        (err) => {
        },
    );
    if (newToken) {
        setLoggedIn(newToken.accessToken);
        setToken(newToken.accessToken);
        setUser(newToken.account);
        return newToken.accessToken;
    }
    return null;
}

export async function initialiseAuth (msalConfig: Configuration, customScopes: Array<string>): Promise<AuthenticationService> {
    authState.scopes = customScopes;
    if (!authService) {
        const scopes = {
            scopes: ['openid', ...customScopes],
        };
        authService = new AuthenticationService(msalConfig as Configuration, scopes);
    }
    await getToken(customScopes);
    return authService;
}
