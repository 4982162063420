import axios from 'axios';
import { addError, addMessage } from '../messages';
import { getToken } from '../MsalAuth';
import ApiResponse from '../classes/apiResponse';
import ApiOptions from '../classes/apiOptions';

export default class Http {
    public static get<T>(url: string, options?: ApiOptions): Promise<ApiResponse<T>> {
        return this.request(url, 'get', options) as Promise<ApiResponse<T>>;
    }

    static getAsArray<T>(url: string, options?: ApiOptions): Promise<ApiResponse<Array<T>>> {
        return this.request<T>(url, 'get', options) as Promise<ApiResponse<Array<T>>>;
    }

    // eslint-disable-next-line default-param-last
    public static delete<T>(url: string, data: unknown = null, options?: ApiOptions): Promise<ApiResponse<T>> {
        return this.dataRequest(url, data, 'delete', options) as Promise<ApiResponse<T>>;
    }

    public static post<T>(url: string, data: unknown, options?: ApiOptions): Promise<ApiResponse<T>> {
        return this.dataRequest(url, data, 'post', options) as Promise<ApiResponse<T>>;
    }

    public static put<T>(url: string, data: unknown, options?: ApiOptions): Promise<ApiResponse<T>> {
        return this.dataRequest(url, data, 'put', options) as Promise<ApiResponse<T>>;
    }

    public static patch<T>(url: string, data: unknown, options?: ApiOptions): Promise<ApiResponse<T>> {
        return this.dataRequest(url, data, 'patch', options) as Promise<ApiResponse<T>>;
    }

    // eslint-disable-next-line default-param-last
    private static async request<T>(url: string, method: 'get' | 'delete' = 'get', options?: ApiOptions): Promise<ApiResponse<T> | ApiResponse<Array<T>> > {
        const apiOptions = Object.assign(new ApiOptions(), options);

        const token = apiOptions?.useToken ? await getToken(apiOptions?.apiScope) : '';
        return axios.request<string, ApiResponse<T>>({
            method,
            url: apiOptions?.useBasePath ? `${url}` : url,
            params: apiOptions?.params,
            ...{
                ...apiOptions?.config,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: apiOptions?.useToken ? `Bearer ${token}` : null,
                    ...apiOptions?.config?.headers,
                },
            },
        }).then((response) => {
            if (apiOptions?.showSuccessMessage) {
                addMessage({
                    status: 'Success', title: apiOptions?.successTitle, message: apiOptions?.message || 'Success', popup: apiOptions?.showPopup,
                });
            }
            return response;
        }).catch((error) => {
            addError({ ...error, message: apiOptions?.errorMessage || error, title: apiOptions?.errorTitle });
            throw error;
        });
    }

    // eslint-disable-next-line default-param-last
    private static async dataRequest<T>(url: string, data: unknown, method: 'post' | 'put' | 'patch' | 'delete' = 'post', options?: ApiOptions): Promise<ApiResponse<T> | ApiResponse<Array<T>>> {
        const apiOptions = Object.assign(new ApiOptions(), options);
        const token = apiOptions?.useToken ? await getToken(apiOptions?.apiScope) : '';
        return axios.request<string, ApiResponse<T>>({
            method,
            url: apiOptions?.useBasePath ? `${url}` : url,
            data,
            params: apiOptions?.params,
            ...{
                ...apiOptions?.config,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: apiOptions?.useToken ? `Bearer ${token}` : null,
                    ...apiOptions?.config?.headers,
                },
            },
        }).then((response) => {
            if (apiOptions?.showSuccessMessage) {
                addMessage({
                    status: 'Success', title: apiOptions?.successTitle, message: apiOptions?.message || 'Success', popup: apiOptions?.showPopup,
                });
            }
            return response;
        }).catch((error) => {
            addError({ ...error, message: apiOptions?.errorMessage || error, title: apiOptions?.errorTitle });
            throw error;
        });
    }
}
