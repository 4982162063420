
import { Vue, Component, Prop } from 'vue-property-decorator';
import { ui } from '@/observables/UI';
import { BeatLoader } from '@saeris/vue-spinners';
import CardIllustration from './CardIllustration.vue';
import DotLoader from '../DotLoader.vue';

@Component({
    components: {
        CardIllustration,
        BeatLoader,
        DotLoader,
    },
})
export default class Card extends Vue {
    @Prop() id!: string;

    @Prop(Boolean) popoverCard!: boolean;

    @Prop() contentText?: string;

    @Prop() title!: string;

    @Prop() headTitle?: string;

    @Prop() ctaText?: string;

    @Prop() customColour?: string;

    @Prop() selected?: boolean;

    @Prop() actionsAlwaysVisible?: boolean;

    @Prop({ default: false }) editTitle?: boolean;

    @Prop({ default: false }) showLoader: boolean;

    cardClick(event) {
        if (
            event.target.closest('.actions-wrapper') === null
            && event.target.closest('.info-button') === null
            && this.editTitle === false) {
            this.$emit('click');
        }
    }

    get darkMode() {
        return ui.darkMode;
    }
}
