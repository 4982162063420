var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.showLoader?'Its not possible to access the scenario while a data load is in progress':''),expression:"showLoader?'Its not possible to access the scenario while a data load is in progress':''"}],staticClass:"card-wrapper card-icon",class:{
        'popover-card': _vm.popoverCard,
        'light-mode': !_vm.darkMode,
        loading: _vm.showLoader,
        'selected-card': _vm.selected,
        'actions-wrapper-visible': _vm.actionsAlwaysVisible },on:{"click":_vm.cardClick}},[_vm._t("info"),(_vm.headTitle || _vm.$slots.actionItems)?_c('div',{staticClass:"head-row"},[(_vm.headTitle)?_c('div',{staticClass:"head-title"},[_vm._v(" "+_vm._s(_vm.headTitle)+" ")]):_c('div'),(_vm.$slots.actionItems)?_c('div',{staticClass:"actions-wrapper"},[_vm._t("actionItems")],2):_vm._e()]):_vm._e(),(_vm.editTitle === false)?_c('div',{staticClass:"card-title"},[_vm._v(_vm._s(_vm.title))]):_vm._t("editTitle"),(_vm.contentText)?_c('div',{staticClass:"content-text"},[_vm._v(" "+_vm._s(_vm.contentText)+" ")]):_vm._e(),_vm._t("extra-content"),(_vm.ctaText && !_vm.showLoader)?_c('button',{staticClass:"cta-button",style:(_vm.customColour ? { color: _vm.customColour } : undefined),on:{"click":function($event){return _vm.$emit('cta-clicked')}}},[_vm._v(" "+_vm._s(_vm.ctaText)+" "),_c('span',{staticClass:"arrow-icons"},[_c('span',{staticClass:"stem",style:(_vm.customColour ? { background: _vm.customColour } : undefined)}),_c('span',{staticClass:"head-arrow"},[_c('span',{staticClass:"top-segment",style:(_vm.customColour
                            ? { background: _vm.customColour }
                            : undefined)}),_c('span',{staticClass:"bottom-segment",style:(_vm.customColour
                            ? { background: _vm.customColour }
                            : undefined)})])])]):_vm._e(),_c('div',{staticClass:"illustration-container"},[_c('CardIllustration',{attrs:{"color":_vm.customColour}})],1),_c('div',{staticClass:"card-color-picker"},[_vm._t("colorPicker")],2),(_vm.showLoader)?_c('DotLoader'):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }