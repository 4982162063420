
import { Vue, Component, Watch } from 'vue-property-decorator';
import { ui, updateUI } from '@/observables/UI';
import { updateConfig } from '@/observables/Config';
import Grid from './Grid';

@Component({})
export default class GridLayoutPanel extends Vue {
  check: any = 1;

  labelItems: Grid[] = [
      new Grid('1 x 2', [1, 2], false),
      new Grid('2 x 3', [2, 3], false),
      new Grid('4 x 5', [4, 5], false),
  ];

  created () {
      const index: number = this.labelItems.findIndex((i: Grid) => i.layout[0] === ui.rows);
      if (index > -1) {
          this.labelItems[index].checked = true;
          this.check = index;
      }
  }

  updateGrid (val: any) {
      updateUI.updateSideBarType('');
      updateUI.setShowSideBar(false);
      updateUI.setRows(this.labelItems[val].layout[0]);
      updateUI.setColumns(this.labelItems[val].layout[1]);
      updateUI.updateShortcutChanges(true);
      updateConfig.updateStart(0);
  }

  get rows (): number {
      return ui.rows;
  }

  @Watch('rows')
  updateOptions () {
      const index: number = this.labelItems.findIndex((i: Grid) => i.layout[0] === ui.rows);
      const currentindex: number = this.labelItems.findIndex((i: Grid) => i.checked);
      if (index > -1) {
          if (currentindex > -1) {
              this.labelItems[currentindex].checked = false;
              this.labelItems[index].checked = true;
              this.check = index;
          }
      }
  }
}
