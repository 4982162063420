
import {
    Vue, Component, Prop,
} from 'vue-property-decorator';
import { ITagAndITagData } from '@/interfaces/ITag';

@Component({
    components: {
    },
})
export default class TagButton extends Vue {
    @Prop() tag: ITagAndITagData;

    @Prop() selected: boolean;

    public selectTag() {
        this.$emit('select-tag', this.tag);
    }
}
