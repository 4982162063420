export default class Info {
    id: string = '';

    title: string = '';

    mainTrend: string = '';

    constructor (id: string, title: string, mainTrend: string) {
        this.id = id;
        this.title = title;
        this.mainTrend = mainTrend;
    }
}
