var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('button',{staticClass:"tag add",style:({
        backgroundColor:
            _vm.tag.fillColour || '#00a6c9',
        color: _vm.tag.textColour,
    }),attrs:{"aria-label":`Add tag ${_vm.tag.text}`},on:{"click":_vm.selectTag}},[_vm._v(" "+_vm._s(_vm.tag.text)+" "),_c('span',{staticClass:"icon-container"},[(_vm.selected)?_c('font-awesome-icon',{staticClass:"active-icon",attrs:{"icon":['fas', 'check']}}):_vm._e(),_c('font-awesome-icon',{staticClass:"add-icon",attrs:{"icon":[
                'fas', _vm.selected
                    ? 'minus'
                    : 'plus',
            ]}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }