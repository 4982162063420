import ActiveFilter from '@/common/ActiveFilter';
import TagCategory from './TagCategory';
import ConfigSort from './ConfigSort';

export default class QueryDataSet {
    dataSetId!: string;

    offset!: number;

    limit!: number;

    filters!: {[key: string]: ActiveFilter};

    tags!: {[key: string]: TagCategory};

    trend: {[key: string]: {[key: string]: { [key: string]: any }}};

    sort: ConfigSort[];

    drilldownId: string;

    series!: boolean;

    constructor (
        dataSetId: string,
        offset: number,
        limit: number,
        filters: {[key: string]: ActiveFilter},
        tags: {[key: string]: TagCategory},
        trend: {[key: string]: {[key: string]: { [key: string]: any }}},
        sort: ConfigSort[],
        drilldownId: string,
        series: boolean,
    ) {
        this.dataSetId = dataSetId;
        this.offset = offset;
        this.limit = limit;
        this.filters = filters;
        this.tags = tags;
        this.trend = trend;
        this.sort = sort;
        this.drilldownId = drilldownId;
        this.series = series;
    }
}
