
import {
    Component, Vue, Watch, Prop,
} from 'vue-property-decorator';
import { VPopover } from 'v-tooltip';
import { actionProjections, projections, updateProjections } from '@/observables/Projections';
import { updateUI, ui } from '@/observables/UI';
import TabSwitcher from '@/components/inputs/TabSwitcher.vue';
import SwitchHeader from '@/common/Chart/SwitchHeader';
import { config } from '@/observables/Config';
// import metaDataFormat from '@/functions/metaDataFormat';
import ErrorModal from '@/common/ErrorModal';
import TableHeaderWithFormatOption from '@/components/inputs/TableHeaderWithFormatOption.vue';
import FormatValuesSelector from '@/components/inputs/FormatValuesSelector.vue';
import Http from '@/auth/api/apiHelper';
import { ScaleLoader } from '@saeris/vue-spinners';

const cloneDeep = require('clone-deep');

interface Options {
    name: string,
    text: string,
    icon?: string,
}

@Component({
    components: {
        VPopover,
        TabSwitcher,
        FormatValuesSelector,
        TableHeaderWithFormatOption,
        ScaleLoader,
    },
})
export default class DataFormatModal extends Vue {
    @Prop({ default: true }) detailedAssumptionPages: boolean;

    public currentDataFormatConfig = {};

    public newDataFormatConfig = {};

    public formattedProperties = [];

    public error;

    public properties: Options[] = []

    public detailedAssumptionProperties: Options[] = [
        { name: 'count', text: 'Count' },
        { name: 'currency', text: 'Currency' },
        { name: 'percentage', text: 'Percentage' },
        { name: 'ratio', text: 'Ratio' },
    ]

    public segmentationProperties: Options[] = [
        { name: 'count', text: 'Number' },
        { name: 'currency', text: 'Currency' },
        { name: 'percentage', text: 'Percentage' },
        { name: 'ratio', text: 'Ratio' },
        // { name: 'string', text: 'String' },
        // { name: 'dateTime', text: 'Date Time' },
    ]

    public optionOpen: Options | null = null;

    public loading: boolean = false;

    async created() {
        if (!this.detailedAssumptionPages) {
            if (this.scenarioDataFormat) {
                this.currentDataFormatConfig = cloneDeep(this.scenarioDataFormat);
                this.properties = this.segmentationProperties;
                this.createSwitcherItems();
                this.activeTab = this.switcherItems[0];
            //  this.updateSegmentationDataFormatting();
            } else {
                this.loading = true;
            }
        } else {
            this.properties = this.detailedAssumptionProperties;
            // await actionProjections.getCurrentDataFormattingConfig();
            this.loading = true;
            const currentDataFormatConfigResult = await actionProjections.getCurrentDataFormattingConfig();
            Vue.set(this, 'currentDataFormatConfig', cloneDeep(currentDataFormatConfigResult));
            if (this.switcherItems.length === 0) {
                this.createSwitcherItems();
                this.activeTab = this.switcherItems[0];
            }
            this.loading = false;
        }
    }

    public selectOption(i) {
        this.optionOpen = this.properties[i];
    }

    private createSwitcherItems(): void {
        this.properties.forEach((property, i) => this.switcherItems.push(
            new SwitchHeader(property.name, property.text, 'FormatValuesSelector', { headerKey: i, header: property.name, headerValueFormat: { valueFormat: this.currentDataFormatConfig[property.name].valueFormat } }),
        ));
    }

    public updateFormat(formatData) {
        const valueFormat = { valueFormat: formatData.format };
        Vue.set(this.newDataFormatConfig, this.properties[formatData.key].name, { description: this.currentDataFormatConfig[this.properties[formatData.key].name]?.description, ...valueFormat });
        if (!this.formattedProperties.includes(this.properties[formatData.key].name)) this.formattedProperties.push(this.properties[formatData.key].name);
    }

    public async applyChanges() {
        if (this.isBtnDisabled) return;
        if (this.detailedAssumptionPages) {
            updateProjections.toggleUpdateReservingClassTable(false);
            await this.applyAssumptionFormattingChanges();
            // updateProjections.toggleUpdateReservingClassTable(true);
        } else {
            updateProjections.toggleUpdateReservingClassTable(false);
            await this.applyScenariosFormattingChanges();
            updateProjections.toggleUpdateReservingClassTable(true);
        }
    }

    public async applyAssumptionFormattingChanges() {
        const dataFormatting: any = { ...this.currentDataFormatConfig, ...this.newDataFormatConfig };
        try {
            await Http.post<any>(`${process.env.VUE_APP_API_ENDPOINT_PREDICT}/UpdateErsDataFormatting`, { ersId: projections.ersId, dataFormatting }, { apiScope: [process.env.VUE_APP_API_SCOPE_PREDICT] });
            this.formattedProperties = [];
            this.newDataFormatConfig = {};
            this.currentDataFormatConfig = {};
            const currentDataFormatConfigResult = await actionProjections.getCurrentDataFormattingConfig();
            Vue.set(this, 'currentDataFormatConfig', cloneDeep(currentDataFormatConfigResult));
            updateProjections.updateReservingClassTotalsFormats({ currency: dataFormatting.currency.valueFormat, percentage: dataFormatting.percentage.valueFormat });
            await actionProjections.getProjectionTotals();
            updateUI.updateRefreshPage();
        } catch (error) {
            this.error = error;
            updateUI.updateErrorModal((new ErrorModal('', 'welcome', 'Welcome Page')));
        }
    }

    public async applyScenariosFormattingChanges() {
        const dataFormatting: any = { ...this.currentDataFormatConfig, ...this.newDataFormatConfig };
        try {
            await Http.post<any>(`${process.env.VUE_APP_API_ENDPOINT_PREDICT}/UpdateSegmentationDataFormatting`, { dataSetId: this.dataSetId, segmentationId: this.segmentationId, dataFormatting }, { apiScope: [process.env.VUE_APP_API_SCOPE_PREDICT] });
            this.currentDataFormatConfig = dataFormatting;
            this.newDataFormatConfig = {};
            this.formattedProperties = [];
            updateUI.updateRefreshPage();
            updateProjections.updateReservingClassTotalsFormats({ currency: dataFormatting.currency.valueFormat, percentage: dataFormatting.percentage.valueFormat });
            await actionProjections.getProjectionTotals();
        } catch (error) {
            this.error = error;
            updateUI.updateErrorModal((new ErrorModal('', 'welcome', 'Welcome Page')));
        }
    }

    get ersId() {
        return projections.ersId;
    }

    get segmentationId() {
        return projections.segmentationId;
    }

    get dataSetId() {
        return config.dataSetId;
    }

    @Watch('detailedAssumptionPages')
    async getNewCurrentDataFormattingConfigForErsId() {
        if (this.detailedAssumptionPages) {
            this.currentDataFormatConfig = {};
            const currentDataFormatConfigResult = await actionProjections.getCurrentDataFormattingConfig();
            Vue.set(this, 'currentDataFormatConfig', cloneDeep(currentDataFormatConfigResult));
            this.switcherItems = [];
            this.createSwitcherItems();
            this.activeTab = this.switcherItems[0];
        }
    }

    // #region tabSwitcher

    public switcherItems: SwitchHeader[] = [];

    public activeTab: SwitchHeader | null = null;

    get pageHeight(): number {
        return ui.pageWrapperHeight;
    }

    updateActiveTab (index: number) {
        this.activeTab = this.switcherItems[index];
    }
    // #endregion

    public get currentRoute(): string {
        return this.$route.name;
    }

    @Watch('currentRoute')
    setConfigDependingOnRoute() {
        // if(this.currentRoute === '')
    }

    get isBtnDisabled(): boolean {
        return projections.updateReservingClassTable;
    }

    get scenarioDataFormat() {
        return projections.scenarioDataFormat;
    }

    @Watch('scenarioDataFormat', { deep: true, immediate: true })
    updateScenarioDataFormat() {
        if (this.scenarioDataFormat) {
            this.currentDataFormatConfig = cloneDeep(this.scenarioDataFormat);
            this.properties = this.segmentationProperties;
            this.createSwitcherItems();
            this.activeTab = this.switcherItems[0];
            this.loading = false;
        }
    }

    get disableApplyChangesBtn() {
        if (this.formattedProperties.length === 0) return true;
        return false;
    }
}
