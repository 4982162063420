
import { Vue, Component } from 'vue-property-decorator';
import { ui, updateUI } from '@/observables/UI';
import { config, updateConfig } from '@/observables/Config';
import Tag from '@/common/Tag';
import TagCategory from '@/common/TagCategory';

@Component
export default class TagPanel extends Vue {
    get tagConfig (): {[key: string]: TagCategory} {
        return ui.tags;
    }

    isActive (category: TagCategory, tag: Tag) {
        let isActive = false;

        if (Object.prototype.hasOwnProperty.call(this.currentTags, category.categoryId)) {
            if (Object.prototype.hasOwnProperty.call(this.currentTags[category.categoryId].tags, tag.tagId)) {
                isActive = true;
            }
        }
        return isActive;
    }

    get currentTags () {
        return config.tags;
    }

    async updateTags (category: TagCategory, tag: Tag) {
        if (Object.prototype.hasOwnProperty.call(this.currentTags, category.categoryId)) {
            if (Object.prototype.hasOwnProperty.call(this.currentTags[category.categoryId].tags, tag.tagId)) {
                updateConfig.removeTag(this.currentTags[category.categoryId], tag.tagId);
                if (Object.entries(this.currentTags[category.categoryId].tags).length === 0) {
                    updateConfig.removeCategoryTag(category.categoryId);
                }
            } else {
                updateConfig.addCategoryTag(this.currentTags[category.categoryId], tag.tagId, tag);
            }
        } else {
            updateConfig.addTag(new TagCategory(category.categoryId, category.name, { [tag.tagId]: tag }), category.categoryId);
        }
        this.updateDB();
        updateUI.updateShortcutChanges(true);
    }

    updateDB () {
        updateConfig.updateStart(0);
        updateConfig.updateTags(this.currentTags);
    }
}
