import Tag from '@/common/Tag';

export default class TagCategory {
    categoryId!: string;

    name!: string;

    tags!: {[key: string]: Tag};

    constructor (categoryId: string, name: string, tags: {[key: string]: Tag}) {
        this.categoryId = categoryId;
        this.name = name;
        this.tags = tags;
    }
}
