
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({})
export default class CardIllustration extends Vue {
    @Prop({ default: '#a1a1a1' }) color?: string; // #656565

    get style() {
        return `fill: none;
                stroke: ${this.color};
                stroke-width: 0.264583px;
                stroke-linecap: butt;
                stroke-linejoin: miter;
                stroke-opacity: 1;`;
    }
}
