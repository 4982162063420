
import { Vue, Component, Prop } from 'vue-property-decorator';
import { VPopover } from 'v-tooltip';
import Multiselect from 'vue-multiselect';
import DOMPurify from 'dompurify';
import { updateUI, ui } from '@/observables/UI';
import { actionProjections, projections, updateProjections } from '@/observables/Projections';
import { config } from '@/observables/Config';
import ScenarioIdName from '@/common/projections/ScenarioIdName';
import ProjectionReviewSave from '@/common/projections/ProjectionsReview/ProjectionReviewSave';
import LoaderModal from '@/components/layout/modals/LoaderModal.vue';
import ErrorModal from '@/common/ErrorModal';
import Http from '@/auth/api/apiHelper';
import UniversalModal from '@/components/layout/modals/UniversalModal.vue';
import TagButton from '@/components/inputs/tables/TagButton.vue';
import PrettyCheck from 'pretty-checkbox-vue/check';
import { scrollToSelected } from '@/functions/tools';

const cloneDeep = require('clone-deep');

@Component({
    components: {
        VPopover,
        Multiselect,
        LoaderModal,
        UniversalModal,
        TagButton,
        PrettyCheck,
    },
})
export default class ProjectionsReviewSaveModal extends Vue {
    @Prop({ default: false }) comparisonModal: boolean;

    @Prop({ default: false }) rollForwadModal: boolean;

    @Prop({ default: null }) selectionId: string

    @Prop() reservingClass: string

    @Prop({ default: '' }) title: string

    @Prop({ default: null }) rollForwardScenarioId: string

    public showLoader = false;

    public scrollToSelected = scrollToSelected;

    public selectedtype = 1;

    public existingScenarios: ScenarioIdName[] = [];

    public missingScenarios: ScenarioIdName[] =[];

    public selectedScenario: ScenarioIdName | null = null;

    public newScenarioName = '';

    public loaderText = 'Loading';

    public saveMessage = '';

    public savingIssues: string[] = [];

    public tagsLookup = null;

    public allReservingClasses: boolean = false;

    created () {
        this.getSaveScenarioOptions();
        if (projections.reservingClassTags.tagsLookup) {
            this.tagsLookup = cloneDeep(projections.reservingClassTags.tagsLookup);
        }
        if (this.rollForwadModal) {
            this.selectedtype = 4;
        }
    }

    async getSaveScenarioOptions () {
        try {
            this.showLoader = true;
            const request = {
                dataSetId: config.dataSetId,
                segmentationId: projections.segmentationId,
                reservingClass: projections.reservingClass,
                selectionSetId: undefined,
            };
            if (this.comparisonModal) {
                request.reservingClass = this.reservingClass;
                request.selectionSetId = this.selectionId;
            }
            if (this.rollForwadModal) {
                request.reservingClass = this.reservingClass;
            }
            const options = await Http.post<any>(`${process.env.VUE_APP_API_ENDPOINT_PREDICT}/SaveScenarioOptions`, request, { apiScope: [process.env.VUE_APP_API_SCOPE_PREDICT] });

            this.existingScenarios = options.data.existingScenarios || [];
            this.missingScenarios = options.data.missingScenarios || [];
            this.showLoader = false;
        } catch (error) {
            updateUI.updateErrorModal((new ErrorModal('', 'reserving-class', 'Reserving Class')));
        }
    }

    get validated () {
        if (this.selectedtype === 1 && projections.scenario.isReadOnly) {
            return false;
        }
        if (this.selectedScenario && this.selectedScenario.isReadOnly) {
            return false;
        }
        if (this.selectedtype === 4 && this.newScenarioName.length > 0) {
            return true;
        }
        if ((this.selectedtype === 2 || this.selectedtype === 3) && this.selectedScenario !== null) {
            return true;
        }
        if (this.selectedtype === 1) return true;
        return false;
    }

    async saveProjectionsCall(validatedMessage, tagsPayload, overwrite, confirmCallBack?, cancelCallBack?) {
        const responseData = await actionProjections.saveProjection(
            this.selectedtype,
            this.selectedScenario !== null ? this.selectedScenario.scenarioId : null,
            this.newScenarioName,
            this.comparisonModal || this.rollForwadModal ? this.reservingClass : null,
            this.comparisonModal ? this.selectionId : null,
            this.comparisonModal,
            validatedMessage.message,
            tagsPayload || [],
            this.allReservingClasses,
            this.rollForwadModal && this.rollForwardScenarioId ? this.rollForwardScenarioId : null,
            overwrite,
            confirmCallBack,
            cancelCallBack,
        );
        if (responseData.saved) {
            this.closeModal();
            this.goToReservingClass();
        }
        this.showLoader = false;
    }

    getValidatedMessage () {
        const validation = {
            valid: true,
            validation_issues: [],
            message: DOMPurify.sanitize(this.saveMessage.trim()),
        };
        if (validation.message.length === 0) {
            validation.valid = false;
            validation.validation_issues.push('Please input a message before saving');
        }
        return validation;
    }

    get modalText () : string {
        return this.reviewSave.message;
    }

    async saveProjection () {
        let tagsPayload;
        if (this.tagsLookup) {
            tagsPayload = this.tagsPayload();
        }
        try {
            const validatedMessage = this.getValidatedMessage();
            if (!validatedMessage.valid) {
                this.savingIssues = validatedMessage.validation_issues;
            }
            if (this.validated && validatedMessage.valid) {
                this.loaderText = 'Saving scenario';
                this.showLoader = true;
                this.saveProjectionsCall(
                    validatedMessage,
                    tagsPayload,
                    false,
                    () => this.saveProjectionsCall(validatedMessage, tagsPayload, true),
                    () => this.closeErrorModal(),
                );
            }
        } catch (error) {
            updateUI.updateErrorModal((new ErrorModal('', 'reserving-class', 'Reserving Class')));
        }
    }

    get currentSegmentationId() : string {
        return projections.segmentationId;
    }

    private goToReservingClass () {
        updateUI.setLoader(true);
        updateProjections.updateReservingClass('');
        updateProjections.updateReservingClassTotals({});
        this.$router.push('/reserving-class');
    }

    public scenarios: any[] = [];

    public async getScenarios () {
        updateUI.setLoader(true);
        try {
            const request = { dataSetId: config.dataSetId, segmentationId: this.currentSegmentationId };
            const result = await Http.post<any>(`${process.env.VUE_APP_API_ENDPOINT_PREDICT}/GetScenarios`, request, { apiScope: [process.env.VUE_APP_API_SCOPE_PREDICT] });
            if (result?.data) {
                updateUI.setLoader(false);
                this.scenarios = result.data;
                return;
            }
            updateUI.setLoader(false);
            this.scenarios = [];
        } catch (error) {
            updateUI.updateErrorModal((new ErrorModal('', 'welcome', 'Welcome Page')));
            updateUI.setLoader(false);
            this.scenarios = [];
        }
    }

    async setScenarioToNewScenario() {
        await actionProjections.getSegmentations();
        await this.getScenarios();

        let appliedScenario = null;

        if (this.selectedtype === 4) {
            const scenarioIndex = this.scenarios.findIndex((elem) => elem.scenarioName === this.newScenarioName);
            if (scenarioIndex > -1) appliedScenario = this.scenarios[scenarioIndex];
        }

        if (this.selectedtype === 2 || this.selectedtype === 3) {
            const scenarioIndex = this.scenarios.findIndex((elem) => elem.scenarioId === this.selectedScenario.scenarioId);
            if (scenarioIndex > -1) appliedScenario = this.scenarios[scenarioIndex];
        }

        if (appliedScenario) {
            updateProjections.updateScenario(
                appliedScenario,
            );
        }
    }

    updateType (type: number) {
        this.selectedScenario = null;
        this.selectedtype = type === this.selectedtype ? null : type;
    }

    async goToReservingClasses () {
        updateProjections.updateReservingClass('');
        updateProjections.updateReservingClassTotals({});
        updateProjections.updateReviewSave(new ProjectionReviewSave());
        this.$router.push('/reserving-class');
    }

    get reviewSave () : ProjectionReviewSave {
        return projections.reviewSave;
    }

    get scenarioName () : string {
        if (this.selectedtype === 4 && this.newScenarioName.length > 0) {
            return this.newScenarioName;
        }
        if ((this.selectedtype === 2 || this.selectedtype === 3) && this.selectedScenario !== null) {
            return this.selectedScenario.scenarioName;
        }
        return projections.scenarioName;
    }

    closeModal () {
        updateProjections.updateReviewSave(new ProjectionReviewSave());
        if (this.comparisonModal || this.rollForwadModal) return this.$emit('close-modal');
        return this.$emit('close');
    }

    closeErrorModal() {
        this.loaderText = '';
        this.showLoader = false;
    }

    get darkMode () : boolean {
        return ui.darkMode;
    }

    // #region tags lookup

    public selectFilterOption(lookupOptionIx, filterOptionIx) {
        this.tagsLookup[lookupOptionIx].filterOptions = this.tagsLookup[lookupOptionIx].filterOptions.map((option, ix) => ({ ...option, selected: filterOptionIx === ix ? !option.selected : false }));
    }

    get projectionsScenario () {
        return projections.scenario;
    }

    public tagsPayload () {
        const tags = [];
        this.tagsLookup.forEach((lookup) => {
            lookup.filterOptions.forEach((option) => {
                if (option.selected) {
                    tags.push({
                        tagName: lookup.name,
                        tagValue: option.name,
                    });
                }
            });
        });

        return tags;
    }

    public isTagSelected(filterOption, lookupOption) {
        if (filterOption.selected) {
            return true;
        } if (lookupOption.filterOptions.some((option) => option.selected)) {
            return false;
        }
        const history = projections.reservingClassTags.tagsHistory ? projections.reservingClassTags.tagsHistory[lookupOption.name] : null;
        if (history) {
            const lastItem = history[history.length - 1];
            if (lastItem && lastItem.value === filterOption.name) {
                return true;
            }
        }
        return filterOption.selected;
    }

    // #endregion
}

