import Vue from 'vue';
import MainConfig from '@/common/MainConfig';

interface Store {
    chartData: MainConfig[],
}

export const mainData: Store = Vue.observable({
    chartData: [],
});

export const updateMainData = {
    setMainData (data: MainConfig[]) {
        Vue.set(mainData, 'chartData', data);
    },
};
