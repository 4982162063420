
import { Vue, Component, Prop } from 'vue-property-decorator';
import PrettyCheck from 'pretty-checkbox-vue/check';
import { VPopover } from 'v-tooltip';
import { ui, updateUI } from '@/observables/UI';
import TableHeaderWithFormatOption from '@/components/inputs/TableHeaderWithFormatOption.vue';

@Component({
    components: {
        PrettyCheck, VPopover, TableHeaderWithFormatOption,
    },
})
export default class SettingsModal extends Vue {
    @Prop({ default: '' }) currentPage: string;

    public dataFormatModalOpen: boolean = false;

    public toggleDataFormatModal() {
        this.dataFormatModalOpen = !this.dataFormatModalOpen;
    }

    public projectionNames = ['reservingClass', 'patternsSelect', 'patternsTrending', 'patternsExclusions', 'initialExpecteds', 'methodSelection', 'projectionsReview', 'projectionsConfig']

    closeModal () {
        updateUI.toggleShowSettingsModal();
    }

    exportCharts () {
        updateUI.updateExportChartsTrigger();
        updateUI.toggleShowSettingsModal();
    }

    get legendTooltip () {
        return this.chartRadio ? 'Not available on the PRA chart' : 'Toggle chart legend';
    }

    get radio () {
        return ui.noneTrend;
    }

    set radio (value) {
        updateUI.updateNoneTrend(value);
    }

    get chartRadio () {
        return ui.alternateChart;
    }

    set chartRadio (value) {
        updateUI.updateNoneTrend(value);
        updateUI.updateAlternateChart(value);
        updateUI.updateShowAverages(false);
    }

    get showAverages() {
        return ui.showAverages;
    }

    set showAverages (value) {
        updateUI.updateShowAverages(value);
    }

    get legendRadio () {
        return ui.chartLegend;
    }

    set legendRadio (value) {
        updateUI.updateChartLegend(value);
    }

    get darkMode () {
        return ui.darkMode;
    }

    set darkMode (value) {
        updateUI.setDarkMode(value);
    }

    get XAxisRadio () {
        return ui.hideXAxis;
    }

    set XAxisRadio (value) {
        updateUI.updateHideXAxis(value);
    }

    get YAxisRadio () {
        return ui.hideYAxis;
    }

    set YAxisRadio (value) {
        updateUI.updateHideYAxis(value);
    }
}

