var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:{ 'projections-sidebar': _vm.showProjectionsSidebar },attrs:{"id":"app"}},[(!_vm.standaloneView)?[(_vm.loggedIn && _vm.uiStore.inactivity)?_c('TimerModal'):_vm._e(),(_vm.loggedIn && _vm.uiStore.showPrintModal)?_c('DownloadModal'):_vm._e(),(_vm.loggedIn && _vm.uiStore.showSettingsModal)?_c('SettingsModal',{attrs:{"currentPage":_vm.currentPage}}):_vm._e(),(_vm.uiStore.loader)?_c('LoaderModal'):_vm._e(),(_vm.currentPage === 'ReservingClassReport' || _vm.currentPage === 'ReportsList')?_c('TopBarReport'):_c('TopBar',{attrs:{"sessionIdHeaderIsSet":_vm.sessionIdHeaderIsSet}}),_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[_c('router-view',{key:"sidebar",attrs:{"name":"SideBar"}})],1)]:_vm._e(),(_vm.error !== null)?_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[_c('ErrorModal')],1):_vm._e(),_c('div',{key:"main",staticClass:"main",style:({ 'pointer-events': _vm.error !== null ? 'none' : 'auto' })},[_c('ExtendedSideBar',{directives:[{name:"show",rawName:"v-show",value:(
                _vm.uiStore.showSideBar &&
                (_vm.currentPage === 'home' || _vm.currentPage === 'drilldown')
            ),expression:"\n                uiStore.showSideBar &&\n                (currentPage === 'home' || currentPage === 'drilldown')\n            "}],key:"extended-sidebar"}),_c('div',{staticClass:"bottom-bar-fill"}),_c('div',{staticClass:"main-content",class:{ projections: _vm.showProjectionsNavbar }},[_c('div',{staticClass:"main-inner-content"},[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[_c('router-view',{key:_vm.$route.matched.length > 0
                                ? _vm.$route.matched[0].name
                                : _vm.$route.name,class:{
                            projectionsSidebarOpen: _vm.showProjectionsSidebar,
                        },attrs:{"isReleaseManger":_vm.isReleaseManger},on:{"sessionIdHeaderSet":_vm.sessionIdHeaderSet,"set-release-manager":_vm.setReleaseManager}})],1)],1),(_vm.uiStore.projectionLoader)?_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[_c('ProjectionPannelLoader')],1):_vm._e()],1)],1),_c('BottomBar'),_c('lcp-notify',{attrs:{"notifyOptions":_vm.notifyOptions,"watchNetwork":true}}),_c('portal-target',{attrs:{"name":"pageTour"}}),(_vm.currentPage === 'reservingClass')?_c('portal-target',{attrs:{"name":"metricsTotalsModal"}}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }