import Vue from 'vue';

import PortalVue from 'portal-vue';

import VueTour from 'vue-tour';

import PrimeVue from 'primevue/config';

import {
    FontAwesomeIcon,
    FontAwesomeLayers,
} from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';

import {
    faBallotCheck,
    faFlaskPotion,
    faChevronLeft,
    faChevronRight,
    faChevronDown,
    faChevronUp,
    faCheck,
    faMinus,
    faQuestion,
    faInfo,
    faTimes,
    faArrowLeft as fasArrowLeft,
    faArrowRight,
    faDollarSign,
    faEuroSign,
    faPoundSign,
    faPercent,
    faCircle,
    faFilter,
    faCog,
    faPlus,
    faExternalLinkSquare,
    faBookmark,
    faWifiSlash,
    faBan,
    faPenNib,
    faArrowAltDown,
    faArrowAltUp,
    faAngleDoubleDown,
    faSyncAlt,
    faBolt,
    faStar,
    faArrowsH,
    faCompressAlt,
    faInfoSquare,
} from '@fortawesome/pro-solid-svg-icons';
import {
    faHandshake,
    faBinoculars,
    faArrowAltCircleRight,
    faTimesSquare,
    faDownload,
    faFilePdf,
    faFileImage,
    faFileCsv,
    faArrowAltCircleLeft,
    faTrash,
    faPlusSquare,
    faHome,
    faArrowLeft,
    faCogs,
    faSignOut,
    faLayerPlus,
    faCommentLines,
    faCheckSquare,
    faMoonStars,
    faDatabase,
    faPencilRuler,
    faAlignSlash,
    faAlignJustify,
    faSun,
    faUserFriends,
    faUsersClass,
    faUser,
    faExclamationSquare,
    faUserPlus,
    faBellOn,
    faComment,
    faCommentSlash,
    faArchive,
    faBalanceScaleLeft,
    faFileSearch,
    faPencil,
    faSave,
    faUndo,
    faTasks,
    faSendBackward,
    faClock,
    faFileDownload,
    faFileExcel,
    faEye,
    faEyeSlash,
    faCheckCircle,
    faCopy,
    faPaste,
    faSync,
    faInfoCircle,
    faForward,
    faFilter as fadFilter,
    faBookOpen,
    faUsersCog,
    faTimesCircle,
    faQuestionSquare,
    faFileExport,
    faExpandAlt,
    faExclamationTriangle,
    faCabinetFiling,
    faFileChartPie,
    faList,
    faFileUpload,
    faBackspace,
    faBalanceScale,
    faKey,
    faClipboard,
    faSitemap,
    faPalette,
    faInventory,
    faCalendar,
    faUserChart,
    faSlidersH,
} from '@fortawesome/pro-duotone-svg-icons';
//
import {
    faTable, faBars, faAngleUp, faAngleDown, faLink, faExpand, faAngleRight, faStar as farStarLine,
} from '@fortawesome/pro-light-svg-icons';
import { faBinoculars as farBinoculars, faAnalytics } from '@fortawesome/pro-regular-svg-icons';
import Notifications from 'vue-notification';
import { Plugin } from 'vue-fragment';
import RollForwardSelectionsRecursiveRow from '@/components/layout/rollForward/RollForwardSelectionsRecursiveRow.vue';
import ManageReportRow from '@/components/ManageReportRow.vue';
// import { AppInsights } from './AppInsights/AppInsights';

import hljs from 'highlight.js/lib/core';
import python from 'highlight.js/lib/languages/python';
import csharp from 'highlight.js/lib/languages/csharp';
import vba from 'highlightjs-vba';
import r from 'highlight.js/lib/languages/r';
import vuePlugin from '@highlightjs/vue-plugin';

import App from './App.vue';
import router from './router';
import { initialiseAuth } from './auth/MsalAuth';

import '@/assets/fonts/IcoMoon.css';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import 'pretty-checkbox/src/pretty-checkbox.scss';

import './intercept';

hljs.registerLanguage('r', r);
hljs.registerLanguage('python', python);
hljs.registerLanguage('csharp', csharp);
hljs.registerLanguage('vba', vba);

Vue.use(vuePlugin);

Vue.use(VueTour);

require('vue-tour/dist/vue-tour.css');

initialiseAuth(
    {
        auth: {
            clientId: process.env.VUE_APP_AUTH_CLIENTID,
            authority: process.env.VUE_APP_AUTH_AUTHORITY,
            redirectUri: window.location.origin,
            postLogoutRedirectUri: window.location.origin,
            knownAuthorities: [process.env.VUE_APP_AUTH_KNOWN_AUTHORITY],
        },
        cache: {
            cacheLocation: 'localStorage',
        },
    },
    [process.env.VUE_APP_B2C_SCOPE],
);

library.add(
    faHandshake,
    faTable,
    faBallotCheck,
    faFlaskPotion,
    faBinoculars,
    faArrowAltCircleRight,
    faChevronLeft,
    faChevronRight,
    faTimesSquare,
    faDownload,
    faFilePdf,
    faFileImage,
    faFileExcel,
    faCheck,
    faFileCsv,
    faArrowAltCircleLeft,
    faTrash,
    faPlusSquare,
    faHome,
    faArrowLeft,
    faArrowRight,
    faFileDownload,
    faCogs,
    faSignOut,
    faLayerPlus,
    faCommentLines,
    faMinus,
    faCheckSquare,
    faTimes,
    faDollarSign,
    faEuroSign,
    faPoundSign,
    faPercent,
    faMoonStars,
    faDatabase,
    faSun,
    faPencilRuler,
    faAlignSlash,
    faAlignJustify,
    faFilter,
    faChevronUp,
    faChevronDown,
    fasArrowLeft,
    faCog,
    faUserFriends,
    faBellOn,
    faUsersClass,
    faUserPlus,
    farBinoculars,
    faAnalytics,
    faCircle,
    faExclamationSquare,
    faComment,
    faCommentSlash,
    faArchive,
    faBalanceScaleLeft,
    faFileSearch,
    faExternalLinkSquare,
    faPencil,
    faBars,
    faSave,
    faUndo,
    faTasks,
    faBookmark,
    faQuestion,
    faInfo,
    faWifiSlash,
    faPlus,
    faSendBackward,
    faClock,
    faAngleUp,
    faAngleDown,
    faEyeSlash,
    faEye,
    faCheckCircle,
    faCopy,
    faBan,
    faSync,
    faPenNib,
    faLink,
    faInfoCircle,
    faForward,
    faAngleRight,
    faArrowAltDown,
    faArrowAltUp,
    faAngleDoubleDown,
    faPaste,
    fadFilter,
    faBookOpen,
    faUsersCog,
    faTimesCircle,
    faUser,
    faQuestionSquare,
    faFileExport,
    faExpandAlt,
    faExclamationTriangle,
    faCabinetFiling,
    faFileChartPie,
    faList,
    faSyncAlt,
    faBolt,
    faFileUpload,
    faBackspace,
    faBalanceScale,
    faStar,
    farStarLine,
    faKey,
    faSitemap,
    faClipboard,
    faExpand,
    faPalette,
    faInventory,
    faCalendar,
    faUserChart,
    faSlidersH,
    faArrowsH,
    faCompressAlt,
    faInfoSquare,
);

Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('font-awesome-layers', FontAwesomeLayers);
Vue.component('roll-forward-selections-recursive-row', RollForwardSelectionsRecursiveRow);
Vue.component('manager-report-row', ManageReportRow);

Vue.use(PortalVue);
Vue.use(Notifications);
Vue.use(Plugin);
Vue.use(PrimeVue);

Vue.config.productionTip = false;

new Vue({
    router,
    render: (h) => h(App),
}).$mount('#app');

// AppInsights.initialise({
//     config: {
//         connectionString: process.env.VUE_APP_AZURE_APP_INSIGHTS_CONNECTION_STRING,
//         enableCorsCorrelation: true,
//     },
//     router: {
//         routerInstance: router,
//         baseName: 'Insursight',
//     },
// });
