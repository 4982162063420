import { AxiosRequestConfig } from 'axios';

export default class ApiOptions {
    params?: { [key: string]: string }

    useBasePath? = true;

    useToken? = true;

    apiScope?: Array<string> = null;

    returnHeaders? = false;

    config?: AxiosRequestConfig = {};

    successTitle? = 'Success';

    message? = '';

    showSuccessMessage? = false;

    showErrorMessage? = true;

    errorTitle? = 'Error';

    errorMessage? = '';

    showPopup? = false;
}
