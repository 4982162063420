
import {
    Component, Vue, Prop, Watch,
} from 'vue-property-decorator';
import { reservingClassFiltersAndSorting, updateReservingClassFiltersAndSorting } from '@/observables/ReservingClassTableFiltersAndSorting';
import { VPopover } from 'v-tooltip';
import PrettyCheck from 'pretty-checkbox-vue/check';
import FilterConfig from '@/common/ReservingClasses/FilterConfig';
// import HeaderGroup from '@/common/ReservingClasses/HeaderGroup';

@Component({ components: { VPopover, PrettyCheck } })
export default class SortFilterCardWithPopover extends Vue {
    @Prop() header!: string;

    @Prop() filterSortData: any;

    @Prop(Boolean) modalSettings?: boolean;

    @Prop() headerKey?: number;

    @Prop() filterConfig: FilterConfig;

    public filters: any = {};

    get tableConfig () {
        return this.filterSortData;
    }

    get headerValueFormat() {
        return 'this.tableConfig[this.reservingClassFiltersAndSorting]';
    }

    public fromCheck = false;

    public toCheck = false;

    public isOpen = false

    clickOpen () {
        this.isOpen = true;
    }

    public sort: string = '';

    public filterBetween: Record<string, number | null> = { from: null, to: null }

    public toggleFilter (filter: string) {
        Vue.set(this.filters[filter], 'enabled', !this.filters[filter].enabled);
        if (this.isFilterActive) {
            updateReservingClassFiltersAndSorting.addFilter(this.filterQuery);
        } else updateReservingClassFiltersAndSorting.removeFilter(this.filterQuery);
    }

    public toggleSort (sort: string) {
        if (this.sort === sort) {
            updateReservingClassFiltersAndSorting.removeSort(this.sortQuery);
            this.sort = '';
            return;
        }
        this.sort = sort;
        updateReservingClassFiltersAndSorting.addSort(this.sortQuery);
    }

    get isFilterActive (): boolean {
        return Object.keys(this.filters).some((filter) => this.filters[filter].enabled === true);
    }

    get isBetweenFilterActive (): boolean {
        return (this.filterBetween.from !== 0 || this.filterBetween.to !== 0);
    }

    get isSortActive (): boolean {
        return this.sort !== '';
    }

    get reservingClassFiltersAndSorting() {
        return reservingClassFiltersAndSorting;
    }

    get sortQuery () {
        if (this.sort) return { name: this.filterSortData.name, order: this.sort, text: this.filterSortData.text };
        return {};
    }

    get filterQuery () {
        const values = [];
        const filterOptions = this.filterSortData.filterConfig.filterOptions;
        const keys = Object.keys(filterOptions);
        keys.forEach((key) => {
            if (filterOptions[key].enabled) values.push(filterOptions[key].text);
        });
        return {
            name: this.filterSortData.name, values, text: this.filterSortData.text, filterConfig: this.filterSortData.filterConfig,
        };
    }

    get betweenFilterQuery () {
        const filterBetweenQuery = { ...this.filterQuery };
        if (this.filterBetween.to || this.filterBetween.from || [0].includes(this.filterBetween.to) || [0].includes(this.filterBetween.from)) {
            filterBetweenQuery.values = [this.filterBetween];
            if (this.filterBetween.from) this.fromCheck = true;
            if (this.filterBetween.to) this.toCheck = true;
            return filterBetweenQuery;
        } filterBetweenQuery.values = [{ from: null, to: null }];
        if (this.filterBetween.from) this.toCheck = false;
        if (this.filterBetween.to) this.fromCheck = false;
        return filterBetweenQuery;
    }

    public handleInputChange() {
        if (this.isBetweenFilterActive) return updateReservingClassFiltersAndSorting.addFilter(this.betweenFilterQuery);
        return updateReservingClassFiltersAndSorting.removeFilter(this.betweenFilterQuery);
    }

    get isTableHeaderActive() {
        const hasFilter = this.reservingClassFiltersAndSorting.filter.some((filter) => filter.text === this.header);
        const hasSort = this.reservingClassFiltersAndSorting.sort.some((sort) => sort.text === this.header);
        const hasBetweenFilter = this.reservingClassFiltersAndSorting.filter.some((betweenFilter) => betweenFilter.text === `${this.header} BETWEEN`);
        return { hasFilter, hasSort, hasBetweenFilter };
    }

    @Watch('reservingClassFiltersAndSorting', { deep: true, immediate: true })
    resetFieldsIfFilterOrSortingRemoved() {
        if (!this.isTableHeaderActive.hasFilter) this.filters = { small: { enabled: false, text: 'Small' }, medium: { enabled: false, text: 'Medium' }, large: { enabled: false, text: 'Large' } };
        if (!this.isTableHeaderActive.hasSort) this.sort = '';
        if (!this.isTableHeaderActive.hasBetweenFilter) {
            this.filterBetween = { from: null, to: null };
            this.toCheck = false;
            this.fromCheck = false;
        }
    }

    @Watch('fromCheck')
    handleFromCheckChange () {
        if (this.fromCheck) {
            this.filterBetween.from = 0;
            updateReservingClassFiltersAndSorting.addFilter(this.betweenFilterQuery);
            return;
        } this.filterBetween.from = null;
        if (this.filterBetween.to === null) updateReservingClassFiltersAndSorting.removeFilter(this.betweenFilterQuery);
    }

    @Watch('toCheck')
    handleToCheckChange () {
        if (this.toCheck) {
            this.filterBetween.to = 0;
            updateReservingClassFiltersAndSorting.addFilter(this.betweenFilterQuery);
            return;
        } this.filterBetween.to = null;
        if (this.filterBetween.from === null) updateReservingClassFiltersAndSorting.removeFilter(this.betweenFilterQuery);
    }

    @Watch('isTableHeaderActive')
    updateTabelHeaderStyle () {
        this.$emit('update-table-header-style', Object.keys(this.isTableHeaderActive).some((filter) => this.isTableHeaderActive[filter] === true));
    }
}
