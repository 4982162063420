import Vue from 'vue';
import ScenarioIdName from '@/common/projections/ScenarioIdName';
import CohortModel from '@/common/projections/CohortModel';
import ReservingClassTotals from '@/common/projections/ReservingClassTotals';
import SelectedException from '@/common/projections/SelectedException';
import { updateUI } from '@/observables/UI';
import { InitialExpected } from '@/common/projections/InitialExpected';
import IEName from '@/common/projections/IEName';
import ProjectionModel from '@/common/projections/ProjectionModel';
import PatternComponent from '@/common/projections/PatternComponent';
import ComponentOption from '@/common/projections/PatternComponent/ComponentOption';
import ViewComponent from '@/common/projections/PatternComponent/ViewComponent';
import ProjectionReviewSave from '@/common/projections/ProjectionsReview/ProjectionReviewSave';
import { ScenariosConfig } from '@/common/projections/ScenariosConfig';
import ErrorModal from '@/common/ErrorModal';
import ValueFormatRules from '@/common/ValueFormatRules';
import SegmentationIdName from '../common/projections/SegmentationIdName';
import CohortPatterns from '../common/projections/CohortPatterns';
import ReservingClassTotalsFormats from '../common/projections/ReservingClassTotalsFormats';
import { config } from './Config';
import Http from '../auth/api/apiHelper';

interface Store {
    cohortPatterns: CohortPatterns,
    ersId: string,
    activeScenarioId: string,
    initialExpected: InitialExpected,
    options: [],
    patternComponent: PatternComponent,
    reservingClass: string,
    scenarioId: string,
    scenarioName: string,
    scenario: ScenarioIdName,
    segmentationId: string,
    segmentationName: string,
    segmentationRollForward: boolean,
    runAnalytics: boolean,
    tableId: string,
    segmentations: ScenariosConfig[],
    projectionSidebarOptions: {
        cohortsList: string[],
        ieNameList: IEName[],
        itemList: string[],
        patternList: string[],
        projectionModelsList: ProjectionModel[],
        selectedCohort: string,
        selectedCohortGranularity: string,
        selectedExceptions: SelectedException[],
        selectedIE: IEName,
        selectedItem: string,
        selectedProjection: ProjectionModel,
    }
    projectionTotals: Record<string, string>
    reservingClassTotals: ReservingClassTotals,
    reservingClassTotalsFormats: ReservingClassTotalsFormats,
    reviewSave: ProjectionReviewSave,
    scenarioDataFormat: Record<string, Record<string, ValueFormatRules>>
    updateReservingClassTable: boolean,
    customMetricTotals: boolean,
    downloadTableId: string,
    viewAnalytics: {
        reservingClasses: string[];
    },
    reservingClassTags: {
        tagsLookup: any,
        tagsHistory: any,
    }
}

export const projections: Store = Vue.observable({
    cohortPatterns: {
        assumptions: [],
        cohorts: [],
        models: [],
        selections: [],
    },
    ersId: '',
    activeScenarioId: '',
    initialExpected: null,
    options: [],
    patternComponent: null,
    reservingClass: '',
    scenarioId: '',
    scenarioName: '',
    scenario: null,
    segmentationId: '',
    tableId: '',
    segmentationName: '',
    segmentationRollForward: false,
    runAnalytics: false,
    projectionSidebarOptions: {
        cohortsList: [],
        ieNameList: [],
        itemList: [],
        patternList: [],
        projectionModelsList: [],
        selectedCohort: null,
        selectedCohortGranularity: 'Default',
        selectedExceptions: [],
        selectedIE: null,
        selectedItem: '',
        selectedProjection: null,
    },
    projectionTotals: {},
    reservingClassTotals: new ReservingClassTotals(),
    reservingClassTotalsFormats: { percentage: {}, currency: {} },
    reviewSave: new ProjectionReviewSave(),
    scenarioDataFormat: null,
    segmentations: [],
    updateReservingClassTable: false,
    customMetricTotals: false,
    downloadTableId: null,
    viewAnalytics: {
        reservingClasses: [],
    },
    reservingClassTags: {
        tagsLookup: null,
        tagsHistory: null,
    },
});

export const updateProjections = {
    resetProjectionsToDefault() {
        projections.cohortPatterns = {
            assumptions: [],
            cohorts: [],
            models: [],
            selections: [],
        };
        projections.ersId = '';
        projections.activeScenarioId = '';
        projections.initialExpected = null;
        projections.options = [];
        projections.patternComponent = null;
        projections.reservingClass = '';
        projections.scenarioId = '';
        projections.scenarioName = '';
        projections.segmentationId = '';
        projections.tableId = '';
        projections.segmentationName = '';
        projections.segmentationRollForward = false;
        projections.runAnalytics = false;
        projections.projectionSidebarOptions = {
            cohortsList: [],
            ieNameList: [],
            itemList: [],
            patternList: [],
            projectionModelsList: [],
            selectedCohort: null,
            selectedCohortGranularity: 'Default',
            selectedExceptions: [],
            selectedIE: null,
            selectedItem: '',
            selectedProjection: null,
        };
        projections.reservingClassTotals = new ReservingClassTotals();
        projections.segmentations = [];
        projections.projectionTotals = {};
        projections.reservingClassTotalsFormats = { percentage: {}, currency: {} };
        projections.updateReservingClassTable = false;
        projections.downloadTableId = null;
        projections.reviewSave = new ProjectionReviewSave();
        projections.scenarioDataFormat = null;
    },
    resetProjectionSidebarOptions() {
        const defaultOptions = {
            cohortsList: [],
            ieNameList: [],
            itemList: [],
            patternList: [],
            projectionModelsList: [],
            selectedCohort: null,
            selectedCohortGranularity: null,
            selectedExceptions: [],
            selectedIE: null,
            selectedItem: '',
            selectedProjection: null,
        };
        Vue.set(projections, 'projectionSidebarOptions', defaultOptions);
    },
    resetReservingClassTotals() {
        projections.reservingClassTotals = new ReservingClassTotals();
    },
    updateresetReservingClassTotals(reservingClassTotals: ReservingClassTotals) {
        Vue.set(projections.reservingClassTotals, 'totalReserves', reservingClassTotals.totalReserves);
        Vue.set(projections.reservingClassTotals, 'filteredAsPercentageOfTotal', reservingClassTotals.filteredAsPercentageOfTotal);
        Vue.set(projections.reservingClassTotals, 'filteredTotalReserves', reservingClassTotals.filteredTotalReserves);
        Vue.set(projections.reservingClassTotals, 'reserveClassCount', reservingClassTotals.reserveClassCount || 0);
        Vue.set(projections.reservingClassTotals, 'reserveClassTotal', reservingClassTotals.reserveClassTotal || 0);
    },
    updateSegmentation(selected: SegmentationIdName) {
        Vue.set(projections, 'segmentationName', selected.segmentationName);
        Vue.set(projections, 'segmentationId', selected.segmentationId);
        Vue.set(projections, 'segmentationRollForward', selected.rollForward);
    },
    updateItemList(itemList: string[]) {
        Vue.set(projections.projectionSidebarOptions, 'itemList', itemList);
    },
    updateErsId(ersId: string) {
        Vue.set(projections, 'ersId', ersId);
    },
    updateCustomMetricTotals(bool: boolean) {
        Vue.set(projections, 'customMetricTotals', bool);
    },
    updateActiveScenarioId(activeScenarioId: string) {
        Vue.set(projections, 'activeScenarioId', activeScenarioId);
    },
    updateReservingClass(reservingClass: string) {
        Vue.set(projections, 'reservingClass', reservingClass);
    },
    updateScenario(scenario: ScenarioIdName) {
        Vue.set(projections, 'scenarioId', scenario.scenarioId);
        Vue.set(projections, 'scenarioName', scenario.scenarioName);
        Vue.set(projections, 'scenario', scenario);
        Vue.set(projections, 'tableId', scenario.tableId);
    },
    updateCohortPatterns(cohortPatterns: CohortPatterns) {
        Vue.set(projections, 'cohortPatterns', cohortPatterns);
    },
    setupSelectedExceptions(selectedExceptions: SelectedException[]) {
        Vue.set(projections.projectionSidebarOptions, 'selectedExceptions', selectedExceptions);
    },
    setSelectedExceptionPatternId(rowKey: number, patternId: string) {
        Vue.set(projections.projectionSidebarOptions.selectedExceptions[rowKey], 'patternId', patternId);
    },
    removeSelectedException(index: number) {
        Vue.delete(projections.projectionSidebarOptions.selectedExceptions, index);
    },
    addSelectedException(row: SelectedException) {
        projections.projectionSidebarOptions.selectedExceptions.push(row);
    },
    updateInitialExpected(initialExpected: InitialExpected) {
        Vue.set(projections, 'initialExpected', initialExpected);
    },
    updatedPatternSelectPatternCohort(selectedPattern: CohortModel) {
        Vue.set(projections, 'patternSelectPatternCohort', selectedPattern);
    },
    updateIE(selectedIE: IEName) {
        Vue.set(projections.projectionSidebarOptions, 'selectedIE', selectedIE);
    },
    updateSelectedItem(selectedItem: string) {
        Vue.set(projections.projectionSidebarOptions, 'selectedItem', selectedItem);
    },
    updateSegmentations(segmentations: ScenariosConfig[]) {
        Vue.set(projections, 'segmentations', segmentations);
    },
    updateSelectedProjection(selectedProjection: ProjectionModel) {
        Vue.set(projections.projectionSidebarOptions, 'selectedProjection', selectedProjection);
    },
    updatePatternList(patternList: []) {
        Vue.set(projections.projectionSidebarOptions, 'patternList', patternList);
    },
    updateProjectionModelsList(projectionModelsList: []) {
        Vue.set(projections.projectionSidebarOptions, 'projectionModelsList', projectionModelsList);
    },
    updateIeNameList(ieNameList: IEName[]) {
        Vue.set(projections.projectionSidebarOptions, 'ieNameList', ieNameList);
    },
    updateSelectedCohort(cohort: string) {
        Vue.set(projections.projectionSidebarOptions, 'selectedCohort', cohort);
    },
    updateSelectedCohortGranularity(cohortGranularity: string) {
        Vue.set(projections.projectionSidebarOptions, 'selectedCohortGranularity', cohortGranularity);
    },
    updateCohortsList(cohortsList: string[]) {
        Vue.set(projections.projectionSidebarOptions, 'cohortsList', cohortsList);
    },
    updateReservingClassTotals(projectionTotals: Record<string, string | number>) {
        Vue.set(projections, 'projectionTotals', projectionTotals);
    },
    updateReservingClassTotalsFormats(reservingClassTotalsFormats: ReservingClassTotalsFormats) {
        Vue.set(projections, 'reservingClassTotalsFormats', reservingClassTotalsFormats);
    },
    updatePatternComponent(patternComponent: PatternComponent) {
        Vue.set(projections, 'patternComponent', patternComponent);
    },
    updateComponentOptions(components: ComponentOption[]) {
        Vue.set(projections.patternComponent, 'components', components);
    },
    addViewComponent(viewComponent: ViewComponent, index: number) {
        projections.patternComponent.viewComponents.splice(index + 1, 0, viewComponent);
    },
    updateViewComponents(viewComponents: ViewComponent[]) {
        Vue.set(projections.patternComponent, 'viewComponents', viewComponents);
    },
    updateViewAnalyticsReservingClasses(reservingClasses: string[]) {
        Vue.set(projections.viewAnalytics, 'reservingClasses', reservingClasses);
    },
    toggleUpdateReservingClassTable(bool: boolean) {
        Vue.set(projections, 'updateReservingClassTable', bool);
    },
    updateRunAnalytics(bool: boolean) {
        Vue.set(projections, 'runAnalytics', bool);
    },
    updateReviewSave(review: ProjectionReviewSave) {
        Vue.set(projections, 'reviewSave', review);
    },
    updateDownloadTableId(downloadTableId: string) {
        Vue.set(projections, 'downloadTableId', downloadTableId);
    },
    updateScenarioDataFormat(scenarioDataFormat: Record<string, Record<string, ValueFormatRules>>) {
        Vue.set(projections, 'scenarioDataFormat', scenarioDataFormat);
    },
    updateFromRedirect(newProjections) {
        Vue.set(projections, 'ersId', newProjections.ersId);
        Vue.set(projections, 'scenarioId', newProjections.scenarioId);
        Vue.set(projections, 'activeScenarioId', newProjections.activeScenarioId);
        Vue.set(projections, 'segmentationId', newProjections.segmentationId);
        Vue.set(projections, 'reservingClass', newProjections.reservingClass);
        Vue.set(projections, 'segmentationName', newProjections.segmentationName);
        Vue.set(projections, 'scenarioName', newProjections.scenarioName);
        Vue.set(projections, 'scenario', newProjections.scenario);
        Vue.set(projections, 'runAnalytics', newProjections.runAnalytics);
    },
    updateReservingClassTags(newTags) {
        Vue.set(projections, 'reservingClassTags', newTags);
    },
};

export const actionProjections = {
    // eslint-disable-next-line consistent-return
    async getProjectionTotals(setMetrics: boolean = false): Promise<any> {
        if (projections.ersId) {
            let reservingClassForErs;
            try {
                reservingClassForErs = await Http.post(
                    `${process.env.VUE_APP_API_ENDPOINT_PREDICT}/GetReserveDiagnosticsForErs`,
                    {
                        ersId: projections.ersId,
                        dataSetId: config.dataSetId,
                        scenarioId: projections.scenarioId,
                        segmentationId: projections.segmentationId,
                    },
                    { apiScope: [process.env.VUE_APP_API_SCOPE_PREDICT] },
                );
            } catch (error) {
                updateUI.updateErrorModal((new ErrorModal('', 'welcome', 'Welcome Page')));
            }
            const result = reservingClassForErs.data;
            updateProjections.updateReservingClassTotals({
                changeFromSavedScenario: result.changeFromSavedScenario,
                ersId: projections.ersId,
                metricTotal: result.metricTotal,
                ersMetricTotal: result.ersMetricTotal,
                scenarioMetricTotal: result.scenarioMetricTotal,
                metricText: result.metricText,
                totalMetricsLabel: result.totalMetricsLabel,
                scenarioMetricsLabel: result.scenarioMetricsLabel,
                changeFromScenarioLabel: result.changeFromScenarioLabel,
                scenarioDiagnostics: result.scenarioDiagnostics,
                reserveClassCount: result.reserveClassCount,
                reservingClassTotal: result.reservingClassTotal,
            });
        }
    },
    async saveProjection(
        action: number = 1,
        scenarioIdName: string = null,
        newScenarioName: string = '',
        reservingClass: string = null,
        selectionSetId: string = null,
        comparisonModal: boolean = false,
        message: string = '',
        tags = [],
        allReservingClasses = false,
        originalScenarioId: string = undefined,
        overwrite = false,
        confirmCallBack: () => void = null,
        cancelCallBack: () => void = null,
    ) {
        try {
            const request = {
                dataSetId: config.dataSetId,
                segmentationId: projections.segmentationId,
                scenarioId: scenarioIdName !== null ? scenarioIdName : projections.scenarioId,
                ersId: projections.ersId,
                reservingClass: reservingClass || projections.reservingClass,
                action,
                name: newScenarioName,
                overwrite: overwrite || action === 2,
                saveCharts: true,
                selectionSetId,
                message,
                includeComments: true,
                tags,
                allReservingClasses,
                originalScenarioId,
            };

            const response = await Http.post<any>(`${process.env.VUE_APP_API_ENDPOINT_PREDICT}/SaveResultsToScenario`, request, { apiScope: [process.env.VUE_APP_API_SCOPE_PREDICT] });
            if (response.data.saved) {
                updateUI.updateErrorModal((new ErrorModal(`Results for the current reserving class have been saved to scenario ${response.data.scenarioName}. We will now navigate you back to the reserving classes`, null, 'Saving', true, false, true)));
                return response.data;
            }
            if (response.data.userQuestion) {
                updateUI.updateErrorModal((new ErrorModal(
                    response.data.message,
                    null,
                    '',
                    false,
                    true,
                    false,
                    response.data.userQuestion,
                    'Proceed',
                    'Cancel',
                    confirmCallBack,
                    cancelCallBack,
                )));
                return response.data;
            }
            if (!comparisonModal) {
                updateUI.updateErrorModal((new ErrorModal(response.data.message, null, 'Saving', true, true, false)));
                return response.data;
            }
            if (!response.data.userQuestion) {
                updateProjections.updateReviewSave(response.data as ProjectionReviewSave);
                return response.data;
            }
            return response.data;
        } catch (error) {
            updateProjections.updateReviewSave(new ProjectionReviewSave());
            updateUI.updateErrorModal((new ErrorModal('', 'welcome', 'Welcome Page')));
            return error;
        }
    },
    async getSegmentations(): Promise<any> {
        try {
            const result = await Http.post<{ isValid: boolean, segmentations: ScenariosConfig[], statusMessage: string, tableId?: string }>(
                `${process.env.VUE_APP_API_ENDPOINT_PREDICT}/GetSegmentations`,
                { dataSetId: config.dataSetId },
                { apiScope: [process.env.VUE_APP_API_SCOPE_PREDICT], showSuccessMessage: true },
            );
            if (result.data.isValid) {
                updateProjections.updateSegmentations(result.data.segmentations);
            } else {
                this.selectedDataSet = null;
                updateUI.updateErrorModal((new ErrorModal(result.data.statusMessage, 'welcome', 'Welcome Page', true)));
            }
            updateUI.setLoader(false);
            return result.data;
        } catch (error) {
            updateUI.updateErrorModal((new ErrorModal('', 'welcome', 'Welcome Page')));
            return error;
        }
    },
    async copyErs(dataSetId: string, segmentationId: string, scenarioId: string, reservingClass: string, continueVal: boolean = false, onlyReturnData: boolean = false) {
        try {
            const copyReserve = await Http.post<any>(
                `${process.env.VUE_APP_API_ENDPOINT_PREDICT}/CopyErs`,
                {
                    dataSetId,
                    segmentationId,
                    scenarioId,
                    reservingClass,
                    continue: continueVal,
                },
                { apiScope: [process.env.VUE_APP_API_SCOPE_PREDICT] },
            );
            if (!onlyReturnData) {
                updateProjections.updateErsId(copyReserve.data.ersId);
                updateProjections.updateActiveScenarioId(copyReserve.data.activeScenarioId);
            }
            return copyReserve.data;
        } catch (error) {
            updateUI.updateErrorModal((new ErrorModal('', 'welcome', 'Welcome Page')));
            return error;
        }
    },
    async getCurrentDataFormattingConfig(scenarioPage: boolean = false) {
        try {
            const result = await Http.post<any>(`${process.env.VUE_APP_API_ENDPOINT_PREDICT}/GetErsDataFormatting`, { ersId: projections.ersId }, { apiScope: [process.env.VUE_APP_API_SCOPE_PREDICT] });
            const currentDataFormatConfig = result.data;
            if (scenarioPage) updateProjections.updateReservingClassTotalsFormats({ currency: result.data.currency.valueFormat, percentage: result.data.percentage.valueFormat });
            // await actionProjections.getProjectionTotals();
            return currentDataFormatConfig;
        } catch (error) {
            this.error = error;
            updateUI.updateErrorModal((new ErrorModal('', 'welcome', 'Welcome Page')));
            return error;
        }
    },
    removeSegementation(segmentationId: string) {
        const index = projections.segmentations.findIndex((segementation) => segementation.segmentationId === segmentationId);
        if (index > -1) projections.segmentations.splice(index, 1);
    },
};
