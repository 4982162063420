
import { Vue, Component } from 'vue-property-decorator';
import { ui } from '@/observables/UI';
import GridLayoutPanel from './panels/GridLayoutPanel.vue';
import LabelsPanel from './panels/LabelsPanel.vue';
import FilteringPanel from './panels/FilteringPanel.vue';
import TagPanel from './panels/TagPanel.vue';
import SortingPanel from './panels/SortingPanel.vue';

@Component({
    components: {
        GridLayoutPanel,
        LabelsPanel,
        FilteringPanel,
        TagPanel,
        SortingPanel,
    },
})
export default class ExtendedSideBar extends Vue {
    get sideBarType () {
        return ui.sideBarType;
    }
}
