
import { Vue, Component } from 'vue-property-decorator';
import FilterOptions from '@/components/layout/panels/FilterOptions.vue';
import ParentTrend from '@/components/layout/panels/ParentTrend.vue';

import { filters } from '@/observables/Filters';
import { config } from '@/observables/Config';
import TrendFilter from '@/common/TrendFilter';

@Component({
    components: {
        FilterOptions,
        ParentTrend,
    },
})
export default class FilteringPanel extends Vue {
    get activeFilters (): TrendFilter[] {
        return config.drilldownLevel > 0 ? filters.config.filter((f: TrendFilter) => f.name !== 'value') : filters.config;
    }
}
