import NumberValue from './NumberValue';

export default class ActiveFilter {
    text!: string;

    dataType!: string;

    values!: (string | NumberValue)[];

    filterType?: string;

    constructor(text: string, dataType: string, values: (string | NumberValue)[], filterType?: string) {
        this.text = text;
        this.dataType = dataType;
        this.values = values;
        this.filterType = filterType;
    }
}
