
import { Vue, Component } from 'vue-property-decorator';
import { updateUI } from '@/observables/UI';
import ErrorModal from '@/common/ErrorModal';
import { logout } from '@/auth/MsalAuth';
import Http from '@/auth/api/apiHelper';

@Component({
    components: {
    },
})
export default class TimerModal extends Vue {
    secondsRemaining: number = 60;

    handle!: number;

    created () {
        const self = this;
        this.handle = setInterval(() => {
            self.reduceTimer();
        }, 1000);
    }

    reduceTimer () {
        this.secondsRemaining -= 1;

        if (this.secondsRemaining <= 0) {
            logout();
            this.$router.push('/login');
        }
    }

    beforeDestroy () {
        clearInterval(this.handle);
    }

    refreshData () {
        try {
            updateUI.updateInactivity(false);
            Http.get<any>(
                `${process.env.VUE_APP_API}/api/QueryDataSets`,
                { apiScope: [process.env.VUE_APP_B2C_SCOPE] },
            );
        } catch (error) {
            updateUI.updateErrorModal((new ErrorModal('', 'welcome', 'Welcome Page')));
        }
    }
}

