
import {
    Vue, Component, Prop,
} from 'vue-property-decorator';
import {
    computePosition, flip, shift, offset, autoUpdate, Placement,
} from '@floating-ui/dom';
import { ILCPTourStep, ILCPTourTheme } from '@/interfaces/ILCPTour';
import { updateUI } from '@/observables/UI';

export interface ILCPTourProps {
    name: string;
    steps: ILCPTourStep[];
    start?: boolean;
    end?: boolean;
    startStep?: number;
    currentStep?: number;
    showArrow?: boolean;
    completionRequired?: boolean,
    targetOffset?: number,
    viewPadding?: number,
    headerText?: string,
    placement?: Placement,
    themeColours?: ILCPTourTheme,
    finishedRoute?: string,
}

@Component
export default class LcpTour extends Vue {
    @Prop({
        default: {
            start: true,
            startStep: 0,
            showArrow: true,
            targetOffset: 12,
            viewPadding: 5,
        },
    }) steps?: any;

    @Prop() name?: string;

    public back():void {
        if (this.currentStepIx - 1 < 0) return;
        this.removeTargetClass(this.currentStep.target);
        this.currentStepIx -= 1;
        this.positionTooltip();
    }

    public endTour():void {
        this.removeTargetClass(this.currentStep.target);
        updateUI.setShowTour(false);
    }

    public next():void {
        if (this.currentStepIx + 1 > this.steps.length) return;
        this.removeTargetClass(this.currentStep.target);
        this.currentStepIx += 1;
        this.positionTooltip();
    }

    beforeDestroy() {
        this.removeTargetClass(this.currentStep.target);
        updateUI.setShowTour(false);
    }

    public cleanup : null |(() => void) = null;

    public targetOnDisplay = false;

    public currentStepIx = 0;

    get currentStep(): ILCPTourStep { return this.steps[this.currentStepIx]; }

    public showTour :boolean = true;

    public finalPlacement :Placement | '' = '';

    public transition :string = '';

    // #region tooltip positioning
    positionTooltip (): void {
        if (this.cleanup) {
            this.cleanup();
        }
        const target = document.querySelector(`[data-tour-id="${this.currentStep.target}"]`);
        const tooltip = document.getElementById(`lcp-tour-${this.name}`);
        // console.log(target);
        if (target) {
            this.targetOnDisplay = true;
        } else {
            this.targetOnDisplay = false;
            return;
        }
        if (target) {
        // if (target && tooltip) {
            if (this.isInViewport(target) === false) {
                target.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
            }
            target.classList.add('lcp-tour-highlighted');
            const middleware = [
                offset(this.steps.targetOffset),
                flip(),
                shift({ padding: this.steps.viewPadding }),
            ];
            this.cleanup = autoUpdate(target, tooltip, () => {
                computePosition(target, tooltip, {
                    placement: this.currentStep.placement || this.steps.placement || 'bottom',
                    middleware,
                }).then(({ x, y, placement }) => {
                    this.finalPlacement = placement;
                    Object.assign(tooltip.style, {
                        left: `${x}px`,
                        top: `${y}px`,
                    });
                });
            }, {
                ancestorScroll: true,
            // ancestorResize?: boolean;
            // elementResize?: boolean;
            // animationFrame?: boolean;
            });
        }
    }
    // #endregion

    public removeTargetClass (previousTarget): void {
        const target = document.querySelector(`[data-tour-id = "${previousTarget}"]`);
        if (target) {
            target.classList.remove('lcp-tour-highlighted');
        }
    }

    created() {
        this.positionTooltip();
    }

    public isInViewport (element: Element): boolean {
        const rect = element.getBoundingClientRect();
        return (
            rect.top >= 0
        && rect.left >= 0
        && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
        && rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    }

    // onBeforeUnmount(() => {
    //   if (cleanup.value) {
    //         cleanup.value();
    //         removeTargetClass(currentStep.value.target);
    //     }})

    //   beforeDestroy() {
    //   console.log(`At this point, watchers, child components, and event listeners have not been teared down yet.`)
    //   // Perform the teardown procedure for exampleLeakyProperty.
    //   // (In this case, effectively nothing)
    //   this.exampleLeakyProperty = null
    //   delete this.exampleLeakyProperty
    // }
}
