

import {
    Vue, Component, Prop,
} from 'vue-property-decorator';

@Component({
    components: {
    },
})
export default class RadioBox extends Vue {
    @Prop() id: string;

    @Prop() value: string;

    @Prop() selectedValue: string;

    @Prop() name: string;

    @Prop() disabled?: boolean;

    @Prop({ default: false }) readonly: boolean;

    updateValue() {
        if (this.readonly) {
            return;
        }
        this.$emit('change', this.value);
    }
}
