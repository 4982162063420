
import { Vue, Component } from 'vue-property-decorator';
import { ScaleLoader } from '@saeris/vue-spinners';
import { ui } from '@/observables/UI';

Component.registerHooks([
    'beforeRouteLeave',
]);

@Component({
    components: {
        ScaleLoader,
    },
})
export default class ProjectionPannelLoader extends Vue {
    get loaderText () {
        return ui.loaderText;
    }
}
