import axios from 'axios';
import SessionTimer from '@/common/SessionTimer';
import { logout } from './auth/MsalAuth';
// import router from './router';

const timer: SessionTimer = new SessionTimer(120, 2);

axios.interceptors.response.use((response: any) => {
    timer.updateLastTouch();
    if (response.status >= 200 && response.status <= 299) {
        return response;
    }
    return null;
}, (error: any) => {
    const status: number = error.response.status;
    // let text: string = 'Service unavailable. Please contact your administrator.';
    // if (error.response) {
    //     status = error.response.status;
    //     text = error.response.data;
    // }
    if (status === 401) {
        logout();
        // text = 'You have been logged out due to inactivity, please click below to login again.';
    }
    // router.push({ name: 'error', params: { status: status.toString(), text } });
    return Promise.reject(error);
});
