import Choice from '@/common/Choice';
import { ui, updateUI } from '@/observables/UI';
import NumberValue from '@/common/NumberValue';
import { updateActiveFilters, updateDefaultFilters, filters } from '@/observables/Filters';
import ActiveFilter from '@/common/ActiveFilter';
import ConfigLabel from '@/common/ConfigLabel';
import ConfigSort from '@/common/ConfigSort';
import { updateActiveTrends } from '@/observables/Trends';
import { config, updateConfig } from '@/observables/Config';
import TrendFilter from '@/common/TrendFilter';
import UILabel from '@/common/UILabel';
import SortOrder from '@/common/SortOrder';
import DataLabel from '@/common/DataLabel';

const cloneDeep = require('clone-deep');

function SetShortcut(shortcut: Choice) {
    if (shortcut.filter) {
        for (let choiceIndex = 0; choiceIndex < shortcut.filter.length; choiceIndex += 1) {
            if (shortcut.filter[choiceIndex].values.length > 0) {
                const index: number = ui.labels.findIndex((i: {[key: string]: any}) => i.name === shortcut.filter[choiceIndex].name);
                if (index > -1) {
                    let values: any = cloneDeep(shortcut.filter[choiceIndex].values);
                    if (ui.labels[index].dataType === 'number') {
                        const numberVal: any = shortcut.filter[choiceIndex].values[0];
                        values = [new NumberValue(Object.prototype.hasOwnProperty.call(numberVal, 'from')
                            ? numberVal.from : null, Object.prototype.hasOwnProperty.call(numberVal, 'to') ? numberVal.to : null)];
                    }
                    updateActiveFilters.setupFilter(
                        shortcut.filter[choiceIndex].name,
                        new ActiveFilter(ui.labels[index].text, ui.labels[index].dataType, values),
                    );
                    updateDefaultFilters.setupFilter(
                        shortcut.filter[choiceIndex].name,
                        new ActiveFilter(ui.labels[index].text, ui.labels[index].dataType, values),
                    );
                }
            }
        }
    }

    Object.keys(filters.active).forEach((key: string) => {
        if (!shortcut.filter.some((i: any) => i.name === key)) {
            updateActiveFilters.removeFilter(key);
        }
    });

    for (let labelIndex = 0; labelIndex < shortcut.dataLabels.length; labelIndex += 1) {
        const choice: DataLabel = shortcut.dataLabels[labelIndex];
        const index: number = config.labels.findIndex((i: ConfigLabel) => i.item === choice.name);
        if (index > -1) updateConfig.updateLabelShow(index, choice.labelSelect);
    }

    if (shortcut.sort !== undefined) {
        for (let sortIndex = 0; sortIndex < shortcut.sort.length; sortIndex += 1) {
            const choice: SortOrder = shortcut.sort[sortIndex];
            const index: number = config.sort.findIndex((i: ConfigSort) => i.name === choice.name);
            config.sort.forEach((s: ConfigSort) => {
                if (s.checked) {
                    updateConfig.updateSortProperties(config.sort.indexOf(s), new ConfigSort(false, s.item, s.name, s.order, s.trend));
                    updateConfig.updateSortOption(new ConfigSort(false, s.item, s.name, s.order, s.trend));
                }
            });
            if (index > -1) {
                updateConfig.updateSortProperties(index, new ConfigSort(true, config.sort[index].item, config.sort[index].name, choice.order, config.sort[index].trend));
                updateConfig.updateSortOption(new ConfigSort(true, config.sort[index].item, config.sort[index].name, choice.order, config.sort[index].trend));
                break;
            }
        }
    }

    if (shortcut.tags) {
        updateConfig.updateTags(cloneDeep(shortcut.tags));
    }

    updateUI.setRows(shortcut.grid[0]);
    updateUI.setColumns(shortcut.grid[1]);

    if (shortcut.trend) {
        const trendFilters = {};
        const trendKeys = Object.keys(shortcut.trend);

        trendKeys.forEach((tk: string) => {
            trendFilters[tk] = {};
            const trendFilterKeys = Object.keys(shortcut.trend[tk]);
            trendFilterKeys.forEach((f: string) => {
                const index: number = ui.labels.findIndex((l: UILabel) => l.name === f);
                if (index > -1) {
                    trendFilters[tk][ui.labels[index].name] = new TrendFilter(ui.labels[index].name, ui.labels[index].text, ui.labels[index].dataType, [shortcut.trend[tk][f]]);
                }
            });
        });
        updateActiveTrends.setTrend(trendFilters);
    } else {
        updateActiveTrends.setTrend({});
    }

    updateUI.updateShortcutChanges(false);
}

export {
    SetShortcut,
};
