
import { Vue, Component, Prop } from 'vue-property-decorator';
import { config } from '@/observables/Config';
import { updateUI } from '@/observables/UI';
import Http from '@/auth/api/apiHelper';
import PopoverCard from '@/components/layout/card/PopoverCard.vue';
import dayjs from 'dayjs';

@Component({
    components: {
        PopoverCard,
    },
})
export default class RollForwardSelectionsRecursiveRow extends Vue {
    @Prop() report;

    @Prop({ default: 0 }) recursionLevel: number;

    @Prop() parentName: string;

    // #region dropdowns
    public showDropdown: boolean = false;

    toggleDropdown(): void {
        this.showDropdown = !this.showDropdown;
    }

    get hasDropdownOptions(): boolean {
        return this.report && this.report.children?.length > 0;
    }

    get permissions() {
        return config.permissions;
    }

    get organisationName () {
        return config.currentOrganisation.name;
    }

    visitReport() {
        localStorage.setItem(`report-access-info-${this.report.blobKey}`, JSON.stringify({
            organisationName: this.organisationName,
            password: this.report.password,
            expiry: dayjs().add(10, 'minute'),
        }));
        if (this.report.scenarioReport && this.recursionLevel === 0) {
            window.open(`${window.location.origin}/reports-table/${this.report.blobKey}`, '_blank');
        } else {
            window.open(`${window.location.origin}/report/${this.report.blobKey}`, '_blank');
        }
    }

    copyReport() {
        if (this.report.scenarioReport && this.recursionLevel === 0) {
            navigator.clipboard.writeText(`${window.location.origin}/reports-table/${this.report.blobKey}`);
        } else {
            navigator.clipboard.writeText(`${window.location.origin}/report/${this.report.blobKey}`);
        }
        this.$notify({
            title: 'Url copied',
            type: 'info',
            text: 'Report URL copied to clipboard',
        });
    }

    copyPassword() {
        navigator.clipboard.writeText(this.report.password);
        this.$notify({
            title: 'Password',
            type: 'info',
            text: 'Password copied to clipboard',
        });
    }

    deleteReport() {
        updateUI.setLoader(true);
        Http.post<any>(
            `${process.env.VUE_APP_API_ENDPOINT_PREDICT}/reservingClasses/reporting/DeleteReport`,
            {
                dataSetId: this.report.dataSetId,
                segmentationId: this.report.segmentationId,
                scenarioId: this.report.scenarioId,
                blobKey: this.report.blobKey,
            },
            { apiScope: [process.env.VUE_APP_API_SCOPE_PREDICT] },
        ).then((res) => {
            if (res.data.isValid) {
                this.$notify({
                    title: 'Report has been deleted',
                    type: 'success',
                });
                this.$emit('refresh-list');
            } else {
                res.data.messages.forEach((issue) => {
                    this.$notify({
                        title: 'There was an issue deleting the report',
                        type: 'error',
                        text: issue,
                    });
                });
            }
            updateUI.setLoader(false);
        }).catch(() => {
            updateUI.setLoader(false);
            this.$notify({
                title: '',
                type: 'error',
                text: 'There was an issue deleting the report',
            });
        });
    }

    // #endregion
}
