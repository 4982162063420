
import { Vue, Component, Prop } from 'vue-property-decorator';
import { BeatLoader } from '@saeris/vue-spinners';
import { config } from '@/observables/Config';
import { reservingClassFiltersAndSorting } from '@/observables/ReservingClassTableFiltersAndSorting';
import SwitchHeader from '../../common/Chart/SwitchHeader';

@Component({
    components: { BeatLoader },
})
export default class TabSwitcher extends Vue {
    @Prop() aTab: SwitchHeader;

    @Prop({ default: false }) noTabScroll: boolean;

    @Prop(Boolean) loading!: boolean;

    @Prop() headers: SwitchHeader[];

    @Prop({ default: 400 }) height: number;

    @Prop({ default: false }) hideTabs: boolean;

    public activeTab: SwitchHeader;

    public tabHolderOverflown: boolean = false;

    get doAnyTabsHaveSettingsOption():boolean {
        return this.headers.some((header) => header.showSettings);
    }

    get permissions() {
        return config.permissions;
    }

    public updateActiveTab (tabIndex: number): void {
        if (this.loading) return;
        this.activeTab = this.headers[tabIndex];
        this.$emit('tab-update', tabIndex);
    }

    settingAction (tabIndex: number) {
        this.$emit('settings-action', tabIndex);
    }

    openShortcut (tabIndex: number) {
        this.$emit('open-shortcut', tabIndex);
    }

    isOverflown () {
        const tabHolder: any = this.$refs.tabHolder;
        this.tabHolderOverflown = tabHolder.offsetWidth < tabHolder.scrollWidth;
    }

    closeTab ($event, tabIndex: number) {
        $event.stopPropagation();
        this.$emit('close-tab', tabIndex);
    }

    public handleTabClick(cancel: boolean, key: number, tab) {
        if (cancel) return;
        if (tab.additionType) {
            this.$emit('add-tab');
            return;
        }
        this.updateActiveTab(key);
    }

    public triggerRefresh() {
        this.$emit('trigger-refresh');
    }

    get activeShortCut(): string {
        return reservingClassFiltersAndSorting.activeShortCut;
    }

    get showShortCutUndo(): boolean {
        // return reservingClassFiltersAndSorting.showShortCutUndo;
        return false;
    }
}
