
import { Vue, Component, Prop } from 'vue-property-decorator';
import { ScaleLoader } from '@saeris/vue-spinners';
import fileSave from 'file-saver';
import { projections } from '@/observables/Projections';
import { base64ToBlob } from '@/functions/objects';
import ErrorModal from '@/common/ErrorModal';
import { config } from '@/observables/Config';
import { updateUI } from '@/observables/UI';
import Http from '@/auth/api/apiHelper';
import { IFileDownload } from '@/interfaces/IFileDownload';

interface IProjectionRequest{
    ersId: string;
    export: string;
    extractAllReservingClassesInScenario: boolean;
    viewAtCohortGranularity?: string;
}

@Component({
    components: {
        ScaleLoader,
    },
})
export default class ProjectionsFileDownloadInput extends Vue {
    @Prop({ default: null }) fileName: IFileDownload;

    @Prop({ default: false }) extractAllReservingClassesInScenario: boolean;

    @Prop({ default: null }) downloadTableId: string;

    public downloadingStatus: boolean = false;

    private routeMap = {
        patternsSelect: 'Patterns Select',
        patternsTrending: 'Patterns Trending',
        patternsExclusions: 'Patterns Exclusions',
        initialExpecteds: 'Initial Expecteds',
        methodSelection: 'Method Selection',
        projectionsReview: 'Review & Save',
    }

    get fileNameText () {
        return `${this.downloadTableId ? this.fileName[0].id : `${this.routeMap[this.routeName]} - ${this.fileName.text} - ${this.extractAllReservingClassesInScenario ? 'All Reserving Classes' : projections.reservingClass}`}`;
    }

    get selectedGranularity(): string {
        return projections.projectionSidebarOptions.selectedCohortGranularity === 'Default' ? '' : projections.projectionSidebarOptions.selectedCohortGranularity;
    }

    get routeName(): string {
        return this.$route.name;
    }

    get createGranularitySuffix(): string {
        if (this.selectedGranularity === '') return '';
        return ` - ${this.selectedGranularity}`;
    }

    async generateFile () {
        try {
            this.downloadingStatus = true;
            const projectionRequest: IProjectionRequest = {
                ersId: projections.ersId,
                export: this.fileName.id,
                extractAllReservingClassesInScenario: this.extractAllReservingClassesInScenario,
                viewAtCohortGranularity: this.fileName.addGranularity ? this.selectedGranularity : undefined,
            };
            let granularityFileSuffix = '';
            if (this.fileName.addGranularity) {
                granularityFileSuffix = this.createGranularitySuffix;
            }
            const tableRequest = {
                segmentationId: projections.segmentationId,
                dataSetId: config.dataSetId,
                scenarioId: projections.scenarioId,
                tableId: this.downloadTableId,
                export: this.requestExport(this.$route.name),
            };
            const response = await Http.post<any>(`${process.env.VUE_APP_API_ENDPOINT_PREDICT}/ExportScenarioData`, this.downloadTableId ? tableRequest : projectionRequest, { apiScope: [process.env.VUE_APP_API_SCOPE_PREDICT] });
            const matches = response.data.match(/^data:([A-Za-z-+/.]+);base64,(.+)$/);
            console.log(matches);
            if (matches.length === 3) {
                const blob = base64ToBlob(matches[2], matches[1]);
                fileSave.saveAs(blob, `${this.fileNameText}${granularityFileSuffix}.xlsx`);
            }
            this.downloadingStatus = false;
        } catch (error) {
            updateUI.updateErrorModal((new ErrorModal('', 'welcome', 'Welcome Page')));
        }
    }

    private requestExport(routeName: string): string {
        if (routeName === 'manageScenarios') return 'manageScenarios';
        if (routeName === 'reservingClass') return 'reviewTable';
        return routeName;
    }
}
