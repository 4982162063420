
import { Vue, Component, Prop } from 'vue-property-decorator';
import { config } from '@/observables/Config';
import FileNameUrl from '@/common/blob/FileNameUrl';
import { updateUI } from '@/observables/UI';
import ErrorModal from '@/common/ErrorModal';
import Http from '@/auth/api/apiHelper';

@Component
export default class FileDownloadInput extends Vue {
    @Prop({
        default: () => [
            'triangle_data.csv',
            'vector_data.csv',
            'segment_info.csv',
            'metrics.csv',
            'chart_labels.csv',
            'drilldownoptions.csv',
        ],
    }) fileNames: string[];

    @Prop({ default: '' }) title: string;

    @Prop({ default: null }) exportScenarioData: string;

    downloadFiles: FileNameUrl[] = [];

    created () {
        try {
            updateUI.setLoader(true);
            if (config.dataSetId) {
                const request = {
                    fileNames: this.fileNames,
                    dataSetId: config.dataSetId,
                };
                Http.post<any>(`${process.env.VUE_APP_API}/api/FileDownload`, request, { apiScope: [process.env.VUE_APP_B2C_SCOPE] }).then((response) => {
                    this.downloadFiles = response.data;
                    updateUI.setLoader(false);
                });
            }
        } catch (error) {
            updateUI.updateErrorModal((new ErrorModal('', 'welcome', 'Welcome Page')));
        }
    }

    formattedName (fileName: string) {
        switch (fileName) {
        case 'triangle_data.csv':
            return 'Triangle Data';
        case 'vector_data.csv':
            return 'Vector Data';
        case 'segment_info.csv':
            return 'Entity Information';
        case 'metrics.csv':
            return 'Metrics';
        case 'chart_labels.csv':
            return 'Chart Label Data';
        case 'triangles_processed.csv':
            return 'Triangles Processed';
        case 'vectors_processed.csv':
            return 'Vectors Processed';
        case 'entity_info_processed.csv':
            return 'Entity Information Processed';
        case 'chart_metrics.csv':
            return 'Chart Metric Data';
        case 'drilldownoptions.csv':
            return 'Drilldown options';
        case 'review_reserving_class_table':
            return 'Review Reserving Class Table';
        default:
            return fileName;
        }
    }
}
