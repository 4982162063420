
import { Vue, Component } from 'vue-property-decorator';
import { ui } from '@/observables/UI';
import LCPInsurSightLogo from '@/assets/svg/LCPInsurSightLogo.vue';
import LCPInsurSightLogoLight from '@/assets/svg/LCPInsurSightLogoLight.vue';

@Component({
    components: {
        LCPInsurSightLogo,
        LCPInsurSightLogoLight,
    },
})
export default class TopBarReport extends Vue {
    get darkMode () {
        return ui.darkMode;
    }
}
