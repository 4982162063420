export default class ScenarioIdName {
    scenarioName: string;

    scenarioId: string;

    scenarioColour: string;

    canDelete: boolean;

    reserveClassCount: string;

    totalReserves: string;

    tableId: string;

    oldScenarioId: string | null;

    oldScenarioName: string | null;

    isReadOnly: boolean;

    hasRollForward: boolean;

    manageExportApi: boolean;

    dataLoadInProgress: boolean;

    constructor(
        scenarioName: string = null,
        scenarioId: string = null,
        scenarioColour: string = null,
        canDelete: boolean = false,
        reserveClassCount: string = null,
        totalReserves: string = null,
        tableId: string = null,
        oldScenarioId: string = null,
        oldScenarioName: string = null,
        isReadOnly: boolean = false,
        hasRollForward: boolean = false,
        manageExportApi: boolean = false,
        dataLoadInProgress: boolean = false,
    ) {
        this.scenarioName = scenarioName;
        this.scenarioId = scenarioId;
        this.scenarioColour = scenarioColour;
        this.canDelete = canDelete;
        this.reserveClassCount = reserveClassCount;
        this.totalReserves = totalReserves;
        this.tableId = tableId;
        this.oldScenarioId = oldScenarioId;
        this.oldScenarioName = oldScenarioName;
        this.isReadOnly = isReadOnly;
        this.hasRollForward = hasRollForward;
        this.manageExportApi = manageExportApi;
        this.dataLoadInProgress = dataLoadInProgress;
    }
}
