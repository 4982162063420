
import { Vue, Component, Prop } from 'vue-property-decorator';
import { IRollForwardOption } from '@/interfaces/IRollForwardPayload';
import RadioBox from '@/components/layout/rollForward/RadioBox.vue';

@Component({
    components: {
        RadioBox,
    },
})
export default class RollForwardSelectionsRadioButtons extends Vue {
    @Prop() options: IRollForwardOption[];

    @Prop() name: string;

    @Prop() selectedValue: string;

    @Prop() disabled?: boolean;

    @Prop() dropSelector?: boolean;

    @Prop({ default: false }) readonly: boolean;

    updateSelectedOption(optionName: string) {
        this.$emit('select-option', optionName);
    }
}
