
import {
    Vue, Component, Prop,
} from 'vue-property-decorator';

import dayjs from 'dayjs';
import PopoverCard from '@/components/layout/card/PopoverCard.vue';
import { VPopover } from 'v-tooltip';

@Component({ components: { VPopover, PopoverCard } })

export default class CommentInput extends Vue {
    @Prop({ default: false }) removed: boolean;

    @Prop({ default: '' }) comment: string;

    @Prop({ default: '' }) email: string;

    @Prop({ default: '' }) date: string;

    @Prop({ default: '' }) editId: string;

    @Prop({ default: '' }) replyId: string | null;

    @Prop({ default: false }) removable: boolean;

    @Prop({ default: false }) editComment: boolean;

    @Prop({ default: false }) reply: boolean;

    @Prop({ default: false }) readonly: boolean;

    commentInput: string = '';

    created () {
        this.commentInput = this.comment;
    }

    formattedDate (commentDate: string): string {
        return dayjs(commentDate).format('DD/MM/YYYY - h:mma');
    }

    get removedText () {
        return this.reply ? 'reply' : 'comment';
    }
}
