
import { Vue, Component, Watch } from 'vue-property-decorator';
import { filters } from '@/observables/Filters';
import { trends } from '@/observables/Trends';
import { config } from '@/observables/Config';
import { ui, uiUtil } from '@/observables/UI';
import ConfigSort from '@/common/ConfigSort';
import UILabel from '@/common/UILabel';
import NumberValue from '@/common/NumberValue';
import TrendFilter from '@/common/TrendFilter';
import ActiveFilter from '@/common/ActiveFilter';
import TagCategory from '@/common/TagCategory';
import Comments from './Comments.vue';

@Component({
    components: {
        Comments,
    },
})
export default class BottomBar extends Vue {
    numberInputText (numberInput: NumberValue) {
        if (Object.prototype.hasOwnProperty.call(numberInput, 'from') && Object.prototype.hasOwnProperty.call(numberInput, 'to')
        && numberInput?.from !== null && numberInput.to !== null) {
            return `${numberInput.from} to ${numberInput.to}`;
        }
        if (Object.prototype.hasOwnProperty.call(numberInput, 'from') && numberInput.from !== null) {
            return numberInput.from;
        }
        if (Object.prototype.hasOwnProperty.call(numberInput, 'to') && numberInput.to !== null) {
            return numberInput.to;
        }
        return '';
    }

    getTagLabel (tag: string, tagOptions: any) {
        let strRet: string = '';

        Object.keys(tagOptions).forEach((d: string) => {
            strRet += strRet === '' ? '' : ' OR ';
            strRet += `${tagOptions[d].name}`;
        });
        return strRet;
    }

    getLabel (label: string) {
        return uiUtil.getLabel(label);
    }

    getLabelValue (label: string, value: string) {
        return uiUtil.getLabelValue(label, value);
    }

    getTrendValues (trend: TrendFilter) {
        if (trend.dataType === 'number') {
            return `${trend.text}: ${this.numberInputText(trend.values[0] as NumberValue)}`;
        }
        let formatted = `${trend.text}: `;
        for (let t = 0; t < trend.values.length; t += 1) {
            if (t === 0) {
                formatted += ` ${trend.values[t]}`;
            } else {
                formatted += `, ${trend.values[t]}`;
            }
        }
        return formatted;
    }

    displayOrder (sort: ConfigSort) {
        const index = ui.labels.findIndex((i: UILabel) => i.name === sort.name);
        if (index > -1) {
            if (ui.labels[index].dataType === 'string') {
                return sort.order === 'desc' ? 'Z to A' : 'A to Z';
            }
            return sort.order === 'desc' ? 'High to Low' : 'Low to High';
        }
        return Object.prototype.hasOwnProperty.call(sort, 'order') ? sort.order : '';
    }

    get routeIsDiagnose () : boolean {
        return this.$route.meta?.routeIsDiagnose;
    }

    get configStore () {
        return config;
    }

    get activeFilters (): { [key: string]: ActiveFilter } {
        return filters.active;
    }

    get activeTrends () : {[key: string]: { [key: string]: any }} {
        return trends.active;
    }

    get tags () : {[key: string]: TagCategory} {
        return config.tags;
    }

    get activeSorts () : ConfigSort {
        return config.sortOption;
    }

    get showFiltering () {
        const af = Object.entries(this.activeFilters).length > 0 && this.activeFilters.constructor === Object;
        const at = Object.entries(this.activeTrends).length > 0 && this.activeTrends.constructor === Object;
        const t = Object.entries(this.tags).length > 0 && this.tags.constructor === Object;
        return af || at || t;
    }

    // #region comments

    get currentRouteName () {
        return this.$route.name;
    }

    public commentsAreOpen: boolean = false;

    public toggleCommentsOpenClose (): void {
        this.commentsAreOpen = !this.commentsAreOpen;
    }

    created() {
        this.$root.$refs.bottomBar = this;
    }

    public closeComments (): void {
        this.commentsAreOpen = false;
    }

    @Watch('currentRouteName')
    closeChatWhenRouteChange() {
        if (this.commentsAreOpen) this.toggleCommentsOpenClose();
    }
    // #endregion

    get uiStore() {
        return ui;
    }
}
