
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class BaseButton extends Vue {
  @Prop() handleClick!: Function;

  @Prop(Boolean) active?: boolean;

  @Prop(Boolean) disabled!: boolean;

  @Prop() id!: string;

  handleClickEvent() {
      if (this.disabled) return;
      this.handleClick(this.id);
  }
}
