import Vue from 'vue';
// import FilterConfig from '@/common/ReservingClasses/FilterConfig';
import HeaderGroup from '@/common/ReservingClasses/HeaderGroup';
// import FilterQuery from '@/common/ReservingClasses/FilterQuery';
// import FormattedFilters from '@/common/ReservingClasses/FormattedFilters';
import FilterQuery from '@/common/ReservingClasses/FilterQuery';
import FormattedFilters from '@/common/ReservingClasses/FormattedFilters';
import { projections } from '@/observables/Projections';
import { config } from '@/observables/Config';
import Http from '../auth/api/apiHelper';

const cloneDeep = require('clone-deep');

// interface ValuesBetween {
//     from: number|null
//     to: number|null
// }

// interface FilterQuery extends FilterQuery {
//     name?: string;
//     text?: string;
//     values?: any
//     filterConfig?: FilterConfig
//     headerGroup?: HeaderGroup;
// }

export interface ReservingClassSort {
    name?: string;
    text?: string;
    order?: string;
    rank: number;
    headerGroup?: HeaderGroup;
    columnName?: string;
}

interface ReservingClassFiltersAndSortingStore {
    filter: FilterQuery[]
    sort: ReservingClassSort[],
    activeShortCut: string,
    // complete: boolean,
}
interface FormattedFiltersStore {
    formattedFilters: [],

}

export const reservingClassFiltersAndSorting: ReservingClassFiltersAndSortingStore = Vue.observable({
    filter: [],
    sort: [],
    activeShortCut: '',
    // complete: true,
});

export const formattedFilters: FormattedFiltersStore = Vue.observable({
    formattedFilters: [],
});

export const updateReservingClassFiltersAndSorting = {
    resetFilter () {
        reservingClassFiltersAndSorting.filter = [];
    },
    resetSort () {
        reservingClassFiltersAndSorting.sort = [];
    },
    resetFormattedFilters () {
        formattedFilters.formattedFilters = [];
    },
    addFilter (filter: FilterQuery) {
        const index = reservingClassFiltersAndSorting.filter.findIndex((item) => item.name === filter.name);
        if (index > -1) {
            Vue.set(reservingClassFiltersAndSorting.filter, index, filter);
        } else reservingClassFiltersAndSorting.filter.push(filter);
    },
    removeFilter (filter: FilterQuery) {
        const index = reservingClassFiltersAndSorting.filter.findIndex((item) => item.name === filter.name);
        if (index > -1) Vue.delete(reservingClassFiltersAndSorting.filter, index);
    },
    addSort (sort: ReservingClassSort): void {
        const length = reservingClassFiltersAndSorting.sort.length;
        const index = reservingClassFiltersAndSorting.sort.findIndex((item) => item.name === sort.name);
        sort.rank = length + 1;
        if (index > -1) {
            Vue.set(reservingClassFiltersAndSorting.sort, index, sort);
        } else reservingClassFiltersAndSorting.sort.push(sort);
    },
    removeSort (sort: ReservingClassSort) {
        const index = reservingClassFiltersAndSorting.sort.findIndex((item) => item.name === sort.name);
        if (index > -1) Vue.delete(reservingClassFiltersAndSorting.sort, index);
        reservingClassFiltersAndSorting.sort.map((elem) => {
            if (elem.rank === 1) {
                return elem;
            }
            elem.rank -= 1;
            return elem;
        });
    },
    increaseRank(index: number) {
        const sort = reservingClassFiltersAndSorting.sort[index];
        if (index !== 0) {
            reservingClassFiltersAndSorting.sort.splice(index, 1);
            reservingClassFiltersAndSorting.sort.splice(index - 1, 0, sort);
            reservingClassFiltersAndSorting.sort.map((elem, i) => {
                const sortElem = elem;
                sortElem.rank = i + 1;
                return sortElem;
            });
        }
    },
    decreaseRank(index: number) {
        const length = reservingClassFiltersAndSorting.sort.length;
        const sort = reservingClassFiltersAndSorting.sort[index];
        if (index !== length - 1) {
            reservingClassFiltersAndSorting.sort.splice(index, 1);
            reservingClassFiltersAndSorting.sort.splice(index + 1, 0, sort);
            reservingClassFiltersAndSorting.sort.map((elem, i) => {
                const sortElem = elem;
                sortElem.rank = i + 1;
                return sortElem;
            });
        }
    },
    setFilterOption (tableConfig: any, filterCondition: string, shortCut: any, type: 'filter'|'sort') {
        const keys = Object.keys(tableConfig).filter((elem) => !['tableId', 'tableName', 'shortCuts'].includes(elem));
        const keyIndex = keys.findIndex((key) => tableConfig[key].some((elem) => elem.name === filterCondition));
        const groupIndex = tableConfig[keys[keyIndex]]?.findIndex((elem) => elem.name === filterCondition);
        const filterConfig = tableConfig[keys[keyIndex]][groupIndex];
        if (type === 'sort') {
            return {
                ...shortCut.sort[0], filterConfig, name: filterConfig.name, headerGroup: filterConfig.headerGroup, text: filterConfig.text,
            };
        }
        return {
            ...shortCut.filterConditions[0], filterConfig, name: filterConfig.name, headerGroup: filterConfig.headerGroup, text: filterConfig.text,
        };
    },
    updateFormattedFilters (formattedFiltersVals: FormattedFilters[]) {
        Vue.set(formattedFilters, 'formattedFilters', formattedFiltersVals);
    },
    setActiveShortCut (shortCut: string) {
        reservingClassFiltersAndSorting.activeShortCut = shortCut;
    },
    setShortCutFilters (shortCutFilters: FilterQuery[]) {
        reservingClassFiltersAndSorting.filter = shortCutFilters;
    },
    setShortCutSorting (shortCutSorting: ReservingClassSort[]) {
        reservingClassFiltersAndSorting.sort = shortCutSorting;
    },
    applyShortCut (shortCut: any, tableConfig: any) {
        updateReservingClassFiltersAndSorting.resetFilter();
        updateReservingClassFiltersAndSorting.resetSort();
        const updatedShortCut = cloneDeep(shortCut);
        if (shortCut?.filterConditions) {
            updatedShortCut.filterConditions = shortCut.filterConditions
                .map((filterCondition) => updateReservingClassFiltersAndSorting.setFilterOption(tableConfig, filterCondition.columnName, shortCut, 'filter'));
            const unformattedFilter = tableConfig.shortCuts.find((unformattedShortCut) => unformattedShortCut.id === updatedShortCut.id).filterConditions;
            updatedShortCut.filterConditions.map((filter) => {
                const unformattedValue = unformattedFilter.find((val) => val.columnName === filter.name);
                if (filter.filterConfig.formatGroup === 'string' && filter.values) {
                    return filter;
                }
                if (['number', 'ratio', 'currency', 'dateTime'].includes(filter.filterConfig.formatGroup)) {
                    filter.values = [{ from: unformattedValue.from, to: unformattedValue.to }];
                } else filter.values = [unformattedValue];
                return filter;
            });
            updateReservingClassFiltersAndSorting.setShortCutFilters(updatedShortCut.filterConditions);
        }
        if (shortCut?.sort) {
            updatedShortCut.sort = shortCut.sort
                .map((sortCondition) => updateReservingClassFiltersAndSorting.setFilterOption(tableConfig, sortCondition.columnName, shortCut, 'sort'));
            updateReservingClassFiltersAndSorting.setShortCutSorting(updatedShortCut.sort);
        }
        updateReservingClassFiltersAndSorting.setActiveShortCut(updatedShortCut.name);
    },
    checkForDefaultShortcut (): Promise<void> {
        // reservingClassFiltersAndSorting.complete = false;
        return Http.post<any>(
            `${process.env.VUE_APP_API_ENDPOINT_PREDICT}/GetTableConfig`,
            {
                dataSetId: config.dataSetId,
                segmentationId: projections.segmentationId,
                tableId: projections.tableId,
            },
            { apiScope: [process.env.VUE_APP_API_SCOPE_PREDICT], showSuccessMessage: true },
        ).then((response) => {
            const tableConfig = response.data;
            const index = tableConfig.formattedShortcuts.findIndex((shortCut) => shortCut.isDefault);
            if (index > -1) {
                updateReservingClassFiltersAndSorting.applyShortCut(tableConfig.formattedShortcuts[index], tableConfig.table);
            }
            // reservingClassFiltersAndSorting.complete = true;
        }).catch(() => {
            // updateUI.updateErrorModal((new ErrorModal('', 'welcome', 'Welcome Page')));
            console.log('error');
        });
    },

};
