export default class ReservingClassTotals {
    totalReserves: string;

    filteredAsPercentageOfTotal: string;

    filteredTotalReserves: string;

    reserveClassCount: number;

    reserveClassTotal: number;

    constructor (
        totalReserves: string = '',
        filteredTotalReserves: string = '',
        filteredAsPercentageOfTotal: string = '',
        reserveClassCount: number = 0,
        reserveClassTotal: number = 0,
    ) {
        this.totalReserves = totalReserves;

        this.filteredTotalReserves = filteredTotalReserves;

        this.filteredAsPercentageOfTotal = filteredAsPercentageOfTotal;

        this.reserveClassCount = reserveClassCount;

        this.reserveClassTotal = reserveClassTotal;
    }
}
