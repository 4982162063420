
import {
    Vue, Component, Prop, Watch,
} from 'vue-property-decorator';
import { updateUI } from '@/observables/UI';

interface ITourStep {
    target: string;
    content: string;
    findBy?: string;
    targetValue?: string;
    params?: {
        placement?: string;
        showArrow?: boolean;
        targetOffset?: number;
        viewPadding?: number;
    };
}

@Component
export default class PageTour extends Vue {
    @Prop() steps!: Array<ITourStep>;

    // #region steps
    public currentStepIx = 0;

    get currentStep(): any { return this.filteredSteps[this.currentStepIx]; }

    public filteredSteps: Array<ITourStep> = [];

    public back():void {
        if (this.currentStepIx - 1 < 0) return;
        this.removeTargetClass(this.currentStep.target);
        this.currentStepIx -= 1;
        this.positionTooltip();
    }

    public endTour():void {
        this.removeAllHighlightedClass();
        updateUI.setShowTour(false);
    }

    public next():void {
        if (this.currentStepIx + 1 > this.steps.length) return;
        this.removeTargetClass(this.currentStep.target);
        this.currentStepIx += 1;
        this.positionTooltip();
    }

    @Watch('currentStepIx', { immediate: true })
    getStepsThatExistInView() {
        this.filteredSteps = [];
        this.steps.forEach((step:ITourStep) => {
            if (step.findBy) {
                const target = document.querySelector(step.findBy);
                if (target) {
                    target.setAttribute('data-v-step', step.targetValue);
                }
            }
        });
        // check if tour element exists
        this.steps.forEach((step:ITourStep) => {
            if (document.querySelector(step.target)) {
                this.filteredSteps.push(step);
            }
        });
    }
    // #endregion

    beforeDestroy() {
        this.removeAllHighlightedClass();
        updateUI.setShowTour(false);
        document.removeEventListener('keyup', this.onKeyup);
    }

    // #region tooltip positioning
    positionTooltip (): void {
        const target = document.querySelector(this.currentStep.target);
        if (target) {
            this.$nextTick(() => {
                target.classList.add('lcp-tour-highlighted');
            });
        }
    }
    // #endregion

    // #region add/remove class
    public removeTargetClass (previousTarget): void {
        const target = document.querySelector(previousTarget);
        if (target) {
            target.classList.remove('lcp-tour-highlighted');
        }
    }

    public removeAllHighlightedClass():void {
        const targets = document.querySelectorAll('.lcp-tour-highlighted');
        targets.forEach((target) => {
            target.classList.remove('lcp-tour-highlighted');
        });
        this.currentStepIx = 0;
    }
    // #endregion

    // #region tour options
    public myOptions = {
        useKeyboardNavigation: true,
        labels: {
            buttonSkip: 'Skip tour [Esc]',
            buttonPrevious: 'Previous [←]',
            buttonNext: 'Next [→]',
            buttonStop: 'Finish [Esc]',
        },
    }

    get vTourCallbacks () {
        return {
            onPreviousStep: this.back,
            onNextStep: this.next,
            onStart: this.positionTooltip,
            onSkip: this.endTour,
            onFinish: this.endTour,
        };
    }
    // #endregion

    // #region key events
    onKeyup(event):void {
        if (event.key === 'Escape') {
            // this.removeAllHighlightedClass();
            this.endTour();
        }
    }

    mounted() {
        document.addEventListener('keyup', this.onKeyup);
    }
    // #endregion
}
