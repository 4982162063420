import vue from 'vue';
import TrendFilter from '@/common/TrendFilter';
import NumberValue from '@/common/NumberValue';

interface Store {
    active: {[key: string]: { [key: string]: any }},
    trend: TrendFilter[],
    linked: {[key: string]: { [key: string]: any }},
}

export const trends: Store = vue.observable({
    active: {},
    trend: [],
    linked: null,
});

export const updateTrend = {
    setupTrend (filter: TrendFilter[]) {
        trends.trend = filter;
    },
};

export const updateActiveTrends = {
    resetFilter () {
        trends.active = {};
    },
    setTrend (trend: {}) {
        trends.active = trend;
    },
    setLinked (linked: {} | null) {
        trends.linked = linked;
    },
    addTrend (trendKey: string) {
        vue.set(trends.active, trendKey, {});
    },
    removeTrend (trend: string) {
        if (Object.prototype.hasOwnProperty.call(trends.active, trend)) {
            vue.delete(trends.active, trend);
        }
    },
    setupTrend (trendKey: string, value: any) {
        vue.set(trends.active[trendKey], value.name, value);
    },
    updateTrend (trendKey: string, trend: string, value: any) {
        trends.active[trendKey][trend].values.push(value);
    },
    removeTrendValue (trendKey: string, trend: string, value: any) {
        if (Object.prototype.hasOwnProperty.call(trends.active, trendKey)) {
            if (Object.prototype.hasOwnProperty.call(trends.active[trendKey], trend)) {
                const index = trends.active[trendKey][trend].values.findIndex((i: string) => i === value);
                if (index > -1) {
                    vue.delete(trends.active[trendKey][trend].values, index);
                }
            }
        }
    },
    removeNumberTrend (trendKey: string, trend: string) {
        if (Object.prototype.hasOwnProperty.call(trends.active, trendKey)) {
            if (Object.prototype.hasOwnProperty.call(trends.active[trendKey], trend)) {
                trends.active[trendKey][trend].values = [];
            }
        }
    },
    updateNumberFilter (trend: string, key: string, value: NumberValue) {
        trends.active[trend][key].values = [value];
    },
    resetDefaults () {
        trends.active = {};
        trends.trend = [];
        trends.linked = null;
    },
};
