
import { Vue, Component, Prop } from 'vue-property-decorator';
import { VTooltip, VPopover } from 'v-tooltip';
import ScenarioIdName from '@/common/projections/ScenarioIdName';
import { ui, updateUI } from '@/observables/UI';
import { config, updateConfig } from '@/observables/Config';
import { projections, updateProjections } from '@/observables/Projections';
import MainConfig from '@/common/MainConfig';
import { logout } from '@/auth/MsalAuth';
import ChartSettingsModal from '@/components/layout/modals/ChartSettingsModal.vue';
import { SetShortcut } from '@/functions/WelcomeShortcuts';
import FileDownloads from '@/components/layout/modals/FileDownloads.vue';
import DataFormatModal from '@/components/layout/modals/DataFormatModal.vue';
import WelcomeButtonsModal from '@/components/layout/modals/WelcomeButtonsModal.vue';
import ErrorModal from '@/common/ErrorModal';
import Choice from '@/common/Choice';
// import ChartCount from '@/common/ChartCount';
// import QueryDataSet from '@/common/QueryDataSet';
// import { filters } from '@/observables/Filters';
// import { trends } from '@/observables/Trends';
import { Watch } from 'vue-slider-component/node_modules/vue-property-decorator';
import ChartControls from './ChartControls.vue';
import Http from '../../auth/api/apiHelper';

Vue.use(VTooltip);

@Component({
    components: {
        ChartControls,
        VPopover,
        DataFormatModal,
        FileDownloads,
        ChartSettingsModal,
        WelcomeButtonsModal,
    },
})
export default class TopBar extends Vue {
    @Prop() sessionIdHeaderIsSet: boolean;

    public dataSets: MainConfig[] = [];

    public showDownloads: boolean = false;

    showWelcomeModal = false;

    get routeRequiresAuth(): boolean {
        return this.$route.meta?.requiresAuth;
    }

    get showTourIcon(): boolean {
        return this.$route.meta?.showTour;
    }

    get routeIsDiagnose(): boolean {
        return this.$route.meta?.routeIsDiagnose;
    }

    get routeRequiresErs() {
        return this.$route.meta?.requiresErsId;
    }

    get scenarioDataFormat() {
        return projections.scenarioDataFormat;
    }

    get showSegmentationValueFormat() {
        return this.$route.meta?.allowSegmentationDataFormatting;
    }

    get permissions() {
        return config.permissions;
    }

    public home () {
        if (projections.segmentationId || projections.segmentationName) {
            updateProjections.updateSegmentation({ segmentationName: '', segmentationId: '' });
            updateProjections.updateScenario(new ScenarioIdName());
            updateProjections.updateReservingClass('');
        }
        updateConfig.updateDataSetName('');
        this.$router.push('/');
    }

    public gotToOrganisationSettings(): void {
        this.$router.push('/organisation-settings');
    }

    public logOut () {
        logout();
    }

    public exportCharts () {
        updateUI.updateExportChartsTrigger();
    }

    get dataSetName () {
        return config.dataSetName;
    }

    get organisationName () {
        return config.currentOrganisation?.name;
    }

    get drilldownLevel (): number {
        return config.drilldownLevel;
    }

    get darkMode () {
        return ui.darkMode;
    }

    public toggleDarkMode (): void {
        updateUI.setDarkMode(!ui.darkMode);
    }

    public toggleTour (): void {
        updateUI.setShowTour(!ui.showTour);
        if (!this.tourActive) {
            const targets = document.querySelectorAll('.lcp-tour-highlighted');
            targets.forEach((target) => {
                target.classList.remove('lcp-tour-highlighted');
            });
            return;
        }
        this.$nextTick(() => {
            this.$nextTick(() => {
                this.$tours.myTour.start();
            });
        });
    }

    get tourActive(): boolean {
        return ui.showTour;
    }

    // get uiStore() {
    //     return ui;
    // }

    // get trendStore() {
    //     return trends;
    // }

    // get configStore() {
    //     return config;
    // }

    // get filters() {
    //     return filters;
    // }

    // #region shortcuts
    get currentShortcut(): Choice {
        return ui.currentShortcut;
    }

    get shortcutChanges(): boolean {
        return ui.shortcutChanges;
    }

    get usingShortcut(): boolean {
        return this.currentShortcut?.text.toLowerCase() !== 'free explore';
    }

    undoShortcutChanges(): void {
        updateUI.setLoader(true);
        SetShortcut(this.currentShortcut);
        updateUI.updateShortcutChanges(false);
        updateUI.setLoader(false);
    }
    // #endregion

    // #region scenario/segmentation format data
    get scenarioDataFormatRequest () {
        return {
            dataSetId: config.dataSetId,
            segmentationId: projections.segmentationId,
        };
    }

    getScenarioFormatValues(): void {
        if (!this.scenarioDataFormat) {
            Http.post<any>(
                `${process.env.VUE_APP_API_ENDPOINT_PREDICT}/GetTableConfigs`,
                this.scenarioDataFormatRequest,
                { apiScope: [process.env.VUE_APP_API_SCOPE_PREDICT] },
            ).then((tableConfig) => {
                updateProjections.updateScenarioDataFormat(tableConfig.data.dataFormatting);
            }).catch(() => {
                updateUI.updateErrorModal((new ErrorModal('', 'welcome', 'Welcome Page')));
            });
        }
    }

    @Watch('scenarioDataFormatRequest', { deep: true })
    wipeScenarioDataFormat() {
        if (this.scenarioDataFormat) {
            updateProjections.updateScenarioDataFormat(null);
        }
    }
    // #endregion
}
