
import { Vue, Component, Prop } from 'vue-property-decorator';
import FilterConfig from '@/common/ReservingClasses/FilterConfig';
import { reservingClassFiltersAndSorting, updateReservingClassFiltersAndSorting } from '@/observables/ReservingClassTableFiltersAndSorting';
import FormattedFilters from '@/common/ReservingClasses/FormattedFilters';
import { projections } from '@/observables/Projections';
import { formatValuesUsingHeaderValueFormat } from '@/functions/tools';

@Component({
    components: {

    },
})
export default class ReservingClassFiltersOrSortCard extends Vue {
    @Prop() filterSortData: any;

    @Prop() filterConfig: FilterConfig;

    @Prop() formattedFilters: FormattedFilters[];

    @Prop() index: number;

    @Prop(Boolean) filter: boolean;

    get reservingClassFiltersAndSorting() {
        return reservingClassFiltersAndSorting;
    }

    get currentFilters () {
        return reservingClassFiltersAndSorting.filter;
    }

    get currentSorting () {
        return reservingClassFiltersAndSorting.sort;
    }

    public increaseRank(index: number) {
        if (index > 0) {
            updateReservingClassFiltersAndSorting.increaseRank(index);
        }
    }

    public decreaseRank(index: number) {
        if (this.currentSorting.length - 1 !== index) {
            updateReservingClassFiltersAndSorting.decreaseRank(index);
        }
    }

    public removeFilter(filter) {
        updateReservingClassFiltersAndSorting.removeFilter(filter);
    }

    public removeSort(sort) {
        updateReservingClassFiltersAndSorting.removeSort(sort);
    }

    get currentDataTypeFormatting() {
        return projections.scenarioDataFormat[this.currentDataType].valueFormat;
    }

    get currentDataType() {
        return this.filterSortData.filterConfig.formatGroup;
    }

    get formattedFilterSortData() {
        return { from: formatValuesUsingHeaderValueFormat(this.currentDataTypeFormatting, this.filterSortData.values[0].from), to: formatValuesUsingHeaderValueFormat(this.currentDataTypeFormatting, this.filterSortData.values[0].to) };
    }

    get currentFilteredDisplayValues() {
        const index = this.formattedFilters.findIndex((filter: FormattedFilters) => filter.columnName === this.filterSortData.name);
        if (index > -1) {
            return this.formattedFilters[index].values;
        }
        return null;
    }
}
