export default class TrendFilter {
    name: string;

    text: string;

    dataType: string;

    values: (object | string)[];

    constructor (name: string, text: string, dataType: string, values: (object | string)[]) {
        this.name = name;
        this.text = text;
        this.dataType = dataType;
        this.values = values;
    }
}
