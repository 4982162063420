
import { Vue, Component, Prop } from 'vue-property-decorator';
import { ScaleLoader } from '@saeris/vue-spinners';
import { ui } from '@/observables/UI';

@Component({
    components: {
        ScaleLoader,
    },
})
export default class LoaderModal extends Vue {
    @Prop({ default: null }) loadingText: string;

    get loaderText () {
        return this.loadingText !== null ? this.loadingText : ui.loaderText;
    }
}
