import { render, staticRenderFns } from "./LabelsPanel.vue?vue&type=template&id=34f38dff&scoped=true&"
import script from "./LabelsPanel.vue?vue&type=script&lang=ts&"
export * from "./LabelsPanel.vue?vue&type=script&lang=ts&"
import style0 from "./LabelsPanel.vue?vue&type=style&index=0&id=34f38dff&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "34f38dff",
  null
  
)

export default component.exports