
import { Vue, Component, Prop } from 'vue-property-decorator';
import { IRollForwardTemplate } from '@/interfaces/IRollForwardTemplate';
import { IRollForwardSelection, IApproach } from '@/interfaces/IRollForwardPayload';
import RollForwardSelectionsRadioButtons from '@/components/layout/rollForward/RollForwardSelectionsRadioButtons.vue';

const cloneDeep = require('clone-deep');

@Component({
    components: {
        RollForwardSelectionsRadioButtons,
    },
})
export default class RollForwardSelectionsRecursiveRow extends Vue {
    @Prop() selectionsRow: IApproach | IRollForwardSelection;

    @Prop() selectionName: string;

    @Prop() rollForwardTemplate: IRollForwardTemplate;

    @Prop({ default: 0 }) recursionLevel: number;

    @Prop() parentName: string;

    @Prop({ default: false }) readonly: boolean;

    @Prop() highLightedSelectionsApproach: null | Array<string>;

    created() {
        this.toggleToHighlighted();
    }

    // #region dropdowns
    public showDropdown: boolean = false;

    toggleDropdown(): void {
        this.showDropdown = !this.showDropdown;
    }

    get hasDropdownOptions(): boolean {
        return this.checkIfHasDropdownOptions(this.selectionsRow);
    }

    checkIfHasDropdownOptions (selectionsRow : IApproach | IRollForwardSelection): boolean {
        return Object.keys(selectionsRow).length > 0 && Object.keys(selectionsRow).indexOf('selectedApproach') === -1;
    }

    // #endregion

    optionSelectedValue(
        optionObj: IRollForwardSelection | string | {[key: string] : any},
        type: string,
    ): IRollForwardSelection | string {
        if (typeof optionObj === 'string') {
            return optionObj;
        }
        const keys = Object.keys(optionObj);
        if (optionObj[type] || keys.length === 0) {
            return optionObj[type];
        }

        const allMatching = [];
        keys.forEach((key) => {
            allMatching.push(this.optionSelectedValue(optionObj[key], type));
        });
        return allMatching.every((value) => value === allMatching[0]) ? allMatching[0] : '';
    }

    updateSelectionsRow(
        selectionsRowObj: IApproach | IRollForwardSelection,
        selectionName: string,
    ) {
        const copySelectionsRow = cloneDeep(this.selectionsRow);
        copySelectionsRow[selectionName] = selectionsRowObj;
        this.$emit('update-selections-row', copySelectionsRow, this.selectionName);
    }

    selectApproachOption(
        selectionsRow: IApproach | IRollForwardSelection,
        optionValue: string,
        type: string | number,
        emit: boolean,
        selectionName: string,
    ) {
        let copySelectionsRow = cloneDeep(selectionsRow);
        if (this.checkIfHasDropdownOptions(selectionsRow)) {
            const selectionsKeys = Object.keys(selectionsRow);
            selectionsKeys.forEach((key) => {
                copySelectionsRow[key] = this.selectApproachOption(copySelectionsRow[key], optionValue, type, false, key);
            });
        } else {
            copySelectionsRow = this.getNewValuesObject(optionValue, type, selectionsRow);
        }
        if (emit) {
            this.$emit('update-selections-row', copySelectionsRow, selectionName);
        }

        return copySelectionsRow;
    }

    getNewValuesObject(
        optionValue: string,
        type: string | number,
        previousValues: IRollForwardSelection | IApproach,
    ) {
        const newValues = {
            selectedApproach: previousValues.selectedApproach || '',
            defaultLiveApproach: previousValues.defaultLiveApproach || '',
        };
        if (type === 'selectedApproach') {
            newValues.selectedApproach = optionValue;
            if (optionValue !== 'Fixed') {
                newValues.defaultLiveApproach = optionValue;
            }
        } else {
            newValues.defaultLiveApproach = optionValue;
        }
        return newValues;
    }

    get isHighlighted() {
        if (this.highLightedSelectionsApproach && this.highLightedSelectionsApproach.length > 0) {
            if (this.highLightedSelectionsApproach.length === 1 && this.selectionName === this.highLightedSelectionsApproach[0]) {
                return true;
            }
            return false;
        }
        return false;
    }

    get childHighlightedSelectionApproach() {
        if (this.highLightedSelectionsApproach && this.highLightedSelectionsApproach.length > 1 && this.selectionName === this.highLightedSelectionsApproach[0]) {
            return this.highLightedSelectionsApproach.slice(1);
        }
        return null;
    }

    toggleToHighlighted() {
        if (this.highLightedSelectionsApproach && this.highLightedSelectionsApproach.length > 1 && this.selectionName === this.highLightedSelectionsApproach[0]) {
            this.toggleDropdown();
        }
    }
}
