var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"bottom-wrapper",attrs:{"id":"page-footer"}},[(_vm.routeIsDiagnose)?_c('div',{staticClass:"filter-wrapper"},[(_vm.showFiltering)?_c('div',{staticClass:"filters"},[_vm._v(" Filtering on: "),_vm._l((_vm.activeFilters),function(category,key){return _c('div',{key:key,staticClass:"filter-item"},[_c('div',{staticClass:"category"},[_vm._v(_vm._s(_vm.getLabel(category.text)))]),(category.dataType === 'string')?_c('div',{staticClass:"value-wrapper"},[(category.values.length > 3)?_c('div',{staticClass:"filter"},[_vm._v(" "+_vm._s(category.values.length)+"+ ")]):_vm._l((category.values),function(filter){return _c('div',{key:`${filter}-text`,staticClass:"filter"},[_vm._v(" "+_vm._s(_vm.getLabelValue(category.text, filter))+" ")])})],2):_vm._e(),(category.dataType === 'number')?_c('div',{staticClass:"value-wrapper"},[_c('div',{staticClass:"filter"},[_vm._v(" "+_vm._s(_vm.numberInputText(category.values[0]))+" ")])]):_vm._e()])}),_vm._l((_vm.activeTrends),function(trendsParameters,key){return _c('div',{key:key,staticClass:"filter-item"},[_c('div',{staticClass:"category"},[_vm._v(_vm._s(_vm.getLabel(key)))]),_vm._l((trendsParameters),function(trendFilter){return [(
                            Object.keys(trendFilter).length > 0 &&
                            trendFilter.values.length > 0
                        )?[_c('div',{key:trendFilter.name,staticClass:"filter"},[_vm._v(" "+_vm._s(_vm.getTrendValues(trendFilter))+" ")])]:_vm._e()]})],2)}),_vm._l((_vm.tags),function(tagOptions,tag){return _c('div',{key:tag + 'tag',staticClass:"filter-item"},[_c('div',{staticClass:"category"},[_vm._v(_vm._s(`${tagOptions.name} tag`))]),_c('div',{staticClass:"filter"},[_vm._v(" "+_vm._s(_vm.getTagLabel(tagOptions.name, tagOptions.tags))+" ")])])})],2):_vm._e(),(_vm.showFiltering)?_c('div',{staticClass:"divider"},[_vm._v("|")]):_vm._e(),(_vm.routeIsDiagnose)?_c('div',{staticClass:"filters"},[_vm._v(" Sorting on: "),_c('div',{staticClass:"filter-item"},[_c('div',{staticClass:"category"},[_vm._v(_vm._s(_vm.activeSorts.item))]),(_vm.displayOrder(_vm.activeSorts).length > 0)?_c('div',{staticClass:"sort"},[_vm._v(" "+_vm._s(_vm.displayOrder(_vm.activeSorts))+" ")]):_vm._e()])]):_vm._e()]):_vm._e(),_c('div',[_c('a',{staticClass:"policy",attrs:{"href":"https://lcpcloud.com/privacy","target":"_blank"}},[_vm._v("Privacy policy")]),_c('router-link',{staticClass:"policy",attrs:{"to":"/terms-conditions"}},[_vm._v(" Terms and Conditions ")]),(_vm.configStore.permissions.releaseManager)?_c('router-link',{staticClass:"policy",attrs:{"to":"/edit-terms-conditions"}},[_vm._v(" Edit Terms and Conditions ")]):_vm._e(),_c('a',{staticClass:"policy",attrs:{"href":"/LCP InsurSight User Guidance - 11 July 2022.pdf","download":""}},[_vm._v("User Guidance")]),_c('a',{staticClass:"policy",attrs:{"href":"mailto:insursight.helpdesk@lcp.uk.com","download":""}},[_vm._v("Contact Helpdesk")]),_c('router-link',{staticClass:"policy",attrs:{"to":"/release-notes"}},[_vm._v(" Release Notes ")])],1),(
            [
                'patternsSelect',
                'patternsTrending',
                'patternsExclusions',
                'initialExpecteds',
                'methodSelection',
            ].includes(_vm.$route.name) && !_vm.uiStore.projectionLoader
        )?_c('div',{staticClass:"segmentationComments"},[_c('div',{staticClass:"triggerIcon",class:{ active: _vm.commentsAreOpen },on:{"click":_vm.toggleCommentsOpenClose}},[_c('font-awesome-icon',{attrs:{"data-v-step":"page-comment","icon":['fad', 'comment-lines']}})],1),_c('div',{staticClass:"commentsComponent",class:{ open: _vm.commentsAreOpen }},[(_vm.commentsAreOpen)?_c('portal-target',{attrs:{"name":"ersComments"}}):_vm._e()],1)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }