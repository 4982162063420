export default class SwitchHeader {
    id: string;

    text: string;

    type: string;

    data: { [key: string]: any };

    showSettings: boolean;

    actionsCol: boolean;

    closable: boolean;

    reviewChartData?: any;

    additionType?: boolean;

    tagsCol?: boolean;

    constructor(
        id: string,
        text: string,
        type: string,
        data: { [key: string]: any },
        showSettings: boolean = false,
        actionsCol: boolean = false,
        closable: boolean = false,
        reviewChartData: any = {},
        additionType: boolean = false,
        tagsCol: boolean = false,
    ) {
        this.id = id;
        this.text = text;
        this.type = type;
        this.data = data;
        this.showSettings = showSettings;
        this.actionsCol = actionsCol;
        this.closable = closable;
        this.reviewChartData = reviewChartData;
        this.additionType = additionType;
        this.tagsCol = tagsCol;
    }
}
