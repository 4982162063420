
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
    components: {},
})
export default class Card extends Vue {
    @Prop() title!: string;

    @Prop() id!: string;
}
