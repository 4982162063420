export default class NumberFilter {
    from!: string | null;

    to!: string | null;

    fromCheck!: boolean;

    toCheck!: boolean;

    constructor (from: string | null = null, to: string | null = null, fromCheck: boolean = false, toCheck: boolean = false) {
        this.from = from;
        this.to = to;
        this.fromCheck = fromCheck;
        this.toCheck = toCheck;
    }
}
