
import { Vue, Component, Watch } from 'vue-property-decorator';
import PrettyCheck from 'pretty-checkbox-vue/check';
import { ui, updateUI } from '@/observables/UI';
import { config, updateConfig } from '@/observables/Config';
import UILabel from '@/common/UILabel';
import ConfigLabel from '@/common/ConfigLabel';

@Component({
    components: {
        PrettyCheck,
    },
})
export default class LabelsPanel extends Vue {
    get originalConfigLabels(): Array<ConfigLabel> {
        return config.labels;
    }

    // #region created local list
    localLabels: Array<any> = [];

    buildLocalList() {
        this.localLabels = [];
        this.originalConfigLabels.forEach((configLabel) => {
            const newLabel = {
                checked: configLabel.checked,
                item: configLabel.item,
                text: this.labelText(configLabel.item),
            };
            this.localLabels.push(newLabel);
        });
    }
    // #endregion

    // #region handle toggle of labels
    toggleChecked(key: number) {
        this.localLabels[key].checked = !this.localLabels[key].checked;
        this.updateConfigWithLocalState();
        updateUI.updateShortcutChanges(true);
    }

    updateConfigWithLocalState() {
        updateConfig.updateLabels(this.localLabels);
    }
    // #endregion

    created() {
        this.buildLocalList();
    }

    labelText (name: string) {
        const index: number = ui.labels.findIndex((i: UILabel) => i.name === name);
        return index > -1 ? ui.labels[index].text : name;
    }

    @Watch('originalConfigLabels', { deep: true })
    rebuildLocalList() {
        this.buildLocalList();
    }
}
