export default class Data {
    segment: string;

    value: string;

    outstanding: number;

    highlevelclass: string;

    genericclass: string;

    syndicate: string;

    constructor (segment: string, value: string, outstanding: number, highlevelclass: string, genericclass: string, syndicate: string) {
        this.segment = segment;
        this.value = value;
        this.outstanding = outstanding;
        this.highlevelclass = highlevelclass;
        this.genericclass = genericclass;
        this.syndicate = syndicate;
    }
}
