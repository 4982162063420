import Vue from 'vue';
import Router from 'vue-router';

// import axios from 'axios';
import { mainData } from '@/observables/MainData';
import { updateConfig, config } from '@/observables/Config';
import { ui, updateUI } from '@/observables/UI';
import { projections } from '@/observables/Projections';
import { noDatasetIdRedirectCheck } from '@/functions/tools';
import SideBar from '@/components/layout/SideBar.vue';
import ProjectionsConfigNavigation from '@/components/layout/projections/ProjectionsConfigNavigation.vue';
import axios from 'axios';
import { updateDataUpload } from './observables/DataUpload';
// import RequestUserLog from './common/RequestUserLog';
import { getToken, loggedIn } from './auth/MsalAuth';

Vue.use(Router);
// `
// async function UpdateUserLog(
//     dataSetId: string,
//     dataSetName: string,
//     routeName: string,
// ) {
//     await axios.post(
//         `${process.env.VUE_APP_API}/api/UpdateUserLog`,
//         new RequestUserLog(dataSetId, dataSetName, routeName),
//     );
// }`

const standalone = {
    standalone: true,
};

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/home',
            name: 'home',
            components: {
                default: () => import('./views/Home.vue'),
                SideBar,

            },
            beforeEnter: (_to, _from, next) => {
                if (mainData.chartData.length === 0) {
                    router.push('/welcome');
                } else {
                    next();
                }
            },
            meta: {
                requiresAuth: true,
                requiresInsurSightAccess: true,
                requiresOrganisation: true,
                routeIsDiagnose: true,
            },
        },
        {
            path: '/detail',
            name: 'detail',
            components: {
                default: () => import('./views/Detail.vue'),
                SideBar,
            },
            props: true,
            beforeEnter: (to, _from, next) => {
                if (
                    Object.entries(to.params).length === 0
                    && to.params.constructor === Object
                ) {
                    router.push('/welcome');
                } else {
                    next();
                }
            },
            meta: {
                requiresAuth: true,
                requiresInsurSightAccess: true,
                requiresOrganisation: true,
                routeIsDiagnose: true,
            },
        },
        {
            path: '/login',
            name: 'login',
            component: () => import('./views/LoginNew.vue'),
            meta: standalone,
        },
        {
            path: '/manage-reports',
            name: 'ManageReports',
            components: {
                default: () => import('./views/ManageReports.vue'),
                SideBar,

            },
            meta: {
                requiresAuth: true,
                requiresInsurSightAccess: true,
                requiresOrganisation: true,
                requiresSavePermission: true,
                routeIsPredict: true,
            },
        },
        {
            path: '/organisation-settings',
            name: 'OrganisationSettings',
            component: () => import('./views/OrganisationSettings.vue'),
            meta: {
                requiresAuth: true,
                requiresInsurSightAccess: true,
                requiresOrganisation: true,
                requiresSavePermission: true,
            },
            // beforeEnter: (_to, _from, next) => {
            //     updateDataUpload.defaultSettings();
            //     updateConfig.updateDataSetName('');
            //     next();
            // },
        },
        {
            path: '/manage-datasets',
            name: 'ManageDatasets',
            component: () => import('./views/ManageDatasets.vue'),
            meta: {
                requiresAuth: true,
                requiresInsurSightAccess: true,
                requiresOrganisation: true,
                requiresSavePermission: true,
            },
            beforeEnter: (_to, _from, next) => {
                updateDataUpload.defaultSettings();
                updateConfig.updateDataSetName('');
                next();
            },
        },
        {
            path: '/edit-dataset',
            name: 'EditDataset',
            component: () => import('./views/EditDataset.vue'),
            meta: {
                requiresAuth: true,
                requiresInsurSightAccess: true,
                requiresOrganisation: true,
                requiresSavePermission: true,
            },
            beforeEnter: (_to, _from, next) => {
                updateUI.setLoader(true);
                next();
            },
        },
        {
            path: '/dataset-upload',
            name: 'datasetUpload',
            component: () => import('./views/Datasets/UploadDataset.vue'),
            meta: {
                requiresAuth: true,
                requiresInsurSightAccess: true,
                requiresOrganisation: true,
                requiresSavePermission: true,
            },
            props: true,
        },
        {
            path: '/roll-forward-predict',
            name: 'rollForwardPredict',
            component: () => import('./views/RollForwardPredict.vue'),
            meta: {
                requiresAuth: true,
                requiresInsurSightAccess: true,
                requiresOrganisation: true,
                requiresSavePermission: true,
            },
            props: true,
        },
        {
            path: '/segmentations',
            name: 'segmentations',
            components: {
                default: () => import('./views/Segmentations.vue'),
                SideBar,
            },
            meta: {
                requiresAuth: true,
                requiresInsurSightAccess: true,
                requiresOrganisation: true,
                routeIsPredict: true,
            },
            beforeEnter: (_to, _from, next) => {
                updateUI.setLoader(true);
                noDatasetIdRedirectCheck(next);
            },
        },
        {
            path: '/scenarios',
            name: 'scenarios',
            components: {
                default: () => import('./views/Scenarios.vue'),
                SideBar,
            },
            meta: {
                requiresAuth: true,
                requiresInsurSightAccess: true,
                requiresOrganisation: true,
                routeIsPredict: true,
            },
        },
        {
            path: '/manage-scenarios',
            name: 'manageScenarios',
            components: {
                default: () => import('./views/ManageScenarios.vue'),
                SideBar,
            },
            meta: {
                requiresAuth: true,
                requiresInsurSightAccess: true,
                requiresOrganisation: true,
                routeIsPredict: true,
                allowSegmentationDataFormatting: true,
                requiresSavePermission: true,
            },
        },
        {
            path: '/reserving-class',
            name: 'reservingClass',
            components: {
                default: () => import('./views/ReservingClass.vue'),
                SideBar,
            },
            meta: {
                requiresAuth: true,
                requiresInsurSightAccess: true,
                requiresOrganisation: true,
                routeIsPredict: true,
                allowSegmentationDataFormatting: true,
                showTour: true,
            },
        },
        {
            path: '/export-manager',
            name: 'ExportManagerView',
            components: {
                default: () => import('./views/ExportManagerView.vue'),
                SideBar,
                ProjectionsConfigNavigation,
            },
            meta: {
                requiresAuth: true,
                requiresInsurSightAccess: true,
                requiresOrganisation: true,
                routeIsPredict: true,
                allowSegmentationDataFormatting: true,
                showTour: true,
            },
            beforeEnter: (_to, _from, next) => {
                updateUI.setLoader(true);
                next();
            },
        },
        {
            path: '/report/:reportId',
            name: 'ReservingClassReport',
            components: {
                default: () => import('./views/ReservingClassReport.vue'),
            },
            beforeEnter: (_to, _from, next) => {
                updateUI.setLoader(true);
                next();
            },
        },
        {
            path: '/reports-table/:reportId',
            name: 'ReportsList',
            components: {
                default: () => import('./views/ReservingClassReport.vue'),
            },
            beforeEnter: (_to, _from, next) => {
                updateUI.setLoader(true);
                next();
            },
        },
        {
            path: '/projections-config',
            name: 'projectionsConfig',
            components: {
                default: () => import('./views/Projections/ProjectionsConfig.vue'),
                SideBar,
                ProjectionsConfigNavigation,
            },
            meta: {
                requiresAuth: true,
                requiresInsurSightAccess: true,
                requiresOrganisation: true,
                routeIsPredict: true,
                requiresErsId: true,
            },
            children: [
                {
                    path: 'patterns-select',
                    name: 'patternsSelect',
                    components: {
                        default: () => import('./views/Projections/PatternsSelect.vue'),
                        SideBar,
                        ProjectionsConfigNavigation,
                    },
                    meta: {
                        requiresAuth: true,
                        requiresInsurSightAccess: true,
                        requiresOrganisation: true,
                        routeIsPredict: true,
                        requiresErsId: true,
                    },
                    props: true,
                },
                {
                    path: 'patterns-trending',
                    name: 'patternsTrending',
                    components: {
                        default: () => import('./views/Projections/PatternsTrending.vue'),
                        SideBar,
                        ProjectionsConfigNavigation,
                    },
                    meta: {
                        requiresAuth: true,
                        requiresInsurSightAccess: true,
                        requiresOrganisation: true,
                        routeIsPredict: true,
                        requiresErsId: true,
                        showTour: true,
                    },
                    props: true,
                },
                {
                    path: 'patterns-exclusions',
                    name: 'patternsExclusions',
                    components: {
                        default: () => import('./views/Projections/PatternsExclusions.vue'),
                        SideBar,
                        ProjectionsConfigNavigation,
                    },
                    meta: {
                        requiresAuth: true,
                        requiresInsurSightAccess: true,
                        requiresOrganisation: true,
                        routeIsPredict: true,
                        requiresErsId: true,
                        showTour: true,
                    },
                    props: true,
                },
                {
                    path: 'initial-expecteds',
                    name: 'initialExpecteds',
                    components: {
                        default: () => import('./views/Projections/InitialExpecteds.vue'),
                        SideBar,
                        ProjectionsConfigNavigation,
                    },
                    meta: {
                        requiresAuth: true,
                        requiresInsurSightAccess: true,
                        requiresOrganisation: true,
                        routeIsPredict: true,
                        requiresErsId: true,
                        showTour: true,
                    },
                    props: true,
                },
                {
                    path: 'method-selection',
                    name: 'methodSelection',
                    components: {
                        default: () => import('./views/Projections/MethodSelection.vue'),
                        SideBar,
                        ProjectionsConfigNavigation,
                    },
                    meta: {
                        requiresAuth: true,
                        requiresInsurSightAccess: true,
                        requiresOrganisation: true,
                        routeIsPredict: true,
                        requiresErsId: true,
                    },
                    props: true,
                },
                {
                    path: 'projections-review',
                    name: 'projectionsReview',
                    components: {
                        default: () => import('./views/Projections/ProjectionsReview.vue'),
                        SideBar,
                        ProjectionsConfigNavigation,
                    },
                    meta: {
                        requiresAuth: true,
                        requiresInsurSightAccess: true,
                        requiresOrganisation: true,
                        routeIsPredict: true,
                        requiresErsId: true,
                    },
                    props: true,
                },
            ],
        },

        {
            path: '/segmentation-upload',
            name: 'segmentationUpload',
            component: () => import('./views/SegmentationUpload.vue'),
            meta: {
                requiresAuth: true,
                requiresInsurSightAccess: true,
                requiresOrganisation: true,
                routeIsPredict: true,
                requiresSavePermission: true,
            },
            props: true,
        },
        {
            path: '/release-notes',
            name: 'ReleaseNotes',
            component: () => import('./views/ReleaseNotes.vue'),
            meta: {
                requiresAuth: true,
                requiresInsurSightAccess: true,
                requiresOrganisation: true,
                routeIsPredict: true,
            },
        },
        {
            path: '/edit-release-notes',
            name: 'EditReleaseNotes',
            component: () => import('./views/EditReleaseNotes.vue'),
            meta: {
                requiresAuth: true,
                requiresInsurSightAccess: true,
                requiresOrganisation: true,
                routeIsPredict: true,
            },
        },
        {
            path: '/terms-conditions',
            name: 'TermsConditionsView',
            component: () => import('./views/TermsConditionsView.vue'),
            meta: {
                requiresAuth: true,
                requiresInsurSightAccess: true,
            },
        },
        {
            path: '/edit-terms-conditions',
            name: 'EditTermsConditionsView',
            component: () => import('./views/EditTermsConditionsView.vue'),
            meta: {
                requiresAuth: true,
                requiresInsurSightAccess: true,
            },
        },
        {
            path: '/view-analysis-results',
            name: 'viewAnalysisResults',
            redirect: '/analytics',
        },
        {
            path: '/analytics',
            name: 'analytics',
            components: {
                default: () => import('./views/Analytics.vue'),
                SideBar,
            },
            meta: {
                requiresAuth: true,
                requiresInsurSightAccess: true,
                requiresOrganisation: true,
                routeIsPredict: true,
                showTour: true,
            },
            props: true,
        },
        {
            path: '/actual-vs-expected',
            name: 'actual-vs-expected',
            components: {
                default: () => import('./views/ActualVSExpected.vue'),
                SideBar,
            },
            meta: {
                requiresAuth: true,
                requiresInsurSightAccess: true,
                requiresOrganisation: true,
                routeIsPredict: true,
            },
            props: true,
        },
        {
            path: '/welcome',
            name: 'welcome',
            component: () => import('./views/Welcome.vue'),
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: '/',
            name: 'root',
            redirect: '/welcome',
        },
        {
            path: '/error',
            name: 'error',
            component: () => import('./views/error.vue'),
            meta: standalone,
        },
    ],
});

async function checkIfLoggedIn() {
    if (loggedIn()) return true;
    if (await getToken()) return true;
    return false;
}

function checkIfOrganisation() {
    if (axios.defaults.headers.get.sessionId && axios.defaults.headers.post.sessionName) {
        return true;
    }
    return false;
}

router.beforeEach(async (to, _from, next) => {
    if (ui.showTour) updateUI.setShowTour(false);
    if (to.meta?.requiresInsurSightAccess && config.permissions.accessInsurSight === false) {
        next('/welcome');
    } else if (to.meta?.requiresOrganisation && checkIfOrganisation() === false) {
        next('/welcome');
    } else if (to.meta?.requiresErsId && projections.ersId === '') {
        next('/welcome');
    } else if (to.meta?.requiresSavePermission && config.permissions.saveChanges === false) {
        next('/error');
    } else {
        next();
    }
});

router.beforeResolve(async (to, from, next) => {
    if (to.name) {
        updateConfig.updateCurrentPage(to.name);
    }

    if (to.matched.some((record) => record.meta.requiresAuth)) {
        await getToken();
        if (!loggedIn()) {
            next('/login');
        } else {
            next();
        }
    } else {
        // await UpdateUserLog(config.dataSetId, config.dataSetName, to.name);
        if (to.name === 'login') {
            await checkIfLoggedIn().then((userLoggedIn) => {
                if (userLoggedIn) next('/welcome');
            });
        }
        next();
    }
});

export default router;
