import ErsIdentifiers from './ErsIdentifiers';

export default class ErsComment {
    dataSetId: string;

    ersId: string;

    ersIdentifiers: ErsIdentifiers;

    constructor(dataSetId: string, ersId: string, ersIdentifiers: ErsIdentifiers) {
        this.dataSetId = dataSetId;
        this.ersId = ersId;
        this.ersIdentifiers = ersIdentifiers;
    }
}
