/* eslint-disable space-before-function-paren */
import Vue from 'vue';
import RequestData from '@/common/RequestData';
import DataSetInfo from '@/common/DataSetInfo';
import ColumnTypes from '@/common/ColumnTypes';
import UploadData from '@/common/UploadData';
import ConfigLabel from '@/common/ConfigLabel';
import ItemMeasure from '@/common/API/ItemMeasure';
import ItemGroupMapping from '@/common/ItemGroupMapping';

export const DataUpload: RequestData = Vue.observable({
    Triangles: [],
    Vectors: [],
    columnTypes: [],
    copyHistory: false,
    data: [],
    dataRows: [],
    dataSetId: '',
    dataSetName: '',
    currency: '',
    editDataSetId: '',
    editMode: false,
    entityInformation: '',
    entityRows: [],
    granularity: '',
    cohortGranularity: '',
    latestStep: 0,
    itemRows: [],
    triangleRows: [],
    vectorRows: [],
    triangleItems: [],
    vectorItems: [],
    month: '',
    triangleType: '',
    uniqueEntities: [],
    year: '',
});

export const updateDataUpload = {
    setDataSetInfo(dataSetInfo: DataSetInfo) {
        DataUpload.dataSetId = dataSetInfo.DataSetId;
        DataUpload.dataSetName = dataSetInfo.DataSetName;
    },
    setColumnTypes(columnTypes: ColumnTypes[]) {
        DataUpload.columnTypes = columnTypes;
    },
    setData(data: UploadData[]) {
        DataUpload.data = data;
    },
    setTriangles(data: string[]) {
        DataUpload.Triangles = data;
    },
    setVectors(data: string[]) {
        DataUpload.Vectors = data;
    },
    setTriangleItems(data: ItemGroupMapping[]) {
        DataUpload.triangleItems = data;
    },
    SetVectorItems(data: ItemGroupMapping[]) {
        DataUpload.vectorItems = data;
    },
    setMonth(month: string) {
        DataUpload.month = month;
    },
    setYear(year: string) {
        DataUpload.year = year;
    },
    setGranularity(granularity: string) {
        DataUpload.granularity = granularity;
    },
    setEntityInformation(entityInformation: string, uniqueEntities: string[], cohortGranularity: string) {
        DataUpload.entityInformation = entityInformation;
        DataUpload.uniqueEntities = uniqueEntities;
        DataUpload.cohortGranularity = cohortGranularity;
    },
    setTriangleType(triangleType: string) {
        DataUpload.triangleType = triangleType;
    },
    setEditDataSetId(editDataSetId: string) {
        DataUpload.editDataSetId = editDataSetId;
    },
    setEditMode(bool: boolean) {
        DataUpload.editMode = bool;
    },
    setCopyHistory(bool: boolean) {
        DataUpload.copyHistory = bool;
    },
    setCurrencies(selectedCurrency: string) {
        DataUpload.currency = selectedCurrency;
    },
    setLatestStep(step: number) {
        DataUpload.latestStep = step;
    },
    setEntityRows(entityRows: ConfigLabel[][]) {
        DataUpload.entityRows = entityRows;
    },
    setDataRows(dataRows: { RowData: string[] }[]) {
        DataUpload.dataRows = dataRows;
    },
    addDataRowsValue(index: number) {
        DataUpload.dataRows[index].RowData.push(null);
    },
    deleteDataRowsValue(rowIndex: number, drilldownIndex: number) {
        Vue.delete(DataUpload.dataRows[rowIndex].RowData, drilldownIndex);
    },
    setItemRows(itemRows: ItemMeasure[]) {
        DataUpload.itemRows = itemRows;
    },
    setItemRowSingle(index: number, itemRow: ItemMeasure) {
        Vue.set(DataUpload.itemRows, index, itemRow);
    },
    setDataRowsDelete(index: number) {
        Vue.delete(DataUpload.dataRows, index);
    },
    setDataRowsSingle(newRow: { RowData: string[] }) {
        DataUpload.dataRows.push(newRow);
    },
    setVectorRows(rows: ItemMeasure[]) {
        DataUpload.vectorRows = rows;
    },
    setTriangleRows(rows: ItemMeasure[]) {
        DataUpload.triangleRows = rows;
    },
    updateTriangleRows(item: ItemMeasure, index: number) {
        Vue.set(DataUpload.triangleRows[index], 'InsurSightItem', item.InsurSightItem);
        if (item.insurSightGroups === null) {
            Vue.set(DataUpload.triangleRows[index], 'InsurSightGroup', item.InsurSightGroup);
        } else {
            Vue.set(DataUpload.triangleRows[index], 'insurSightGroups', item.insurSightGroups);
        }
    },
    updateVectorRows(item: ItemMeasure, index: number) {
        Vue.set(DataUpload.vectorRows[index], 'InsurSightItem', item.InsurSightItem);
        if (item.insurSightGroups === null) {
            Vue.set(DataUpload.vectorRows[index], 'InsurSightGroup', item.InsurSightGroup);
        } else {
            Vue.set(DataUpload.vectorRows[index], 'insurSightGroups', item.insurSightGroups);
        }
    },
    defaultSettings() {
        DataUpload.Triangles = [];
        DataUpload.Vectors = [];
        DataUpload.columnTypes = [];
        DataUpload.copyHistory = false;
        DataUpload.data = [];
        DataUpload.dataRows = [];
        DataUpload.dataSetId = '';
        DataUpload.dataSetName = '';
        DataUpload.editDataSetId = '';
        DataUpload.editMode = false;
        DataUpload.entityInformation = '';
        DataUpload.entityRows = [];
        DataUpload.granularity = '';
        DataUpload.itemRows = [];
        DataUpload.latestStep = 0;
        DataUpload.month = '';
        DataUpload.triangleRows = [];
        DataUpload.vectorRows = [];
        DataUpload.triangleType = '';
        DataUpload.uniqueEntities = [];
        DataUpload.year = '';
    },
};
