
import { Vue, Component, Watch } from 'vue-property-decorator';
import { projections } from '@/observables/Projections';
import ViewAnalyticsSelections from '@/components/layout/panels/projections/ViewAnalyticsSelections.vue';
import { viewAnalytics, updateViewAnalytics } from '@/observables/ViewAnalytics';
import ProjectionTotals from '../panels/projections/ProjectionTotals.vue';
import ReservingClassFilters from '../panels/projections/ReservingClassFilters.vue';

@Component({
    components: {
        ProjectionTotals,
        ReservingClassFilters,
        ViewAnalyticsSelections,
    },
})
export default class ProjectionConfigSideBar extends Vue {
    goToSegmentations () {
        if (projections.segmentationId || projections.segmentationName) {
            // updateProjections.updateSegmentation({ segmentationName: '', segmentationId: '' });
            // updateProjections.updateScenario(new ScenarioIdName());
            // updateProjections.updateReservingClass('');
            this.$router.push('/segmentations');
        }
    }

    goToScenarios () {
        if (projections.segmentations) {
            // updateUI.setLoader(true);
            // updateProjections.updateScenario(new ScenarioIdName());
            // updateProjections.updateReservingClass('');
            this.$router.push('/scenarios');
        }
    }

    goToReservingClass () {
        if (projections.reservingClass) {
            // updateProjections.updateReservingClassTotals({});
            this.$router.push('/reserving-class');
        }
    }

    get hasSegmentation () {
        if (projections.segmentationId) return projections.segmentationId;
        return false;
    }

    get hasScenarios () {
        if (projections.scenarioId) return projections.segmentations;
        return false;
    }

    get hasReservingClass () {
        if (projections.reservingClass) return projections.reservingClass;
        return false;
    }

    get projectionStore () {
        return projections;
    }

    private pagesWithProjections = {
        reservingClass: true,
        patternsSelect: true,
        patternsTrending: true,
        patternsExclusions: true,
        initialExpecteds: true,
        methodSelection: true,
        projectionsReview: true,
    }

    get showProjectionTotals() {
        return this.pagesWithProjections[this.$route.name];
    }

    private pagesWithReservingClassFilters = {
        reservingClass: true,
    }

    get showReservingClassFilters() {
        return this.pagesWithReservingClassFilters[this.$route.name];
    }

    // #region reserving class multiselct
    public reservingClasses: string[] = [];

    get selectedReservingClass(): string {
        return viewAnalytics.selectedReservingClass;
    }

    set selectedReservingClass(reservingClass: string) {
        updateViewAnalytics.updateChangeFromSideBar(true);
        updateViewAnalytics.updateSelectedReservingClass(reservingClass);
        updateViewAnalytics.setReservingClassFromCompare(this.selectedReservingClass);
        // updateViewAnalytics.updateChangeFromSideBar(true);
    }

    @Watch('hasReservingClass', { immediate: true })
    async updateSelectedReservingClass(newVal) {
        if (newVal) {
            this.selectedReservingClass = newVal;
        }
    }

    get viewAnalyticsPage():string {
        return viewAnalytics.page;
    }

    get paginatedResClasses() {
        return viewAnalytics.paginatedReservingClasses;
    }
    // #endregion
}
