
import { Vue, Component, Prop } from 'vue-property-decorator';
import { NotifyOptions } from './NotifyOptions';

@Component({})
export default class LcpNotify extends Vue {
  @Prop() watchNetwork: boolean;

  @Prop() notifyOptions: NotifyOptions;

  created() {
      if (this.watchNetwork) {
          this.watchForNetworkChanges();
      }
  }

  showOfflineMessage(): void {
      this.$notify({
          title: 'No internet connection',
          type: 'offline',
          text: 'You currently have no internet connection. Please reconnect to continue.',
          duration: -1,
      });
  }

  cleanOfflineMessage(): void {
      this.$notify({
          clean: true,
      });
  }

  watchForNetworkChanges() {
      window.addEventListener('offline', this.showOfflineMessage);
      window.addEventListener('online', this.cleanOfflineMessage);
  }

  beforeDestroy() {
      window.removeEventListener('offline', this.showOfflineMessage);
      window.removeEventListener('online', this.cleanOfflineMessage);
  }
}
