
import { Vue, Component } from 'vue-property-decorator';
import SyncLoader from 'vue-spinner/src/SyncLoader.vue';
import fileSave from 'file-saver';
import { config } from '@/observables/Config';
import { updateUI } from '@/observables/UI';
import { base64ToBlob } from '@/functions/objects';
import ErrorModal from '@/common/ErrorModal';
import Http from '@/auth/api/apiHelper';

@Component({
    components: {
        SyncLoader,
    },
})
export default class DownloadModal extends Vue {
    downloading: boolean = false;

    created () {
        this.startDownload();
    }

    async startDownload () {
        try {
            this.downloading = true;
            const downloadData = await Http.post<any>(`${process.env.VUE_APP_API}/api/DataDownload`, { dataSetId: config.dataSetId }, { apiScope: [process.env.VUE_APP_B2C_SCOPE] });
            if (downloadData.data.status === 'success') {
                const mime = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'; // 'application/vnd.ms-excel';
                const blob = base64ToBlob(downloadData.data.data, mime);
                fileSave.saveAs(blob, 'Tags_and_comments_.xlsx');
                this.downloading = false;
                updateUI.setShowPrintModal(false);
            } else {
                this.downloading = false;
                setTimeout(() => {
                    updateUI.setShowPrintModal(false);
                }, 3000);
            }
        } catch (error) {
            updateUI.updateErrorModal((new ErrorModal('', 'welcome', 'Welcome Page')));
        }
    }

    close () {
        updateUI.setShowPrintModal(false);
    }

    get downloadingText () {
        return this.downloading ? 'Your download is being generated - this may take a few minutes' : 'No comments or tags available to download for this data set';
    }
}

