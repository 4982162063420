
import { Vue, Component, Prop } from 'vue-property-decorator';
import dayjs from 'dayjs';

import { v4 as uuidv4 } from 'uuid';
import DOMPurify from 'dompurify';
import { updateUI } from '@/observables/UI';
import EditComments from '@/common/API/EditComment';
import { projections } from '@/observables/Projections';
import ErsIdentifiers from '@/common/comments/ErsIdentifiers';
// import { ersSections } from '@/observables/ErsSections';
import { camelize } from '@/functions/tools';
import ErrorModal from '@/common/ErrorModal';
import Http from '@/auth/api/apiHelper';
import ErsData from '@/common/ErsData';
import { config } from '../../observables/Config';
import RequestRemoveComment from '../../common/RequestRemoveComment';
import CommentInput from '../inputs/CommentInput.vue';
import ErsComment from '../../common/comments/ErsComment';

const cloneDeep = require('clone-deep');

@Component({
    components: {
        CommentInput,
    },
})
export default class Comment extends Vue {
    @Prop(Object) readonly comment!: { [key: string]: any };

    @Prop(String) readonly chartId!: string;

    @Prop({ default: false }) readonly ers: boolean;

    @Prop({ default: null }) readonly filters!: Record<string, string>;

    @Prop() readonly ersData: ErsData;

    @Prop({ default: false }) readonly modalComments: boolean;

    @Prop({ default: null }) readonly modalCommentFilterValues: Record<string, string>;

    @Prop({ default: false }) readonly: boolean;

    public editId = '';

    public editText = '';

    public loading = true;

    public replyInput = '';

    public replyInputActive = false;

    public showCommentsInputs = false;

    public showEditInputs = false;

    public ErsComment: ErsComment;

    replace(str: string, replaceWhat: string, replaceTo: string) {
        const re = new RegExp(replaceWhat, 'g');
        return str.replace(re, replaceTo);
    }

    get displayableComment (): string {
        let string = this.comment.comment;
        string = this.replace(string, '&gt;', '>');
        string = this.replace(string, '&lt;', '<');
        return string;
    }

    public editComment (comment: { commentText: string, commentId: string }) {
        this.editId = comment.commentId.length > 0 ? comment.commentId : this.comment.commentId;
        this.editText = comment.commentText;
    }

    public cancelEdit () {
        this.editId = '';
        this.editText = '';
    }

    public updateComment (comment: string) {
        this.editText = comment;
    }

    public async saveEdit (replyId: string = null) {
        if (!this.modalComments) updateUI.setLoader(true);
        const replyIdInUse = replyId.length > 0 ? replyId : null;
        const newEdit: EditComments = new EditComments(this.chartId, config.dataSetId, this.comment.commentId, dayjs().toJSON(), replyIdInUse, this.editText);

        let request;
        if (this.ers) {
            this.setErsRequest();
            request = cloneDeep(this.ErsComment);
            request.commit = true;
            if (!replyIdInUse) {
                request.editComment = {
                    commentId: this.comment.commentId,
                    date: dayjs().toJSON(),
                    comment: this.editText,
                };
            } else {
                request.editReplyComment = {
                    commentId: this.comment.commentId,
                    replyId: replyIdInUse,
                    comment: this.editText,
                };
            }
        }

        const apiName = this.ers ? `${process.env.VUE_APP_API_ENDPOINT_PREDICT}/WriteErsComment` : `${process.env.VUE_APP_API}/api/EditComments`;
        const scope = this.ers ? [process.env.VUE_APP_API_SCOPE_PREDICT] : [process.env.VUE_APP_B2C_SCOPE];
        const ersRequestOrNewEdit = this.ers ? request : newEdit;
        try {
            const result = await Http.post<any>(apiName, JSON.stringify(ersRequestOrNewEdit), { apiScope: scope });
            if (result.status === 200 && result.data.isValid) {
                this.$emit('get-latest');
            } else if (result.data.isValid === false) {
                this.$notify({
                    type: 'error',
                    text: result.data.statusMessage,
                });
            }
            this.cancelEdit();
        } catch (error) {
            updateUI.updateErrorModal((new ErrorModal('', 'welcome', 'Welcome Page')));
        }
        updateUI.setLoader(false);
    }

    public async addReply () {
        if (this.replyInput.length > 0) {
            updateUI.setLoader(true);
            this.cancelEdit();
            const slicedReplyInput = this.replyInput.slice(0, 2000);

            const newReply = {
                chartId: this.chartId,
                DataSetId: config.dataSetId,
                commentId: `${this.comment.commentId}`,
                data: {
                    replyId: uuidv4(),
                    reply: DOMPurify.sanitize(slicedReplyInput),
                    date: dayjs().toJSON(),
                },
            };

            let request;
            if (this.ers) {
                this.setErsRequest();
                request = cloneDeep(this.ErsComment);
                request.commit = true;
                request.addReply = {
                    commentId: this.comment.commentId,
                    data: { reply: DOMPurify.sanitize(slicedReplyInput) },
                };
            }

            const apiName = this.ers ? `${process.env.VUE_APP_API_ENDPOINT_PREDICT}/WriteErsComment` : `${process.env.VUE_APP_API}/api/UpdateReplies`;
            const scope = this.ers ? [process.env.VUE_APP_API_SCOPE_PREDICT] : [process.env.VUE_APP_B2C_SCOPE];

            const ersRequestOrNewEdit = this.ers ? request : newReply;

            try {
                const response = await Http.post<any>(apiName, JSON.stringify(ersRequestOrNewEdit), { apiScope: scope });
                console.log(response);
                if (response.data.isValid) {
                    this.replyInput = '';
                    this.$emit('get-latest');
                    this.replyInputActive = false;
                } else {
                    this.$notify({
                        type: 'error',
                        text: response.data.statusMessage,
                    });
                }
            } catch (error) {
                updateUI.updateErrorModal((new ErrorModal('', 'welcome', 'Welcome Page')));
            }
            updateUI.setLoader(false);
        }
    }

    public async deleteComment (replyId: string = null) {
        // updateUI.setLoader(true);

        const apiName = this.ers ? `${process.env.VUE_APP_API_ENDPOINT_PREDICT}/RemoveErsComment` : `${process.env.VUE_APP_API}/api/RemoveComment`;
        const scope = this.ers ? [process.env.VUE_APP_API_SCOPE_PREDICT] : [process.env.VUE_APP_B2C_SCOPE];

        let request;

        this.setErsRequest();

        if (this.ers) {
            request = cloneDeep(this.ErsComment);
            request.removeComment = {
                commentId: this.comment.commentId, replyId,
            };
        } else {
            request = new RequestRemoveComment(this.chartId, this.comment.commentId, replyId);
        }

        try {
            const result = await Http.post<any>(apiName, request, { apiScope: scope });
            console.log(result);
            if (result.data.isValid === true) this.$emit('get-latest');
        } catch (error) {
            updateUI.updateErrorModal((new ErrorModal('', 'welcome', 'Welcome Page')));
        }
        // updateUI.setLoader(false);
    }

    public setErsRequest () {
        const ersIdentifiers = new ErsIdentifiers(
            projections.segmentationId,
            projections.scenarioId,
            this.modalComments ? this.ersData.reservingClassTitle : projections.reservingClass,
            this.modalComments ? camelize(this.ersData.ersSection) : this.$route.name,
            this.modalComments ? this.modalCommentFilterValues : this.filters,
        );

        // console.log(projections.ersId);
        const ersId = this.modalComments ? this.ersData.ersId : projections.ersId;

        this.ErsComment = new ErsComment(config.dataSetId, ersId, ersIdentifiers);
    }

    get permissions() {
        return config.permissions;
    }
}
