var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"switcher-holder"},[_c('div',{ref:"tabHolder",staticClass:"tab-holder",class:{ 'format-value-tab-holder': _vm.noTabScroll }},[(_vm.hideTabs)?_c('div',{staticClass:"hide-tabs"}):_vm._e(),_vm._l((_vm.headers),function(tab,key){return _c('div',{key:`${tab.id}-${key}`,staticClass:"tabs",class:{
                active: tab.id === _vm.aTab.id,
                'disabled-click': _vm.loading,
                'format-value-tab-holder': _vm.noTabScroll,
                'addition-tab': tab.additionType,
            },attrs:{"data-v-step":`${tab.id}-${key}`},on:{"click":function($event){return _vm.handleTabClick(
                    !tab.showSettings && _vm.doAnyTabsHaveSettingsOption,
                    key,
                    tab
                )}}},[(tab.additionType)?_c('div',[_c('font-awesome-icon',{staticClass:"icon left",attrs:{"icon":['fas', 'plus']}})],1):_vm._e(),(tab.data)?_c('div',{staticClass:"text",on:{"click":function($event){_vm.handleTabClick(
                        (tab.showSettings &&
                            !_vm.doAnyTabsHaveSettingsOption) ||
                            tab.additionType,
                        key,
                        tab
                    )}}},[_vm._v(" "+_vm._s(tab?.text || '')+" ")]):_c('BeatLoader',{attrs:{"color":"rgb(143 143 143)","size":5,"loading":true}}),(tab.id === _vm.aTab.id)?_c('div',{staticClass:"icons-wrapper"},[(tab.showSettings && tab.data?.settingsInView)?_c('font-awesome-icon',{staticClass:"icon",attrs:{"icon":['fas', 'arrow-left']},on:{"click":function($event){return _vm.settingAction(key)}}}):_vm._e(),(!tab.data?.settingsInView && _vm.aTab.id === 'ReservingClass')?_c('v-popover',{staticClass:"align-center icon",attrs:{"offset":"15","placement":"bottom","container":"body","boundariesElement":"document.body"},on:{"show":function($event){return _vm.openShortcut(key)}}},[_c('div',{staticClass:"shortcut-wrapper",class:{active: _vm.activeShortCut}},[_c('font-awesome-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                          content: `Table shortcuts ${_vm.activeShortCut ? `- ${_vm.activeShortCut}` : ''}`,
                          placement: 'right',
                          offset: '5px',
                      }),expression:"{\n                          content: `Table shortcuts ${activeShortCut ? `- ${activeShortCut}` : ''}`,\n                          placement: 'right',\n                          offset: '5px',\n                      }"}],staticClass:"icon bookmark",attrs:{"icon":['fas', 'bookmark'],"aria-label":"Table shortcuts","title":"Table shortcuts"}}),(_vm.showShortCutUndo)?_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                          content: 'Undo filter/sorting changes',
                          placement: 'right',
                          offset: '5px',
                      }),expression:"{\n                          content: 'Undo filter/sorting changes',\n                          placement: 'right',\n                          offset: '5px',\n                      }"}],staticClass:"icon icon-undo"}):_vm._e()],1),_c('template',{slot:"popover"},[_c('portal-target',{attrs:{"name":"resClassShortcut"}})],1)],2):_vm._e(),(!tab.data?.settingsInView && tab.data?.refreshButton)?_c('font-awesome-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                          content: 'Refresh table',
                          placement: 'right',
                          offset: '5px',
                      }),expression:"{\n                          content: 'Refresh table',\n                          placement: 'right',\n                          offset: '5px',\n                      }"}],staticClass:"icon",attrs:{"icon":['fas', 'sync-alt']},on:{"click":_vm.triggerRefresh}}):_vm._e(),(tab.showSettings && !tab.data?.settingsInView)?_c('font-awesome-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                        content:
                            _vm.permissions.saveChanges === false
                                ? 'Not available on your current license'
                                : 'Manage table metrics',
                        placement: 'right',
                        offset: '5px',
                        boundariesElement: '.tab-holder',
                    }),expression:"{\n                        content:\n                            permissions.saveChanges === false\n                                ? 'Not available on your current license'\n                                : 'Manage table metrics',\n                        placement: 'right',\n                        offset: '5px',\n                        boundariesElement: '.tab-holder',\n                    }"}],staticClass:"icon",class:{
                        'disabled-permission-icon':
                            _vm.permissions.saveChanges === false,
                    },attrs:{"data-v-step":"tab-settings-cog","icon":['fas', 'cog']},on:{"click":function($event){_vm.permissions.saveChanges
                            ? _vm.settingAction(key)
                            : () => {}}}}):_vm._e(),(tab.closable)?_c('font-awesome-icon',{staticClass:"icon",attrs:{"icon":['fas', 'times']},on:{"click":function($event){return _vm.closeTab($event, key)}}}):_vm._e()],1):_vm._e()],1)})],2),_c('div',{staticClass:"wrapper-padding card"},[_c('div',{staticClass:"scroll",class:{ 'format-value-tab-holder': _vm.noTabScroll },style:({ height: `${_vm.height}px` })},[_vm._t("default")],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }