
import {
    Component, Vue, Prop, Watch,
} from 'vue-property-decorator';
import { VPopover } from 'v-tooltip';

interface HeaderValueFormat {
    dataType: string,
    multiply?: number,
    hideCommas?: boolean,
    nDp?: number,
    prefix: string,
    suffix: string,
    valueFormat?: HeaderValueFormat
}

interface Options {
    name: string,
    text: string,
    icon?: string,
}

@Component({ components: { VPopover } })
export default class TableHeaderWithFormatOption extends Vue {
    @Prop() header!: string;

    @Prop() headerValueFormat?: HeaderValueFormat;

    @Prop(Boolean) modalSettings?: boolean;

    @Prop() headerKey?: number;

    public openPopover(): void {
        this.popoverOpen = true;
    }

    public closePopover(): void {
        this.popoverOpen = false;
    }

    public popoverOpen = false

    public multiplier = 1;

    public unitFormatOptions = {
        none: {
            multiply: 1,
            suffix: '',
        },
        percent: {
            multiply: 100,
            suffix: '%',
        },
        thousands: {
            multiply: 0.001,
            suffix: 'k',
        },
        millions: {
            multiply: 0.000001,
            suffix: 'm',
        },
        billions: {
            multiply: 0.000000001,
            suffix: 'b',
        },
    }

    public currencies: Options[] = [
        { name: 'none', text: '' },
        { name: 'pound', text: '£', icon: 'pound-sign' },
        // { name: 'dollar', text: '$', icon: 'dollar-sign' },
        // { name: 'euro', text: '€', icon: 'euro-sign' },
    ]

    public units: Options[] = [
        { name: 'none', text: '' },
        { name: 'percent', text: '%', icon: 'percent' },
        { name: 'thousands', text: 'k' },
        { name: 'millions', text: 'm' },
        { name: 'billions', text: 'b' },
    ]

    @Watch('headerValueFormat', { immediate: true, deep: true })
    setCurrentValueFormat() {
        if (this.modalSettings) {
            this.newFormat = this.headerValueFormat?.valueFormat;
            return;
        }
        this.newFormat = this.headerValueFormat;
    }

    public newFormat: any = {};

    public updateFormat (format: string) {
        const newFormat = { ...this.newFormat, ...this.unitFormatOptions[format] };
        this.newFormat = newFormat;
    }

    // #region dp handler
    public numberOfDPlacesOptions = [0, 1, 2, 3, 4, 5]

    public updateNoDecimalPlace (number: number) {
        Vue.set(this.newFormat, 'nDp', number);
    }

    get currentNumberOfDecimalPlaces () {
        return this.newFormat.nDp;
    }
    // #endregion

    // #region currency handler
    public updateCurrency (currency: string) {
        Vue.set(this.newFormat, 'prefix', currency);
    }
    // #endregion

    public updateCommaSeparated () {
        this.newFormat.hideCommas = !this.newFormat.hideCommas;
    }

    get isCommaSeparated () {
        if (this.newFormat.hideCommas === null) return null;
        return this.newFormat.hideCommas;
    }

    public submitFomatting () {
        this.$emit('update-format', { format: this.newFormat, key: this.headerKey });
    }
}
