
import { Vue, Component, Prop } from 'vue-property-decorator';
import { ui, updateUI } from '@/observables/UI';
import { trends, updateActiveTrends } from '@/observables/Trends';

// import NumberFilter from '@/common/NumberFilter';
import { updateConfig } from '@/observables/Config';
import UILabel from '@/common/UILabel';
import NumberInput from '@/components/inputs/NumberInput.vue';
import TrendFilter from '@/common/TrendFilter';
import NumberFilter from '@/common/NumberFilter';

@Component({
    components: {
        NumberInput,
    },
})
export default class TrendOptions extends Vue {
    @Prop() filter!: TrendFilter;

    @Prop() readonly parent!: string;

    selectedFilter: string = '';

    get activeHeader () {
        if (Object.prototype.hasOwnProperty.call(trends.active[this.parent], this.filter.name)) {
            if (Object.prototype.hasOwnProperty.call(trends.active[this.parent][this.filter.name], 'values')) {
                return trends.active[this.parent][this.filter.name].values.length > 0;
            }
        }
        return false;
    }

    get numberInputs () {
        let blankFilter = new NumberFilter();
        if (this.activeHeader) {
            if (trends.active[this.parent][this.filter.name].dataType === 'number') {
                const vals = trends.active[this.parent][this.filter.name].values[0];
                blankFilter = new NumberFilter(vals.from, vals.to, vals.from !== null, vals.to !== null);
            }
        }
        return blankFilter;
    }

    activeFilterHeader (filter: string) {
        return filter === this.selectedFilter;
    }

    async toggle (filter: string) {
        if (!Object.prototype.hasOwnProperty.call(trends.active[this.parent], filter)) {
            updateActiveTrends.setupTrend(this.parent, new TrendFilter(this.filter.name, this.filter.text, this.filter.dataType, []));
        }
        this.selectedFilter = filter === this.selectedFilter ? '' : filter;
        updateUI.updateShortcutChanges(true);
    }

    filterValues (filter: string) {
        const index: number = ui.labels.findIndex((l: UILabel) => l.name === filter);
        return index > -1 ? ui.labels[index].valueFormat : [];
    }

    activeFilter (value: string) {
        let index = -1;
        if (Object.prototype.hasOwnProperty.call(trends.active[this.parent], this.filter.name)) {
            index = trends.active[this.parent][this.filter.name].values.findIndex((i: string) => i === value);
        }
        return index;
    }

    toggleTrendFilter (filter: TrendFilter, value: string) {
        updateConfig.updateStart(0);
        if (this.activeFilter(value) > -1) {
            updateActiveTrends.removeTrendValue(
                this.parent,
                filter.name,
                value,
            );
        } else {
            updateActiveTrends.updateTrend(
                this.parent,
                filter.name,
                value,
            );
        }
        updateUI.updateShortcutChanges(true);
    }

    tooltip (bool: boolean) {
        return bool ? 'Remove Filter' : 'Add Filter';
    }
}
