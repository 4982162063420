
import { Vue, Component } from 'vue-property-decorator';
import TrendOptions from '@/components/layout/panels/TrendOptions.vue';
import { trends, updateActiveTrends } from '@/observables/Trends';
import TrendFilter from '@/common/TrendFilter';
import UILabel from '@/common/UILabel';
import { ui, updateUI } from '@/observables/UI';

@Component({
    components: {
        TrendOptions,
    },
})
export default class ParentTrend extends Vue {
    filter: TrendFilter = this.parentTrend[0];

    selectedFilter: string = '';

    get trendFilters (): TrendFilter[] {
        return trends.trend.filter((t: TrendFilter) => t.name !== 'trend');
    }

    get parentTrend (): TrendFilter[] {
        return trends.trend.filter((t: TrendFilter) => t.name === 'trend');
    }

    get activeHeader () {
        return Object.keys(trends.active).length > 0;
    }

    activeFilterHeader (filter: string) {
        return filter === this.selectedFilter;
    }

    async toggle (filter: string) {
        this.selectedFilter = filter === this.selectedFilter ? '' : filter;
        updateUI.updateShortcutChanges(true);
    }

    filterValues (filter: string) {
        const index: number = ui.labels.findIndex((l: UILabel) => l.name === filter);
        return index > -1 ? ui.labels[index].valueFormat : [];
    }

    activeFilter (value: string) {
        return trends.active[value];
    }

    tooltip (bool: boolean) {
        return bool ? 'Remove Trend' : 'Add Trend';
    }

    toggleTrendFilter (trend: string) {
        if (Object.prototype.hasOwnProperty.call(trends.active, trend)) {
            updateActiveTrends.removeTrend(trend);
        } else {
            updateActiveTrends.addTrend(trend);
        }
        updateUI.updateShortcutChanges(true);
    }

    activeTrendCheck (trend: string) {
        return Object.prototype.hasOwnProperty.call(trends.active, trend);
    }
}
