
import { Vue, Component, Watch } from 'vue-property-decorator';
// import { projections, updateProjections } from '@/observables/Projections';
import { VPopover } from 'v-tooltip';
import { reservingClassFiltersAndSorting, updateReservingClassFiltersAndSorting, formattedFilters } from '@/observables/ReservingClassTableFiltersAndSorting';
import FilterAndSortingPopoverContent from '@/components/layout/Popovers/FilterAndSortingPopoverContent.vue';
import SortFilterCardWithPopover from '@/components/layout/Popovers/SortFilterCardWithPopover.vue';
import ReservingClassFiltersOrSortCard from './ReservingClassFiltersOrSortCard.vue';

interface PopoverStatus {
    popover: string;
    autoHide: boolean;
}

@Component({
    components: {
        ReservingClassFiltersOrSortCard, SortFilterCardWithPopover, FilterAndSortingPopoverContent, VPopover,
    },
})
export default class ReservingClassFilters extends Vue {
    get routeName() {
        return this.$route.name;
    }

    get reservingClassFiltersAndSorting() {
        return reservingClassFiltersAndSorting;
    }

    get formattedFilters() {
        return formattedFilters;
    }

    get currentFilters () {
        return reservingClassFiltersAndSorting.filter;
    }

    get currentSorting () {
        return reservingClassFiltersAndSorting.sort;
    }

    public popoverOpen: PopoverStatus = { popover: '', autoHide: false }

    public openPopover(key): void {
        this.popoverOpen.popover = key;
    }

    public closePopover(popover:string): void {
        this[popover].popover = '';
    }

    public updatePopoverOpen(val) {
        this.popoverOpen.popover = val;
    }

    public sortPopoverOpen: PopoverStatus = { popover: '', autoHide: false }

    public openSortPopover(key): void {
        this.sortPopoverOpen.popover = key;
    }

    public updateSortPopoverOpen(val) {
        this.sortPopoverOpen.popover = val;
    }

    public filterIsActive: boolean = false;

    public updateFilterIsActive(val):void {
        this.filterIsActive = val;
    }

    public updateSortAutoHide(val): void {
        this.sortPopoverOpen.autoHide = val;
    }

    public updateFilterAutoHide(val): void {
        this.popoverOpen.autoHide = val;
    }

    get giveSortFilterData() {
        const sortWithFilterData = [];
        this.currentSorting.forEach((elem) => {
            const sortData: any = { ...elem };
            const index = this.currentFilters.findIndex((filter) => filter.text === elem.text);
            if (index > -1) {
                sortData.values = this.currentFilters[index].values;
            } else sortData.values = [];
            sortWithFilterData.push(sortData);
        });
        return sortWithFilterData;
    }

    public updateSorting(sortingQuery) {
        if (sortingQuery.action === 'add')updateReservingClassFiltersAndSorting.addSort(sortingQuery.sortQuery);
        if (sortingQuery.action === 'remove') {
            updateReservingClassFiltersAndSorting.removeSort(sortingQuery.sortQuery);
            this.closePopover('sortPopoverOpen');
        }
    }

    public updateFilters(filterQuery) {
        if (filterQuery.action === 'add')updateReservingClassFiltersAndSorting.addFilter(filterQuery.filterQuery);
        if (filterQuery.action === 'remove') {
            updateReservingClassFiltersAndSorting.removeFilter(filterQuery.filterQuery);
            this.closePopover('popoverOpen');
        }
    }

    public handleViewAnalysisBtnClick(): void {
        if (this.routeName === 'reservingClass') {
            this.$router.push('/view-analysis-results');
        } else this.$router.push('/reserving-class');
    }

    @Watch('giveSortFilterData')
    resetSelectedSortPopover() {
        if (this.giveSortFilterData.length === 0) this.sortPopoverOpen.popover = '';
    }

    @Watch('currentFilters')
    resetSelectedFilterPopover() {
        if (this.currentFilters.length === 0) this.popoverOpen.popover = '';
    }
}
