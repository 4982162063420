
import {
    Vue, Component, Prop,
} from 'vue-property-decorator';
import Multiselect from 'vue-multiselect';
import { ScaleLoader } from '@saeris/vue-spinners';
import dayjs from 'dayjs';
import { v4 as uuidv4 } from 'uuid';
import DOMPurify from 'dompurify';
import { RuntimeTemplateCompiler } from 'vue-runtime-template-compiler';
import { projections } from '@/observables/Projections';
import ErsIdentifiers from '@/common/comments/ErsIdentifiers';
import ErsData from '@/common/ErsData';
import ErrorModal from '@/common/ErrorModal';
import Http from '@/auth/api/apiHelper';
import { config } from '@/observables/Config';
import { camelize } from '@/functions/tools';
import singleComment from './Comment.vue';
import { updateUI } from '../../observables/UI';
import ErsComment from '../../common/comments/ErsComment';

const cloneDeep = require('clone-deep');

@Component({
    components: {
        ScaleLoader,
        Multiselect,
        singleComment,
        RuntimeTemplateCompiler,
    },
})
export default class Comments extends Vue {
    @Prop({ default: '' }) readonly chartId: string;

    @Prop({ default: false }) readonly modalComments: boolean;

    @Prop() readonly ersData: ErsData;

    @Prop({ default: '' }) readonly dataSetId: string;

    @Prop({ default: false }) readonly ers: boolean;

    @Prop({ default: true }) readonly readOnly!: boolean;

    @Prop({ default: null }) readonly filters!: Record<string, string>;

    public ErsComment: ErsComment;

    public response: Array<any> = [];

    public loading = true;

    public textareaActive = false;

    public commentInput = '';

    public previousExercisesComments = null;

    public previousExercisesNames = null;

    get commentsHeader () {
        if (this.ers) {
            return {
                initialExpecteds:
                    `Rationale for <span class="page-header-title">IE selections</span> for IE name:
                    <span>${this.modalComments ? this.ersData?.ersIeName : this.filters?.initialExpectedName}</span>
                    using exposure:
                    <span>${this.modalComments ? this.ersData?.ersExposureItem : this.filters?.exposureMethod}</span>
                    to project:
                    <span>${this.modalComments ? this.ersData.ersProjection : this.filters.itemModel}</span>`,
                patternsSelect:
                    `Rationale for <span class="page-header-title">Selected patterns</span> for
                    <span>${this.modalComments ? this.ersData?.ersModelItem : this.filters?.itemModel}</span>`,
                patternsExclusions:
                    `Rationale for <span class="page-header-title">Pattern exclusions</span> for
                    <span>${this.modalComments ? this.ersData?.ersModelItem : this.filters?.patternName}</span>
                    <span>${this.modalComments ? this.ersData?.ersModelItem : this.filters?.itemModel}</span> pattern`,
                patternsTrending:
                    `Rationale for <span class="page-header-title">Pattern trending selections</span> for
                    <span>${this.modalComments ? this.ersData?.ersPatternName : this.filters?.patternName}</span>
                    <span>${this.modalComments ? this.ersData?.ersModelItem : this.filters?.itemModel}</span> pattern`,
                methodSelection:
                    `Rationale for <span class="page-header-title">Method selections</span> for
                    <span>${this.modalComments ? this.ersData?.ersProjection : this.filters?.projection}</span> projection`,
            };
        }
        return {};
    }

    get selectedIe() {
        return projections.projectionSidebarOptions.selectedIE?.name;
    }

    get itemGroupProjected() {
        return projections.projectionSidebarOptions.selectedProjection?.projection;
    }

    get routeName() {
        return this.$route.name;
    }

    get currentCommentHeader() {
        if (this.modalComments) {
            if (this.ersData.ersChart) {
                const routeName = camelize(this.ersData.ersSection);
                // TODO make check for if ersChart
                return `<h3>Comments: ${this.commentsHeader[routeName]}</h3>`;
            }
            return '<h3>Comments: </h3>';
        }
        return this.commentsHeader?.[this.routeName] ? `<h3>Comments: ${this.commentsHeader[this.routeName]}</h3>` : '<h3>Comments</h3>';
    }

    created () {
        this.getComments();
        console.log('here');
    }

    async getComments () {
        updateUI.setLoader(false);
        // this.loading = true;
        this.response = [];

        this.setErsRequest();

        const request = this.ers ? cloneDeep(this.ErsComment) : { id: this.chartId };
        const apiName = this.ers ? `${process.env.VUE_APP_API_ENDPOINT_PREDICT}/ReadErsComment` : `${process.env.VUE_APP_API}/api/QueryComments`;
        const scope = this.ers ? [process.env.VUE_APP_API_SCOPE_PREDICT] : [process.env.VUE_APP_B2C_SCOPE];

        try {
            const commentHistory = await Http.post<any>(apiName, request, { apiScope: scope });
            if (commentHistory?.status === 200) {
                this.response = commentHistory.data.data.reverse();
                if (commentHistory.data.previousExercises) {
                    this.previousExercisesComments = commentHistory.data.previousExercises;
                    this.previousExercisesNames = commentHistory.data.previousExercisesNames;
                }
            }
        } catch (error) {
            updateUI.updateErrorModal((new ErrorModal('', 'welcome', 'Welcome Page')));
        }

        this.loading = false;
    }

    async addComment () {
        if (this.commentInput.length > 0) {
            this.loading = true;
            this.textareaActive = false;
            const slicedCommentInput = this.commentInput.slice(0, 2000);
            this.setErsRequest();

            let request;
            if (this.ers) {
                request = cloneDeep(this.ErsComment);
                request.addComment = { comment: DOMPurify.sanitize(slicedCommentInput) };
                request.commit = true;
                if (this.modalComments)console.log('modal comments');
            } else {
                request = {
                    id: `${this.chartId}`,
                    DataSetId: this.dataSetId,
                    data: {
                        commentId: uuidv4(),
                        comment: DOMPurify.sanitize(slicedCommentInput),
                        date: dayjs().toJSON(),
                        replies: [],
                    },
                };
            }

            const apiName = this.ers ? `${process.env.VUE_APP_API_ENDPOINT_PREDICT}/WriteErsComment` : `${process.env.VUE_APP_API}/api/UpdateComments`;
            const scope = this.ers ? [process.env.VUE_APP_API_SCOPE_PREDICT] : [process.env.VUE_APP_B2C_SCOPE];

            try {
                const response = await Http.post<any>(apiName, JSON.stringify(request), { apiScope: scope });
                if (response.data.isValid) {
                    this.commentInput = '';
                    this.getComments();
                } else {
                    this.$notify({
                        type: 'error',
                        text: response.data.statusMessage,
                    });
                }
            } catch (error) {
                updateUI.updateErrorModal((new ErrorModal('', 'welcome', 'Welcome Page')));
            }
        }
    }

    get modalCommentFilterValues() {
        if (this.modalComments) {
            switch (this.ersData.ersSection) {
            case 'Patterns Select':
                return { itemModel: this.ersData.ersModelItem };
            case 'Patterns Trending' || 'Patterns Exclusions':
                return {
                    itemModel: this.ersData.ersModelItem,
                    patternName: this.ersData.ersPatternName,
                };
            case 'Initial Expecteds':
                return {
                    itemModel: this.ersData.ersProjection,
                    initialExpectedName: this.ersData.ersIeName,
                    exposureMethod: this.ersData.ersExposureItem,
                };
            case 'Method Selection':
                return {
                    projection: this.ersData.ersProjection,
                };
            default:
            }
        } return {};
    }

    public setErsRequest () {
        const ersIdentifiers = new ErsIdentifiers(
            projections.segmentationId,
            projections.scenarioId,
            this.modalComments ? this.ersData.reservingClassTitle : projections.reservingClass,
            this.modalComments ? camelize(this.ersData.ersSection) : this.$route.name,
            this.modalComments ? this.modalCommentFilterValues : this.filters,
        );

        const ersId = this.modalComments ? this.ersData.ersId : projections.ersId;

        this.ErsComment = new ErsComment(this.dataSetId, ersId, ersIdentifiers);
    }

    public watchForSubmit (e: {[key: string]: any}) {
        if (e.keyCode === 13 && !e.shiftKey) {
            e.preventDefault();
            this.addComment();
        }
    }

    focusText(isFocused: boolean) {
        if (isFocused === true || (isFocused === false && this.commentInput.trim() === '')) {
            this.textareaActive = isFocused;
        }
    }

    get permissions() {
        return config.permissions;
    }

    // #region previous exercises
    public openedRows = {};

    toggleRow(previousExerciseId: number) {
        const rowsCopy = { ...this.openedRows };
        rowsCopy[previousExerciseId] = !rowsCopy[previousExerciseId];
        this.openedRows = rowsCopy;
    }

    // #endregion
}
