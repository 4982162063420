/* eslint-disable no-param-reassign */
import vue from 'vue';
import Message from './classes/message';
import Guid from './util/guid';

interface MessageState {
    messages: Array<Message>;
}

export const state: MessageState = vue.observable({
    messages: [],
});

export const apiMessages = (): Array<Message> => state.messages;

export const addNewMessage = (message) => {
    state.messages.push(message);
};

export const dismissMessageById = (messageId) => {
    for (let i = 0; i < state.messages.length; i += 1) {
        if (state.messages[i].id === messageId) {
            state.messages.splice(i, 1);
            return;
        }
    }
};

export const addError = (error: Message) => {
    error.id = Guid.newGuid().toString();
    error.isError = true;
    if (error.data?.errors?.length) {
        error.data.errors.forEach((err) => {
            addNewMessage({ ...error, data: err.message });
        });
        return;
    }
    if (error.data?.Errors?.length) {
        error.data.Errors.forEach((err) => {
            addNewMessage({ ...error, data: err.Message });
        });
        return;
    }
    addNewMessage(error);
};

export const addMessage = (message: Message) => {
    message.id = Guid.newGuid().toString();
    addNewMessage(message);
    if (!message.popup) {
        window.setTimeout(() => {
            dismissMessageById(message.id);
        }, 7000);
    }
};

export const dismissMessage = (messageId) => {
    dismissMessageById(messageId);
};
