var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('transition',[_c('div',{staticClass:"error-modal-wrapper"},[_c('div',{staticClass:"bg"}),(_vm.errorModal.canClose)?_c('font-awesome-icon',{staticClass:"modal-close-icon",attrs:{"icon":['fad', 'times-square']},on:{"click":_vm.closeModal}}):_vm._e(),_c('div',{staticClass:"error-modal"},[_c('font-awesome-icon',{staticClass:"error-icon",class:{
                    'warning-icon': _vm.errorModal.warning,
                    'success-icon': _vm.errorModal.success,
                },attrs:{"icon":[
                    'fad',
                    _vm.errorModal.warning
                        ? 'bell-on'
                        : _vm.errorModal.success
                        ? 'check-square'
                        : 'exclamation-square',
                ]}}),_c('div',{staticClass:"error-title"},[_vm._v(" "+_vm._s(_vm.errorModal.warning ? "Warning" : _vm.errorModal.success ? "Success" : "There's been a glitch")+" ")]),(!_vm.errorModal.errorMsg)?_c('div',{staticClass:"error-msg"},[_vm._v(" We're not quite sure what went wrong, but this has been logged and we will investigate why this occurred. Click below to continue from the "+_vm._s(_vm.errorModal.backToText)+" page. ")]):_c('div',{staticClass:"error-msg"},[_vm._v(" "+_vm._s(_vm.errorModal.errorMsg)+" ")]),(!_vm.errorModal.canClose && _vm.errorModal.backToText)?_c('div',{staticClass:"back-btn",on:{"click":_vm.navigateBack}},[_c('font-awesome-icon',{staticClass:"back-icon",attrs:{"icon":['fad', 'arrow-alt-circle-left'],"transform":"down-1"}}),_vm._v("Back to "+_vm._s(_vm.errorModal.backToText)+" ")],1):_vm._e(),(_vm.errorModal.confirmText)?_c('div',{staticClass:"footer"},[_c('button',{staticClass:"btn active",on:{"click":_vm.closeModal}},[_vm._v(_vm._s(_vm.errorModal.cancelText))]),_c('button',{staticClass:"btn",on:{"click":_vm.confirmAction}},[_vm._v(_vm._s(_vm.errorModal.confirmText))])]):_vm._e()],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }