import Vue from 'vue';

interface Store {
    page: string;
    paginatedReservingClasses: { [key: string]: string[] };
    pageNumber: number;
    selectedReservingClass: string;
    changeFromSideBar: boolean;
}

export const viewAnalytics: Store = Vue.observable({
    page: 'identify',
    selectedReservingClass: '',
    paginatedReservingClasses: {},
    pageNumber: 1,
    changeFromSideBar: false,
});

export const updateViewAnalytics = {
    updatePage (page: string): void {
        Vue.set(viewAnalytics, 'page', page);
    },
    updateChangeFromSideBar (bool: boolean): void {
        Vue.set(viewAnalytics, 'changeFromSideBar', bool);
    },
    updateSelectedReservingClass (reservingClass: string): void {
        Vue.set(viewAnalytics, 'selectedReservingClass', reservingClass);
    },
    updatePageNumber (pageNumber: number): void {
        Vue.set(viewAnalytics, 'pageNumber', pageNumber);
    },
    setPaginatedReservingClasses (paginatedReservingClasses: { [key: string]: string[] }): void {
        Vue.set(viewAnalytics, 'paginatedReservingClasses', paginatedReservingClasses);
    },
    setReservingClassFromCompare (reservingClass: string): void {
        const index = Object.values(viewAnalytics.paginatedReservingClasses).findIndex((elem) => elem.includes(reservingClass));
        const pageNumber = index > -1 ? index + 1 : null;
        if (pageNumber) {
            Vue.set(viewAnalytics, 'pageNumber', pageNumber);
            Vue.set(viewAnalytics, 'selectedReservingClass', reservingClass);
        }
    },
    resetViewAnalytics (): void {
        Vue.set(viewAnalytics, 'page', 'identify');
        Vue.set(viewAnalytics, 'paginatedReservingClasses', {});
        Vue.set(viewAnalytics, 'pageNumber', 1);
        Vue.set(viewAnalytics, 'selectedReservingClass', '');
        Vue.set(viewAnalytics, 'changeFromSideBar', false);
    },
};
