
import { Vue, Component, Prop } from 'vue-property-decorator';
import Multiselect from 'vue-multiselect';
import { ui } from '@/observables/UI';
import ExposureLookup from '@/common/ExposureLookup';
import { scrollToSelected } from '@/functions/tools';

@Component({
    components: {
        Multiselect,

    },
})
export default class ViewAnalyticsSelections extends Vue {
    @Prop({ default: () => [] }) exposureLookupList: ExposureLookup[];

    @Prop() selectedExposureLookup: ExposureLookup;

    public updateSelectedExposureLookup($event:ExposureLookup) {
        this.$emit('update-selected-exposure-lookup', $event);
    }

    public scrollToSelected = scrollToSelected;

    get uiStore() {
        return ui;
    }
}
