
import { Vue, Component, Watch } from 'vue-property-decorator';
import PrettyRadio from 'pretty-checkbox-vue/radio';
import { config, updateConfig } from '@/observables/Config';
import ConfigSort from '@/common/ConfigSort';
import { updateUI } from '@/observables/UI';

@Component({
    components: {
        PrettyRadio,
    },
})
export default class SortingPanel extends Vue {
  check: number = -1;

  created() {
      this.buildSort();
  }

  buildSort() {
      const index: number = this.sortingItems.findIndex(
          (i: ConfigSort) => i.checked,
      );
      if (index > -1) this.check = index;
  }

  updateSort(index: number) {
      if (this.check > -1) updateConfig.updateSortBool(this.check, false);
      updateConfig.updateSortBool(index, true);
      updateConfig.updateSortOption(
          new ConfigSort(
              true,
              this.sortingItems[index].item,
              this.sortingItems[index].name,
              this.sortingItems[index].order,
              this.sortingItems[index].trend,
          ),
      );
      this.check = index;
      updateUI.updateShortcutChanges(true);
  }

  changeOrder(item: ConfigSort, name: string) {
      const index: number = config.sort.findIndex(
          (s: ConfigSort) => s.name === name,
      );
      if (config.sortOption.name === name && index > -1) {
          if (item.order === 'asc') {
              updateConfig.updateSortOptionOrder('desc', index);
          } else {
              updateConfig.updateSortOptionOrder('asc', index);
          }
      }
      updateUI.updateShortcutChanges(true);
  }

  get sortingItems(): ConfigSort[] {
      return config.sort;
  }

  @Watch('sortingItems', { deep: true })
  rebuildLocalList() {
      this.buildSort();
  }
}
