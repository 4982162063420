import Vue from 'vue';
import Sort from '@/common/Sort';
import ConfigSort from '@/common/ConfigSort';
import MainConfig from '@/common/MainConfig';
import ActiveFilter from '@/common/ActiveFilter';
import TagCategory from '@/common/TagCategory';
import DrilldownHistory from '@/common/DrilldownHistory';
import Info from '@/common/Chart/Info';
import Data from '@/common/Chart/Data';
import Tag from '@/common/Tag';

export interface Organisation {
    name: string,
    id: string,
    dbName: string,
}

interface IPermissions{
    accessInsurSight: boolean,
    accessPredict: boolean,
    saveChanges: boolean,
    releaseManager: boolean,
    showTermsAndConditions: boolean,
}

interface Store {
    base64: string,
    dataSetId: string,
    dataSetName: string;
    drilldownLevel: number,
    drilldownConfig: MainConfig,
    drilldownFilters: ActiveFilter[],
    drilldownOptions: {[key: string]: DrilldownHistory},
    drilldownList: string[],
    chartCount: number,
    currentPage: string,
    labels: [],
    previousPage: string,
    sort: ConfigSort[],
    sortOption: ConfigSort,
    start: number,
    tags: {[key: string]: TagCategory},
    currentOrganisation: Organisation | null,
    permissions: IPermissions,
}

export const config: Store = Vue.observable({
    base64: '',
    chartCount: 0,
    currentPage: '',
    dataSetId: '',
    dataSetName: '',
    drilldownConfig: new MainConfig('', new Info('', '', ''), new Data('', '', 0, '', '', ''), {}, {}),
    drilldownFilters: [],
    drilldownLevel: 0,
    drilldownList: [],
    drilldownOptions: {},
    labels: [],
    previousPage: '',
    sort: [],
    sortOption: {},
    start: 0,
    tags: {},
    currentOrganisation: null,
    permissions: {
        accessInsurSight: false,
        accessPredict: false,
        saveChanges: false,
        releaseManager: false,
        showTermsAndConditions: false,
    },

});

export const updateConfig = {
    updateTags (data: any) {
        Vue.set(config, 'tags', data);
    },
    updateLabels (labels: any) {
        config.labels = labels;
    },
    updateLabelShow (index: number, value: boolean) {
        Vue.set(config.labels[index], 'checked', value);
    },
    updateStart (start: number) {
        config.start = start;
    },
    updateChartCount (count: number) {
        config.chartCount = count;
    },
    updateSortOrder (sort: Sort) {
        Vue.set(config.sort[sort.key], 'order', sort.order);
    },
    updateSortProperties (index: number, sort: ConfigSort) {
        Vue.set(config.sort, index, sort);
    },
    updateSortBool (index: number, bool: boolean) {
        Vue.set(config.sort[index], 'checked', bool);
    },
    updateSort (sort: ConfigSort[]) {
        config.sort = sort;
    },
    updateCurrentPage (page: string) {
        config.previousPage = config.currentPage;
        config.currentPage = page;
    },
    updateDataSetId (dataSetId: string) {
        config.dataSetId = dataSetId;
    },
    updateDataSetName (dataSetName: string) {
        config.dataSetName = dataSetName;
    },
    updateDrilldownLevel (level: number) {
        config.drilldownLevel = level;
    },
    updateDrilldownConfig (drilldownChart: MainConfig) {
        config.drilldownConfig = drilldownChart;
    },
    updateDrilldownFilters (filter: ActiveFilter) {
        config.drilldownFilters.push(filter);
    },
    updateDrilldownOptions (drilldown: DrilldownHistory) {
        Vue.set(config.drilldownOptions, drilldown.level, drilldown);
    },
    removeDrilldownOption (key: string) {
        Vue.delete(config.drilldownOptions, key);
    },
    removeCategoryTag (categoryId: string) {
        Vue.delete(config.tags, categoryId);
    },
    addCategoryTag (tagCategory: TagCategory, tagId:string, tag: Tag) {
        Vue.set(tagCategory.tags, tagId, tag);
    },
    removeTag (tagCategory: TagCategory, tagId: string) {
        Vue.delete(tagCategory.tags, tagId);
    },
    addTag (tagCategory: TagCategory, categoryId: string) {
        Vue.set(config.tags, categoryId, tagCategory);
    },
    drillBack () {
        Vue.delete(config.drilldownOptions, config.drilldownLevel);
        config.drilldownLevel -= 1;
        config.drilldownConfig = config.drilldownOptions[config.drilldownLevel].config;
    },
    resetDrilldown () {
        config.drilldownConfig = new MainConfig('', new Info('', '', ''), new Data('', '', 0, '', '', ''), {}, {});
        config.drilldownLevel = 0;
        config.drilldownFilters = [];
        config.drilldownOptions = {};
    },
    resetSort () {
        config.sort = [];
    },
    updateSortOption (sort: ConfigSort) {
        config.sortOption = sort;
    },
    updateSortOptionOrder (order: string, key: number) {
        Vue.set(config.sort[key], 'order', order);
        Vue.set(config.sortOption, 'order', order);
    },
    updateDrilldownList (drilldownList: string[]) {
        config.drilldownList = drilldownList;
    },
    updateBase64 (doc: string) {
        config.base64 = doc;
    },
    updateCurrentOrganisation(org: Organisation) {
        config.currentOrganisation = org;
    },
    updatePermissions(perm: IPermissions) {
        config.permissions = perm;
    },
    updateFromRedirect(newConfig) {
        config.dataSetId = newConfig.dataSetId;
        config.dataSetName = newConfig.dataSetName;
    },
    resetDefaults () {
        config.base64 = '';
        config.chartCount = 0;
        config.dataSetId = '';
        config.dataSetName = '';
        config.drilldownConfig = new MainConfig('', new Info('', '', ''), new Data('', '', 0, '', '', ''), {}, {});
        config.drilldownFilters = [];
        config.drilldownLevel = 0;
        config.drilldownList = [];
        config.drilldownOptions = {};
        config.labels = [];
        config.previousPage = '';
        config.sort = [];
        config.sortOption = {};
        config.start = 0;
        config.tags = {};
        config.currentOrganisation = null;
    },

    updatetags(tags: any) {
        config.tags = tags;
    },
};
