export default class ConfigSort {
    checked?: boolean;

    item?: string;

    name?: string;

    order?: string;

    trend?: boolean;

    constructor (checked: boolean, item: string, name: string, order: string, trend: boolean) {
        this.checked = checked;
        this.item = item;
        this.name = name;
        this.order = order;
        this.trend = trend;
    }
}
