export default class Grid {
    item!: string;

    layout!: number[];

    checked!: boolean;

    constructor (item: string, layout: number[], checked:boolean) {
        this.item = item;
        this.layout = layout;
        this.checked = checked;
    }
}
