export default class NumberValue {
    from: (number | null);

    to: (number | null);

    constructor (from: (number | null), to: (number | null)) {
        this.from = from;
        this.to = to;
    }
}
