
import { Vue, Component, Prop } from 'vue-property-decorator';
import { filters, updateActiveFilters } from '@/observables/Filters';
import { ui, updateUI } from '@/observables/UI';
import { updateConfig } from '@/observables/Config';
import ActiveFilter from '@/common/ActiveFilter';
import NumberFilter from '@/common/NumberFilter';
import UILabel from '@/common/UILabel';
import NumberInput from '@/components/inputs/NumberInput.vue';
import { trends } from '@/observables/Trends';
import TrendFilter from '@/common/TrendFilter';

@Component({
    components: {
        NumberInput,
    },
})
export default class FilterOptions extends Vue {
    @Prop() readonly filter!: TrendFilter;

    selectedFilter: string = '';

    get numberInputs () {
        let blankFilter = new NumberFilter();
        if (this.activeHeader(this.filter.name)) {
            if (filters.active[this.filter.name].dataType === 'number') {
                const vals: any = filters.active[this.filter.name].values[0];
                blankFilter = new NumberFilter(vals.from, vals.to, vals.from !== null, vals.to !== null);
            }
        }
        return blankFilter;
    }

    activeHeader (filter: string) {
        return Object.prototype.hasOwnProperty.call(filters.active, filter);
    }

    activeFilterHeader (filter: string) {
        return filter === this.selectedFilter;
    }

    async toggle (filter: string) {
        this.selectedFilter = filter === this.selectedFilter ? '' : filter;
    }

    filterValues (filter: string) {
        const index: number = ui.labels.findIndex((l: UILabel) => l.name === filter);
        return index > -1 ? ui.labels[index].valueFormat : [];
    }

    activeFilter (filter: string, value: string) {
        if (this.activeHeader(filter)) {
            return filters.active[filter].values.includes(value);
        }
        return false;
    }

    tooltip (bool: boolean) {
        return bool ? 'Remove Filter' : 'Add Filter';
    }

    toggleFilter (filter: string, value: string) {
        updateConfig.updateStart(0);

        if (this.activeHeader(filter)) {
            if (filters.active[filter].values.includes(value)) {
                updateActiveFilters.removeFilterValue(filter, value);
            } else {
                updateActiveFilters.updateFilter(filter, value);
            }
        } else {
            const index: number = ui.labels.findIndex((i: UILabel) => i.name === filter);
            updateActiveFilters.setupFilter(
                filter,
                new ActiveFilter(ui.labels[index].text, ui.labels[index].dataType, [value]),
            );
        }
        updateUI.updateShortcutChanges(true);
    }

    get activeTrends () {
        return trends.active;
    }
}
