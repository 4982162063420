/* eslint-disable */
import { ui } from '@/observables/UI';
import UILabel from '@/common/UILabel';
import ValueFormat from '@/common/ValueFormat';
import Highcharts, { AxisLabelsFormatterCallbackFunction, XAxisOptions, YAxisOptions } from 'highcharts';
import { nFormatter, fixedToDp, hasNegative } from '@/functions/tools';

const chartSettings: any = {
    chart: {
        backgroundColor: 'transparent',
        spacingBottom: 10,
        spacingTop: 30,
        spacingLeft: 0,
        zoomType: 'xy',
        panning: true,
        panKey: 'shift',
        // marginRight: '20px'
        height: null,
        width: null,
    },
    exporting: {
        enabled: false,
        fallbackToExportServer: false,
        chartOptions: {
            title: {
                text: ''
            },
            chart: {
                backgroundColor: '#212121'
            },
            xAxis: [{
                labels: {
                    style: {
                        color: '#ffffff'
                    }
                },
                title: {
                    style: {
                        color: '#ffffff'
                    }
                },
                gridLineColor: '#424242'
            }],
            yAxis: [{
                labels: {
                    style: {
                        color: '#ffffff'
                    }
                },
                title: {
                    style: {
                        color: '#ffffff'
                    }
                },
                gridLineColor: '#424242'
            }]
        }
    },
    navigation: {
        buttonOptions: {
            symbolStroke: 'rgb(0, 163, 199)',
            theme: {
                fill: 'none'
            },
        },
        menuStyle: {
            background: '#424242'
        },
        menuItemStyle: {
            color: '#fff'
        },
        menuItemHoverStyle: {
            background: '#424242',
            color: 'rgb(0, 163, 199)'
        }
    },
    credits: {
        text: 'Source LCP InsurSight',
        href: '',
        enabled: false,
        style: {
            color: '#ffffff',
        },
    },
    legend: {
        enabled: false,
    },
    plotOptions: {
        line: {
            animation: false,
            marker: {
                enabled: false,
            },
        },
        scatter: {
            lineWidth: 0,
        },
        series: {
            animation: false,
            lineWidth: 3,
            marker: {
                symbol: 'circle'
            },
            stickyTracking: false
        }
    },
    xAxis: {
        crosshair: true,
        labels: {
            style: {
                color: '#fff',
            },
        },
        title: {
            style: {
                color: '#fff',
            },
            text: 'Development Year',
            margin: 0
        },
        gridLineWidth: 1,
        gridLineColor: 'rgb(48, 48, 48)'
    },
    yAxis: {
        gridLineColor: '#303030',
        title: {
            style: {
                color: '#fff',
            },
            text: '',
        },
        labels: {
            format: null,
        },
    },
    series: [],
    title: {
        text: null,
    },
    tooltip: {
        backgroundColor: 'White',
        borderRadius: 0,
        borderWidth: 4,
        useHTML: true,
        formatter: chartToolTip,
        followPointer: true,
        snap: 0,
        outside: true,
    },
};

function standardChartTip(point: any) {
    let yLabel = null;
    let yValue = null;
    let seriesName = '';
    if (Object.prototype.hasOwnProperty.call(point.series.chart.userOptions, 'axisFormats')) {
        if (Object.prototype.hasOwnProperty.call((point.series.chart.userOptions as any).axisFormats, 'yAxis')) {
            const vf = (point.series.chart.userOptions as any).axisFormats.yAxis.valueFormat;
            const val =
            vf.multiply && vf.multiply > 1 ?
            (Number(point.y) * vf.multiply).toFixed(vf.nDp) :
            nFormatter(Number(point.y), point.series.dataMax, vf.nDp);
            seriesName = point!.point.series.name;
            yLabel = `${(point.series.chart.userOptions as any).axisFormats.yAxis.axisText}`;
            yValue = `${vf.prefix}${val}${vf.suffix}`;
        }
    } else {
        const index = ui.labels.findIndex((i: UILabel) => i.name === point!.series.userOptions.category || i.name === point!.series.userOptions.data[0]?.category);

        yValue = nFormatter(Number(point!.point.y), point.series.yAxis.dataMax, 2);
        yLabel = '';
        let prefix = '';
        let suffix = '';

        if (index > -1) {
            let matchLabel =  point!.series.userOptions.label || point!.series.userOptions.data[0]?.label;
            const valIndex: number = ui.labels[index].valueFormat.findIndex((vi: ValueFormat) => vi.text === matchLabel);
            if (valIndex > -1) {

                const val = ui.labels[index].valueFormat[valIndex];

                if (Object.prototype.hasOwnProperty.call(val, 'prefix')) {
                    if (val.prefix !== null && val.prefix !== 'None') prefix = val.prefix;
                }
                if (Object.prototype.hasOwnProperty.call(val, 'suffix')) {
                    if (val.suffix !== null && val.suffix !== 'None') suffix = val.suffix;
                }

                yValue = `${prefix}${yValue}${suffix}`;

                // if (point.series.yAxis.dataMax as number >= 1000000000) {
                //     yValue = `${prefix}${(point!.point.y as number / 1000000000).toFixed(val.nDp)}b${suffix}`;
                // } else if (point.series.yAxis.dataMax as number >= 1000000) {
                //     yValue = `${prefix}${(point!.point.y as number / 1000000).toFixed(val.nDp)}m${suffix}`;
                // } else if (point.series.yAxis.dataMax as number >= 1000) {
                //     yValue = `${prefix}${(point!.point.y as number / 1000).toFixed(val.nDp)}k${suffix}`;
                // } else {
                //     yValue = `${prefix}${point!.point.y.toFixed(val.nDp)}${suffix}`;
                // }
            }
        }

        if (point!.point.series.userOptions.label !== undefined) {
            yLabel = point!.point.series.userOptions.label;
            seriesName = point!.point.series.name;
        } else {
            yLabel = point!.series.userOptions.data[0].label;
            seriesName = point!.key;
        }
        if(point!.series.userOptions.data[0].customLabel){
            yLabel = point!.series.userOptions.data[0].customLabel;
        }
    }

    let yInfo = '';
    if (!ui.hideYAxis) {
        yInfo = `<div class="flex-wrapper">
        <div class="label">${yLabel}:</div>
        <div> <b>${yValue}</b></div>
        </div>`
    }


    let xInfo = ''
    if (!ui.hideXAxis) {
        xInfo = `<div class="flex-wrapper">
        <div class="label">${point.series.xAxis.axisTitle.textStr}</div>
        <div><b>${point.series.xAxis.axisTitle.textStr !== 'Development Year' ?  point.x : nFormatter(point.x, 0, 2) }</b></div>
        </div>`
    }

    if(yLabel === 'Previous years average'){
        return `
        <div class="tooltip-wrapper">
            <div>
                ${yInfo}
                ${xInfo}
            </div>
        </div>`;
    }

    return `
    <div class="tooltip-wrapper">
        <div class="title" style="color: #000">${seriesName}</div>
        <div>
            ${yInfo}
            ${xInfo}
        </div>
    </div>`;
}

function scatterChartTip() {
    return scatterChartToolTip(this);
}

function scatterChartToolTip(point: any) {
    // const index = ui.labels.findIndex((i: UILabel) => i.name === point!.series.userOptions.category || i.name === point!.series.userOptions.data[0]?.category);
    let yValue: string = `${point!.point.y}`;
    let multi = point.series.yAxis.userOptions?.valueFormat?.multiply || 1;
    let multiply = multi > 1 ? multi : 1;
    yValue = nFormatter(Number(yValue) * multiply , point.series.yAxis.dataMax, 2)
    const seriesToolTipText = point.series.userOptions?.seriesToolTipText
    let seriesName = point!.key;

    // specific settings for reservingClassQualityResults
    if(point.series.xAxis.userOptions.name && point.series.yAxis.userOptions.name){
        return customScatterChartTip(point, seriesName, yValue);
    }

    let tooltipInner: string;
    if (seriesToolTipText) {
        let valueFormat = point.series.yAxis.userOptions.valueFormat;

        tooltipInner = `${seriesToolTipText}: ${valueFormat?.prefix}${yValue}${valueFormat?.suffix}`
    } else { tooltipInner = `${seriesName}: £${yValue}` }

    return `
    <div class="tooltip-wrapper">
        <div class="title" style="color: #000">${tooltipInner}</div>
        </div>`;
}

function customScatterChartTip(point: any, seriesName, yValue){
    let xValue = seriesName
    if(typeof seriesName === 'number'){
        xValue = nFormatter(seriesName, point.series.xAxis.dataMax, 2)
    }

    return `
    <div class="tooltip-wrapper">
        <div class="flex-wrapper">
        <div class="label">${point.series.xAxis.userOptions.name}:</div>
        <div><b>£${xValue}</b></div>
        </div>
        <div class="flex-wrapper">
        <div class="label">${point.series.yAxis.userOptions.name}:</div>
        <div><b>${yValue}</b></div>
        </div>
    </div>`;
}

function alternateChartTip(point: any) {
    let fromValue  = nFormatter(point!.point.low, point!.point.low, 2);
    let toValue  = nFormatter(point!.point.high, point!.point.high, 2);
    let prefix = '';
    let suffix = '';
    const index = ui.labels.findIndex((i: UILabel) => i.name === point!.series.userOptions.category || i.name === point!.series.userOptions.data[0]?.category);

    if (index > -1) {
        let matchLabel = point!.series.userOptions.label || point!.series.userOptions.data[0]?.label;
        const valIndex: number = ui.labels[index].valueFormat.findIndex((vi: ValueFormat) => vi.text === matchLabel);
        if (valIndex > -1) {

            const val = ui.labels[index].valueFormat[valIndex];

            if (Object.prototype.hasOwnProperty.call(val, 'prefix')) {
                if (val.prefix !== null && val.prefix !== 'None') prefix = val.prefix;
            }
            if (Object.prototype.hasOwnProperty.call(val, 'suffix')) {
                if (val.suffix !== null && val.suffix !== 'None') suffix = val.suffix;
            }
        }
    }

    return `
    <div class="tooltip-wrapper">
        <div>
            <div class="flex-wrapper">
                <div class="label">Range:</div>
                <div> <b>${prefix}${fromValue}${suffix} - ${prefix}${toValue}${suffix}</b></div>
            </div>
        </div>
    </div>`;
}

function chartToolTip(this) {

    if (Object.prototype.hasOwnProperty.call(this!.point, 'high')) {
        return alternateChartTip(this);
    } else {
        return standardChartTip(this);
    }
}

function DarkModeChartStyling(isDark: boolean) {
    return {
        chart: {
            backgroundColor:  isDark ? '#212121' : '#ffffff',
        },
        xAxis: {
            labels: {
                style: {
                    color: isDark ? '#ffffff' : '#2B2B2B',
                },
                enabled: !ui.hideXAxis,
            },
            title: {
                style: {
                    color: isDark ? '#ffffff' : '#2B2B2B',
                },
            },
            gridLineColor:  isDark   ? '#424242' : '#f2f2f2',
        },
        yAxis: {
            labels: {
                style: {
                    color: isDark ? '#ffffff' : '#2B2B2B',
                },
                enabled: !ui.hideYAxis,
            },
            title: {
                style: {
                    color: isDark ? '#ffffff' : '#2B2B2B',
                },
            },
            gridLineColor:  isDark   ? '#424242' : '#f2f2f2',
        },
        exporting: {
            chartOptions: {
                title: {
                    style: {
                        color: isDark ? '#ffffff' : '#2B2B2B',
                    },
                },
                chart: {
                    backgroundColor: isDark ? '#212121' : '#ffffff',
                },
                credits: {
                    text: 'Source LCP InsurSight',
                    href: '',
                    enabled: false,
                    style: {
                        color: isDark ? '#ffffff' : '#2B2B2B',
                    },
                },
                xAxis: [
                    {
                        labels: {
                            style: {
                                color: isDark ? '#ffffff' : '#2B2B2B',
                            },
                            enabled: !ui.hideYAxis,
                        },
                        title: {
                            style: {
                                color: isDark ? '#ffffff' : '#2B2B2B',
                            },
                            margin: !ui.hideYAxis ? 20 : 0,
                            y: -15,
                        },
                        gridLineColor: isDark ? '#424242' : '#f2f2f2',
                    },
                ],
                yAxis: [
                    {
                        labels: {
                            style: {
                                color: isDark ? '#ffffff' : '#2B2B2B',
                            },
                            enabled: !ui.hideYAxis,
                        },
                        title: {
                            style: {
                                color: isDark ? '#ffffff' : '#2B2B2B',
                            },
                        },
                        gridLineColor: isDark ? '#424242' : '#f2f2f2',
                    },
                ],
            }
        }
    }
    // options.credits = {
    //     text: 'Source LCP InsurSight',
    //     href: '',
    //     enabled: false,
    //     style: {
    //         color: bool ? '#ffffff' : '#2B2B2B',
    //     },
    // };
}

const tooltipSettings = {
    // Default tooltip placement relative to target element
    defaultPlacement: 'auto',
    // Default CSS classes applied to the tooltip element
    defaultClass: 'vue-tooltip-theme',
    // Default CSS classes applied to the target element of the tooltip
    defaultTargetClass: 'has-tooltip',
    // Is the content HTML by default?
    defaultHtml: true,
    // Default HTML template of the tooltip element
    // It must include `tooltip-arrow` & `tooltip-inner` CSS classes (can be configured, see below)
    // Change if the classes conflict with other libraries (for example bootstrap)
    defaultTemplate:
        '<div class="tooltip" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner"></div></div>',
    // Selector used to get the arrow element in the tooltip template
    defaultArrowSelector: '.tooltip-arrow, .tooltip__arrow',
    // Selector used to get the inner content element in the tooltip template
    defaultInnerSelector: '.tooltip-inner, .tooltip__inner',
    // Delay (ms)
    defaultDelay: 0,
    // Default events that trigger the tooltip
    defaultTrigger: 'hover focus',
    // Default position offset (px)
    defaultOffset: 0,
    // Default container where the tooltip will be appended
    defaultContainer: 'body',
    defaultBoundariesElement: undefined,
    defaultPopperOptions: {},
    // Class added when content is loading
    defaultLoadingClass: 'tooltip-loading',
    // Displayed when tooltip content is loading
    defaultLoadingContent: '...',
    // Hide on mouseover tooltip
    autoHide: true,
    // Close tooltip on click on tooltip target?
    defaultHideOnTargetClick: true,
    // Auto destroy tooltip DOM nodes (ms)
    disposeTimeout: 5000,
    // Options for popover
    popover: {
        defaultPlacement: 'auto',
        // Use the `popoverClass` prop for theming
        defaultClass: 'vue-popover-theme',
        // Base class (change if conflicts with other libraries)
        defaultBaseClass: 'tooltip popover',
        // Wrapper class (contains arrow and inner)
        defaultWrapperClass: 'wrapper',
        // Inner content class
        defaultInnerClass: 'tooltip-inner popover-inner',
        // Arrow class
        defaultArrowClass: 'tooltip-arrow popover-arrow',
        // Class added when popover is open
        defaultOpenClass: 'open',
        defaultDelay: 0,
        defaultTrigger: 'click',
        defaultOffset: 0,
        defaultContainer: 'body',
        defaultBoundariesElement: undefined,
        defaultPopperOptions: {},
        // Hides if clicked outside of popover
        defaultAutoHide: true,
        // Update popper on content resize
        defaultHandleResize: true,
    },
};

enum LoginType {
    local = 'localSignIn',
    lcp = 'lcpSignIn',
    signUp = 'localSignUp'
}

function base64ToBlob(val, mime) {
    const bstr = atob(val);
    const ab = s2ab(bstr);
    return new Blob([ab], { type: mime });
}

function s2ab(val) {
    const buf = new ArrayBuffer(val.length);
    const view = new Uint8Array(buf);
    // eslint-disable-next-line
    for (let i = 0; i !== val.length; ++i) view[i] = val.charCodeAt(i) & 0xFF;
    return buf;
}

export {
    DarkModeChartStyling,
    LoginType,
    base64ToBlob,
    chartSettings,
    chartToolTip,
    scatterChartTip,
    tooltipSettings,
};
