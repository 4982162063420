export default class RequestRemoveComment {
    chartId: string;

    commentId: string;

    replyId: string;

    constructor (chartId: string, commentId: string, replyId: string) {
        this.chartId = chartId;
        this.commentId = commentId;
        this.replyId = replyId;
    }
}
