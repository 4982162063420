import {
    AxisLabelsFormatterContextObject,
} from 'highcharts';
import YAxisLevelFormatter from '@/common/YAxisLevelFormatter';
import UILabel from '@/common/UILabel';
import { updateUI, ui } from '@/observables/UI';
import ValueFormat from '@/common/ValueFormat';
import { config } from '@/observables/Config';
import ValueFormatRules from '@/common/ValueFormatRules';
import ColourMappins from '@/assets/json/ColourMappings.json';
import { FileUploadToken } from '@/common/API/FileUpload';
import Http from '@/auth/api/apiHelper';
import Vue from 'vue';
import dayjs from 'dayjs';

const { BlobServiceClient } = require('@azure/storage-blob');

function mergeDeep (target, source, isMergingArrays = false) {
    const newTarget = ((obj) => {
        let cloneObj;
        try {
            cloneObj = JSON.parse(JSON.stringify(obj));
        } catch (err) {
            cloneObj = { ...obj };
        }
        return cloneObj;
    })(target);
    const isObject = (obj) => obj && typeof obj === 'object';
    if (!isObject(target) || !isObject(source)) {
        return source;
    }
    Object.keys(source).forEach((key) => {
        const targetValue = target[key];
        const sourceValue = source[key];
        if (Array.isArray(targetValue) && Array.isArray(sourceValue)) {
            if (isMergingArrays) {
                target[key] = targetValue.map((x, i) => (sourceValue.length <= i
                    ? x
                    : mergeDeep(x, sourceValue[i], isMergingArrays)));
                if (sourceValue.length > targetValue.length) {
                    target[key] = target[key].concat(
                        sourceValue.slice(targetValue.length),
                    );
                }
            } else {
                target[key] = targetValue.concat(sourceValue);
            }
        } else if (isObject(targetValue) && isObject(sourceValue)) {
            target[key] = mergeDeep({ ...targetValue }, sourceValue, isMergingArrays);
        } else {
            target[key] = sourceValue;
        }
    });
    return newTarget;
}

function nFormatter (num, dataMax, digits = -1) {
    let suffix = '';
    let finalNumber = null;
    const absDataMax = Math.abs(dataMax);
    const breakpoints = [
        { number: 1000000000000, suffix: 't' },
        { number: 1000000000, suffix: 'b' },
        { number: 1000000, suffix: 'm' },
        { number: 1000, suffix: 'k' },
        { number: 0, suffix: '' },
    ];
    let i = 0;
    while (i < breakpoints.length && finalNumber === null) {
        if (absDataMax as number >= breakpoints[i].number) {
            suffix = breakpoints[i].suffix;
            finalNumber = digits > -1
                ? Number((num as number / (breakpoints[i].number || 1)).toFixed(digits))
                : Number(num as number / (breakpoints[i].number || 1));
        }
        i += 1;
    }
    return `${finalNumber}${suffix}`;
}

function numberDivision (ogNumber: number): number {
    const breakpoints = [
        1000000000000,
        100000000000,
        1000000000,
        1000000,
        1000,
        10,
        -1000000000000,
        -100000000000,
        -1000000000,
        -1000000,
        -1000,
        -10,
    ];
    let finalNumber = 0;
    let i = 0;
    while (i < breakpoints.length && finalNumber === 0) {
        if ((ogNumber >= 0 && ogNumber >= breakpoints[i]) || (ogNumber < 0 && ogNumber <= breakpoints[i])) {
            finalNumber = Number((ogNumber / breakpoints[i]).toFixed(0));
        }
        i += 1;
    }
    return finalNumber;
}

function fixedToDp (num, digits) {
    const lookup = [
        { value: 1, symbol: '' },
        { value: 1e3, symbol: 'k' },
        { value: 1e6, symbol: 'm' },
        { value: 1e9, symbol: 'b' },
        { value: 1e12, symbol: 't' },
        { value: 1e15, symbol: 'p' },
        { value: 1e18, symbol: 'e' },
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    if (num >= 0) {
        const item = lookup
            .slice()
            .reverse()
            .find((elem) => num >= elem.value);
        return item
            ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol
            : parseFloat(num).toFixed(2);
    }
    const deNeg = num * -1;
    const item = lookup
        .slice()
        .reverse()
        .find((elem) => deNeg >= elem.value);
    return item
        ? `-${((deNeg / item.value).toFixed(digits).replace(rx, '$1'))}${item.symbol}`
        : `-${Number(deNeg).toFixed(2)}`;
}

function hasNegative (number: string) {
    const numberSplit = number.split('');
    const remNeg = number;
    if (numberSplit[0] === '-') {
        numberSplit.shift();
        return { neg: number.split('')[0] === '-', remNeg: numberSplit.join('') };
    }
    return { neg: number.split('')[0] === '-', remNeg };
}

const yAxisLabelformatter: YAxisLevelFormatter = {
    set: false,
    formatter: (data: AxisLabelsFormatterContextObject) => {
        if (Object.prototype.hasOwnProperty.call(data.chart.userOptions, 'axisFormats')) {
            if (Object.prototype.hasOwnProperty.call((data.chart.userOptions as any).axisFormats, 'yAxis')) {
                const vf = (data.chart.userOptions as any).axisFormats.yAxis.valueFormat;
                let val = data.value;

                if (vf.dataType === 'number') {
                    val = (vf.multiply && vf.multiply > 1 ? Number(data.value) * vf.multiply : Number(data.value));
                    // if (vf.scaleFrontEndControl) {
                    let digits = vf.nDp;
                    const multiply = vf.multiply && vf.multiply > 1 ? vf.multiply : 1;
                    if (digits === 0) {
                        const integerTicks = data.axis.tickPositions.map((pos) => numberDivision(multiply * pos));
                        if ((new Set(integerTicks)).size !== integerTicks.length) {
                            digits = 2;
                        }
                    }
                    // at least one digit for specificity. It will be cleared by Number() inside nFormatter if not needed
                    val = nFormatter(Number(val), data.axis.max || (data.axis as any).dataMax, digits || 2);
                    // }
                }

                return `${vf.prefix === null ? '' : vf.prefix}${val}${vf.suffix === null || /[a-zA-Z]/.test(String(val)) === true ? '' : vf.suffix}`;
            }
        } else if (Object.prototype.hasOwnProperty.call(data!.chart.userOptions.series[0], 'category')) {
            const index = ui.labels.findIndex((i: UILabel) => i.name === 'value');

            let format: ValueFormat = new ValueFormat(
                (data!.chart.userOptions.series[0] as any).value,
                (data!.chart.userOptions.series[0] as any).value,
                '',
                '',
                0,
            );

            if (index > -1 && Object.prototype.hasOwnProperty.call(ui.labels[index], 'valueFormat')) {
                const valFormatIndex: number = ui.labels[index].valueFormat.findIndex(
                    (v: ValueFormat) => v.name === (data!.chart.userOptions.series[0] as any).value,
                );
                if (valFormatIndex > -1) {
                    format = ui.labels[index].valueFormat[valFormatIndex];
                }
            }

            let prefix: string = format.prefix !== null && format.prefix !== 'None' ? format.prefix : '';
            let suffix: string = format.suffix !== null && format.suffix !== 'None' ? format.suffix : '';

            if (data!.axis.userOptions.title.text === '£') {
                suffix = '';
                prefix = '£';
            }

            return `${prefix}${nFormatter(Number(data.value), (data.axis as any).dataMax)}${suffix}`;
        }
        return `${data.value}`;
    },
};

const xAxisLabelformatter: Function = (data: AxisLabelsFormatterContextObject) => {
    if (Object.prototype.hasOwnProperty.call(data.chart.userOptions, 'axisFormats')) {
        if (Object.prototype.hasOwnProperty.call((data.chart.userOptions as any).axisFormats, 'xAxis')) {
            if (Object.prototype.hasOwnProperty.call((data.chart.userOptions as any).axisFormats.xAxis, 'valueFormat')) {
                const vf = (data.chart.userOptions as any).axisFormats.xAxis.valueFormat;
                let val = data.value;

                if (vf.dataType === 'number') {
                    val = (vf.multiply && vf.multiply > 1 ? Number(data.value) * vf.multiply : Number(data.value));
                    // if (vf.scaleFrontEndControl) {
                    let digits = vf.nDp;
                    const multiply = vf.multiply && vf.multiply > 1 ? vf.multiply : 1;
                    if (digits === 0) {
                        const integerTicks = data.axis.tickPositions.map((pos) => numberDivision(multiply * pos));
                        if ((new Set(integerTicks)).size !== integerTicks.length) {
                            digits = 1;
                        }
                    }
                    // at least one digit for specificity. It will be cleared by Number() inside nFormatter if not needed
                    val = nFormatter(Number(val), (data.axis as any).dataMax, digits || 2);
                    // }
                }

                return `${vf.prefix === null ? '' : vf.prefix}${val}${vf.suffix === null || /[a-zA-Z]/.test(String(val)) === true ? '' : vf.suffix}`;
            }
        }
    } else {
        if (data.axis.userOptions.title.text.split('').includes('%')) {
            return `${nFormatter(Number(data.value) * 100, (data.axis as any).dataMax)}%`;
        }
        if (data.axis.userOptions.title.text === 'Reserves') {
            const hasNeg = hasNegative(nFormatter(Number(data.value), (data.axis as any).dataMax));
            return hasNeg.neg ? `-£${hasNeg.remNeg}` : `£${nFormatter(Number(data.value), (data.axis as any).dataMax)}`;
        }
    }
    return data.value;
};

function noDatasetIdRedirectCheck (next) {
    if (!config.dataSetId) {
        updateUI.setLoader(false);
        next('/welcome');
        return;
    } next();
}

function formatValuesUsingHeaderValueFormat (headerValueFormat: ValueFormatRules, value: string) {
    let formattedValue: string | number = Number(value);
    formattedValue *= headerValueFormat.multiply;
    formattedValue = formattedValue.toFixed(headerValueFormat.nDp);
    if (headerValueFormat.hideCommas) formattedValue = formattedValue.toLocaleString();
    formattedValue = `${headerValueFormat.prefix}${formattedValue}${headerValueFormat.suffix}`;
    return formattedValue;
}

function createAxisFormats (chartOptions) {
    return {
        yAxis: {
            axisText: chartOptions.yAxis.title.text,
            valueFormat: chartOptions.yAxis.valueFormat,
            categories: chartOptions.yAxis.categories,
        },
        xAxis: {
            axisText: chartOptions.xAxis.title.text,
            valueFormat: chartOptions.xAxis.valueFormat,
            categories: chartOptions.xAxis.categories,
        },
    };
}

function findDefaultAxisFormat (labels: UILabel[], value: string) {
    const index = labels.findIndex((i: UILabel) => i.name === 'value');
    let format: ValueFormat = new ValueFormat(
        value,
        value,
        '',
        '',
        0,
    );
    if (index > -1 && Object.prototype.hasOwnProperty.call(ui.labels[index], 'valueFormat')) {
        const valFormatIndex: number = labels[index].valueFormat.findIndex(
            (v: ValueFormat) => v.name === value,
        );
        if (valFormatIndex > -1) {
            format = labels[index].valueFormat[valFormatIndex];
        }
    }
    return format;
}

function findChartTitle (chartType: 'Predict' | 'Diagnose', activeChartId: string, chartConfig: any): string {
    let title = '';
    if (chartType === 'Predict') {
        chartConfig.all_charts.map((chart) => {
            if (activeChartId === chart.chartId) {
                title = chartConfig.info?.title ? chartConfig.info?.title : chart.title.text;
                return title;
            } return title;
        });
    } else if (chartConfig.data && chartConfig.data.ersChartTitleLong && chartConfig.data.ersChart) {
        title = chartConfig.data.ersChartTitleLong;
    } else {
        title = chartConfig.info.title;
    }
    return title.length > 190 ? `${title.substring(0, 190)}...` : title;
}

function colorMaping (color: string, darkMode: boolean) {
    const colorToMatch = color.length === 9 ? color.replace(/FF$/, '') : color;
    const colorPair = ColourMappins.filter(
        (obj) => obj.colorTrendDark === colorToMatch
      || obj.colorTrendLight === colorToMatch,
    );
    if (colorPair.length === 1) {
        const lightModeColor = colorPair[0].colorTrendLight;
        const darkModeColor = colorPair[0].colorTrendDark;
        return darkMode ? darkModeColor : lightModeColor;
    }
    return color;
}

const isValidHex = (hex) => /^#([A-Fa-f0-9]{3,4}){1,2}$/.test(hex);

const getChunksFromString = (st, chunkSize) => st.match(new RegExp(`.{${chunkSize}}`, 'g'));

const convertHexUnitTo256 = (hexStr) => parseInt(hexStr.repeat(2 / hexStr.length), 16);

const hexToRGBA = (hex) => {
    if (!isValidHex(hex)) {
        return hex;
    }
    const chunkSize = Math.floor((hex.length - 1) / 3);
    const hexArr = getChunksFromString(hex.slice(1), chunkSize);
    const [r, g, b, a] = hexArr.map(convertHexUnitTo256);
    return `rgba(${r}, ${g}, ${b}, ${a ? a / 255 : 1})`;
};

async function UploadToBlobStorage (fileUploadToken: FileUploadToken, fileInfo: any) {
    const blobServiceClient = new BlobServiceClient(`${fileUploadToken.url}${fileUploadToken.sas}`);
    const containerClient = blobServiceClient.getContainerClient(fileUploadToken.containerName);
    const folderName = fileUploadToken.folderName ? fileUploadToken.folderName : '';
    const blockBlobClient = containerClient.getBlockBlobClient(`${folderName}${fileInfo.fileName}`);

    try {
        const response = await blockBlobClient.uploadData(fileInfo.formData);
        return response;
    } catch (error) {
        Http.post(
            `${process.env.VUE_APP_API_ENDPOINT_PREDICT}/api/reportError`,
            { dataSetId: config.dataSetId, dataSetName: config.dataSetName, fileUploadError: error },
            { apiScope: [process.env.VUE_APP_API_SCOPE_PREDICT] },
        );
    }
}

function camelize (text: string) {
    const camelizeText = text.replace(/[-_\s.]+(.)?/g, (_, c) => (c ? c.toUpperCase() : ''));
    return camelizeText.substr(0, 1).toLowerCase() + camelizeText.substr(1);
}

// selector is id of multiselect component otherwise will use first multiselect component on page
function scrollToSelected (selector: string = ''): void {
    Vue.nextTick(() => {
        let selected;
        if (selector) {
            selected = document.querySelector(selector)
                .parentElement
                .parentElement
                .querySelector('.multiselect__option--selected')?.parentElement;
        } else {
            selected = document.querySelector('.multiselect__option--selected')?.parentElement as HTMLElement | null;
        }
        if (selected) {
            const listItemHeight = selected.offsetHeight;
            const multiselect = selected.parentElement.parentElement as HTMLElement | null;
            const multiselectElements = Array.from(multiselect.querySelector('.multiselect__content').children);
            const index = [...(multiselectElements as HTMLElement[])].findIndex((elem) => elem.querySelector('span')
                .className.includes('multiselect__option--selected'));
            if (multiselect) {
                // let selected be 3rd one from top
                multiselect.scrollTop = (index - 2) * listItemHeight;
            }
        }
    });
}

function hexToRGB(hexStr) {
    const col = { r: null, g: null, b: null };
    col.r = parseInt(hexStr.substr(1, 2), 16);
    col.g = parseInt(hexStr.substr(3, 2), 16);
    col.b = parseInt(hexStr.substr(5, 2), 16);
    return col;
}

// iVBORw0KGgoAAAANSUhEUgAAABkAAAAECAYAAAB7oZQmAAAAAXNSR0IArs4c6QAAABZJREFUKFNj/M9Q/Z+BxoBx1BJSQhgArb8J6UsdquwAAAAASUVORK5CYII=;
// iVBORw0KGgoAAAANSUhEUgAAABkAAAAECAYAAAB7oZQmAAAAAXNSR0IArs4c6QAAABZJREFUKFNjZPjP8J+BxoBx1BJSQhgAyiMH/e2Q7rsAAAAASUVORK5CYII=;
function changeColInUri(colFrom, colTo) {
    const data = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAECAYAAAB7oZQmAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAABZJREFUGJVj3C8t/Z+BxoCJ1hYML0sAF98B/JbmKPMAAAAASUVORK5CYII=';
    // create fake image to calculate height / width
    const img = document.createElement('img');
    img.src = data;
    img.style.visibility = 'hidden';
    document.body.appendChild(img);
    const canvas = document.createElement('canvas');
    canvas.width = 25;
    canvas.height = 4;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(img, 0, 0); // remove image
    img.parentNode.removeChild(img); // do actual color replacement
    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    const newData = imageData.data;
    const rgbFrom = hexToRGB(colFrom);
    const rgbTo = hexToRGB(colTo);
    let r; let g; let b;
    for (let x = 0, len = newData.length; x < len; x += 4) {
        r = newData[x];
        g = newData[x + 1];
        b = newData[x + 2];
        if ((r === rgbFrom.r) && (g === rgbFrom.g) && (b === rgbFrom.b)) {
            newData[x] = rgbTo.r;
            newData[x + 1] = rgbTo.g;
            newData[x + 2] = rgbTo.b;
        }
    } ctx.putImageData(imageData, 0, 0);
    return canvas.toDataURL();
}

function unixToReadable(unix: number):string {
    return dayjs(unix * 1000).format('DD/MM/YYYY HH:mm');
}

// const swappedImage = changeColInUri('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAECAYAAAB7oZQmAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAABZJREFUGJVj3C8t/Z+BxoCJ1hYML0sAF98B/JbmKPMAAAAASUVORK5CYII=', '#bf1b1b', '#000000');

export {
    mergeDeep,
    nFormatter,
    hasNegative,
    yAxisLabelformatter,
    xAxisLabelformatter,
    fixedToDp,
    noDatasetIdRedirectCheck,
    formatValuesUsingHeaderValueFormat,
    createAxisFormats,
    findDefaultAxisFormat,
    findChartTitle,
    colorMaping,
    hexToRGBA,
    UploadToBlobStorage,
    camelize,
    scrollToSelected,
    changeColInUri,
    unixToReadable,
};
