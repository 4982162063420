
import {
    Vue, Component, Prop, Watch,
} from 'vue-property-decorator';
import PrettyCheck from 'pretty-checkbox-vue/check';
import { filters, updateActiveFilters } from '@/observables/Filters';
import { trends, updateActiveTrends } from '@/observables/Trends';
import { ui, updateUI } from '@/observables/UI';
import NumberValue from '@/common/NumberValue';
import ActiveFilter from '@/common/ActiveFilter';
import NumberFilter from '@/common/NumberFilter';
import UILabel from '@/common/UILabel';

@Component({
    components: {
        PrettyCheck,
    },
})
export default class NumberInput extends Vue {
    @Prop() readonly filterKey: string;

    @Prop() filterVariables: NumberFilter;

    @Prop() readonly type: string;

    @Prop() readonly parentTrend: string;

    filterData: NumberFilter = {
        from: null,
        fromCheck: false,
        to: null,
        toCheck: false,
    };

    created () {
        this.filterData = { ...this.filterVariables };
        this.onFilterVariablesChange();
    }

    toggleCheck(target: 'from' | 'to') {
        this.filterData[target === 'from' ? 'fromCheck' : 'toCheck'] = !this.filterData[target === 'from' ? 'fromCheck' : 'toCheck'];
        this.validateChecks();
        this.onFilterVariablesChange();
    }

    updateInput(val: string, target: 'from' | 'to') {
        this.filterData[target] = val;
        if (this.filterData[target === 'from' ? 'fromCheck' : 'toCheck']) {
            this.onFilterVariablesChange();
        }
    }

    activeFilter () {
        return this.type === 'filter' ? Object.prototype.hasOwnProperty.call(filters.active, this.filterKey) : Object.prototype.hasOwnProperty.call(trends.active, this.filterKey);
    }

    validateChecks () {
        if (this.filterData.from === null || this.filterData.from.length === 0) {
            this.filterData.from = null;
            this.filterData.fromCheck = false;
        }
        if (this.filterData.to === null || this.filterData.to.length === 0) {
            this.filterData.to = null;
            this.filterData.toCheck = false;
        }
    }

    get removeFilter () {
        if (this.filterData.from || this.filterData.to) {
            if (this.filterData.fromCheck && this.filterData.toCheck) {
                return false;
            }
            if (this.filterData.fromCheck) {
                return false;
            }
            if (this.filterData.toCheck) {
                return false;
            }
        }
        return true;
    }

    get filterExists() {
        if (this.type === 'filter') {
            return Object.prototype.hasOwnProperty.call(filters.active, this.filterKey);
        }
        if (this.type === 'trend') {
            return Object.prototype.hasOwnProperty.call(trends.active, this.filterKey);
        }
        return false;
    }

    @Watch('filterExists', { deep: true })
    checkFilterExists () {
        if (!this.filterExists) {
            this.filterData = {
                from: null,
                fromCheck: false,
                to: null,
                toCheck: false,
            };
        }
    }

    @Watch('filterVariables', { deep: true })
    updateValues () {
        this.filterData = { ...this.filterVariables };
    }

    onFilterVariablesChange () {
        let valueInput = null;
        if (this.filterData.from || this.filterData.to) {
            if (this.filterData.fromCheck && this.filterData.toCheck) {
                valueInput = new NumberValue(Number(this.filterData.from), Number(this.filterData.to));
            } else if (this.filterData.fromCheck) {
                valueInput = new NumberValue(Number(this.filterData.from), null);
            } else if (this.filterData.toCheck) {
                valueInput = new NumberValue(null, Number(this.filterData.to));
            }
        }
        if (this.type === 'filter') {
            if (this.removeFilter) {
                updateActiveFilters.removeNumberFilterValue(this.filterKey, null);
            } else if (this.activeFilter()) {
                updateActiveFilters.updateNumberFilter(this.filterKey, 'from', valueInput.from);
                updateActiveFilters.updateNumberFilter(this.filterKey, 'to', valueInput.to);
            } else {
                const index: number = ui.labels.findIndex((i: UILabel) => i.name === this.filterKey);
                updateActiveFilters.setupFilter(
                    this.filterKey,
                    new ActiveFilter(ui.labels[index].text, ui.labels[index].dataType, [valueInput]),
                );
            }
        }
        if (this.type === 'trend') {
            if (this.removeFilter) {
                updateActiveTrends.removeNumberTrend(this.parentTrend, this.filterKey);
            } else {
                updateActiveTrends.updateNumberFilter(this.parentTrend, this.filterKey, valueInput);
            }
        }
        updateUI.updateShortcutChanges(true);
    }
}
