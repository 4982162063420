
import {
    Vue, Component, Watch, Prop,
} from 'vue-property-decorator';
import { actionProjections, projections } from '@/observables/Projections';
import { ui } from '@/observables/UI';
import UniversalModal from '@/components/layout/modals/UniversalModal.vue';
import MetricsTotalModal from '@/components/layout/modals/MetricsTotalsModal.vue';
import LoaderModal from '../../modals/LoaderModal.vue';

@Component({
    components: {
        UniversalModal,
        MetricsTotalModal,
        LoaderModal,
    },
})
export default class ProjectionTotals extends Vue {
    @Prop({ default: false }) reserveTotals: boolean;

    public totalsLoading: boolean = false;

    get ersId () {
        return projections.ersId;
    }

    get pendingChanges () {
        return ui.pendingChanges;
    }

    get projectionTotals () {
        return projections.projectionTotals;
    }

    get displayMetricsTotals() {
        return [
            {
                title: this.projectionTotals.metricText,
                value: null,
            },
            {
                title: this.projectionTotals.totalMetricsLabel,
                value: this.projectionTotals.ersMetricTotal,
            },
            {
                title: this.projectionTotals.scenarioMetricsLabel,
                value: this.projectionTotals.scenarioMetricTotal,
            },
            {
                title: this.projectionTotals.changeFromScenarioLabel,
                value: this.projectionTotals.changeFromSavedScenario,
            },
            ...(this.showFilterOnText ? [{
                title: '',
                value: `Filtered on ${this.projectionTotals.reserveClassCount} of ${this.projectionTotals.reserveClassTotal} reserving classes`,
                pointToFootnote: false,
            }] : []),
        ];
    }

    get onReservingClassPage () {
        return this.$route.name === 'reservingClass';
    }

    get shouldFetchProjectionTotals() {
        return this.ersId && !this.reserveTotals;
    }

    get showFilterOnText() {
        return (this.projectionTotals.reserveClassCount < this.projectionTotals.reserveClassTotal) && this.onReservingClassPage;
    }

    showMetricsTotalsModal: boolean = false;

    toggleMetricsTotalsModal(): void {
        this.showMetricsTotalsModal = !this.showMetricsTotalsModal;
    }

    @Watch('shouldFetchProjectionTotals', { immediate: true })
    reload (itShould: boolean) {
        if (itShould) {
            this.totalsLoading = true;
            actionProjections.getProjectionTotals();
        }
    }

    @Watch('displayMetricsTotals', { deep: true })
    setLoader () {
        this.totalsLoading = true;
        setTimeout(() => {
            this.totalsLoading = false;
        }, 1000);
    }
}
