
import { Vue, Component, Watch } from 'vue-property-decorator';
import PageTour from '@/components/PageTour/PageTour.vue';
import cssVars from 'css-vars-ponyfill';
import * as Highcharts from 'highcharts';
import TimerModal from '@/components/layout/TimerModal.vue';
import DownloadModal from '@/components/layout/modals/DownloadModal.vue';
import SettingsModal from '@/components/layout/modals/SettingsModal.vue';
import LoaderModal from '@/components/layout/modals/LoaderModal.vue';
import ProjectionPannelLoader from '@/components/layout/modals/ProjectionPannelLoader.vue';
import ExtendedSideBar from '@/components/layout/ExtendedSideBar.vue';
import TopBar from '@/components/layout/TopBar.vue';
import BottomBar from '@/components/layout/BottomBar.vue';
import { ui, updateUI } from '@/observables/UI';
import { config } from '@/observables/Config';
import ErrorModal from '@/components/layout/modals/ErrorModal.vue';
import { projections } from '@/observables/Projections';
import { chartSettings } from '@/functions/objects';
import LcpNotify from '@/components/LcpNotify/LcpNotify.vue';
import { NotifyOptions } from '@/components/LcpNotify/NotifyOptions';
import LcpTour from '@/components/layout/LcpTour.vue';
import TopBarReport from '@/components/layout/TopBarReport.vue';
import { loggedIn } from './auth/MsalAuth';
import { apiMessages } from './auth/messages';

@Component({
    components: {
        BottomBar,
        DownloadModal,
        ExtendedSideBar,
        SettingsModal,
        ErrorModal,
        TimerModal,
        TopBar,
        LoaderModal,
        ProjectionPannelLoader,
        LcpNotify,
        LcpTour,
        PageTour,
        TopBarReport,
    },
})
export default class App extends Vue {
    notifyOptions: NotifyOptions = {
        position: 'bottom right',
        width: 400,
    }

    get uiStore() {
        return ui;
    }

    get loggedIn(): boolean {
        return loggedIn();
    }

    get currentPage(): string {
        return config.currentPage;
    }

    get darkMode(): boolean {
        return ui.darkMode;
    }

    get error() {
        return ui.errorModal;
    }

    get ApiMessages() {
        return apiMessages();
    }

    public get standaloneView(): boolean {
        return this.$route.meta?.standalone;
    }

    get showProjectionsSidebar(): boolean {
        const page = config.currentPage;
        return (
            (this.$route.matched.length > 0
        && this.$route.meta?.routeIsPredict
        && this.$route.matched[this.$route.matched.length - 1].components
            .SideBar
        && !ui.loader)
      || (page === 'segmentations'
        && projections.segmentationName !== ''
        && !ui.loader)
        );
    }

    get showProjectionsNavbar(): boolean {
        const page = config.currentPage;
        return (
            (this.$route.matched.length > 0
        && this.$route.matched[this.$route.matched.length - 1].components
            .ProjectionsConfigNavigation
        && !ui.loader)
      || (page === 'segmentations'
        && projections.segmentationName !== ''
        && !ui.loader)
        );
    }

    mounted() {
        Highcharts.setOptions(chartSettings);
        updateUI.setPageWrapperHeight(window.innerHeight);
        updateUI.setPageWrapperWidth(window.innerWidth);
        window.addEventListener('resize', this.setPageWrapperHeight);
    }

    destroyed() {
        window.removeEventListener('resize', this.setPageWrapperHeight);
    }

    private setPageWrapperHeight(): void {
        updateUI.setPageWrapperWidth(window.innerWidth);
        updateUI.setPageWrapperHeight(window.innerHeight);
    }

    sessionIdHeaderIsSet = false;

    sessionIdHeaderSet (isSet: boolean) {
        this.sessionIdHeaderIsSet = isSet;
    }

    isReleaseManger: boolean | null = false;

    setReleaseManager (isReleaseManger: boolean) {
        this.isReleaseManger = isReleaseManger;
    }

  @Watch('darkMode', { immediate: true })
    handler(isDark: boolean) {
        cssVars({
            variables: {
                '--base': isDark ? '#303030' : '#dedede',
                '--bar': isDark ? '#212121' : '#ffffff',
                '--content': isDark ? '#424242' : '#fefefe',
                '--altContent': isDark ? '#424242' : '#f2f2f2',
                '--altContentTransparent': isDark ? '#42424266' : '#f2f2f266',
                // tables
                '--table': isDark ? '#212121' : '#d0d0d0', // '#ffffff',
                '--tableHeader': isDark ? '#212121' : '#dcdcdc',
                '--tableRow': isDark ? '#303030' : '#f2f2f2',
                '--tableRowHover': isDark ? '#585858' : '#e3e3e3',
                '--tableAltRow': isDark ? '#484848' : '#efefef',
                '--tableOddRow': isDark ? '#3a3a3a' : '#eeecec',
                //
                '--chartBase': isDark ? '#212121' : '#ffffff',
                '--popoverBackground': isDark ? '#262626' : '#d1d1d1',
                '--tagOpacity': isDark ? '0.4' : '0.8',
                '--infoLine': isDark ? '#353535' : '#e6e6e6',
                '--text': isDark ? '#ffffff' : '#2B2B2B',
                '--progressBar': isDark ? '#ffffff' : '#858585',
                // shadows
                '--tooltipShadow': isDark
                    ? '0px 0px 25px -3px #000200'
                    : '0px 0px 15px -3px #cecece',
                '--tableShadow': isDark
                    ? '-3px 0 6px 4px rgb(0 0 0 / 40%)'
                    : '-3px 0 6px 4px rgb(106 105 105 / 40%)',
                '--cardShadow': isDark
                    ? '0px 0px 5px 2px #1f1f1f7d'
                    : '0px 0px 5px 2px #1f1f1f12',

                // alts
                '--textDarken50': isDark ? '#808080' : '#000000',
                '--dashedLine': isDark ? '#808080' : '#dedede',
                '--baseLighten5': isDark ? '#3d3d3d' : '#f3f3f3',
                '--highlightBorder': isDark ? '#d4dc5c' : '#821a4d',
            },
        });
    }

  get showTour () : boolean {
      return ui.showTour;
  }
}
