
import { Vue, Component, Prop } from 'vue-property-decorator';
import { ui, updateUI } from '@/observables/UI';
import ErrorModalData from '@/common/ErrorModal';

@Component({
    components: {

    },
})
export default class ErrorModal extends Vue {
    close() {
        updateUI.updateErrorModal(null);
    }

    @Prop({ default: null }) loadingText: string;

    public navigateBack() {
        this.$router.push(`/${this.errorModal.backTo}`);
        updateUI.updateErrorModal(null);
    }

    get errorModal (): ErrorModalData | null {
        return ui.errorModal;
    }

    get loaderText () {
        return this.loadingText !== null ? this.loadingText : ui.loaderText;
    }

    closeModal() {
        if (this.errorModal.cancelCallBack) {
            this.errorModal.cancelCallBack();
        }
        updateUI.updateErrorModal(null);
    }

    confirmAction() {
        this.errorModal.confirmCallBack();
        this.closeModal();
    }
}
