
import {
    Vue, Component, Prop, Watch,
} from 'vue-property-decorator';
import { ScaleLoader } from '@saeris/vue-spinners';
import { ui, updateUI } from '@/observables/UI';
import Http from '@/auth/api/apiHelper';
import { config } from '@/observables/Config';
import { actionProjections, projections } from '@/observables/Projections';
import ErrorModal from '@/common/ErrorModal';

@Component({
    components: {
        ScaleLoader,
    },
})
export default class MetricsTotalsModal extends Vue {
    @Prop({ default: null }) loadingText: string;

    created() {
        this.getMetrics();
    }

    get loaderText () {
        return this.loadingText !== null ? this.loadingText : ui.loaderText;
    }

    selectedMetric: string = '';

    showAsPercentage: boolean = false;

    metrics: any = [];

    loading: boolean = false;

    handleMetricClick (metric: string) {
        this.selectedMetric = metric;
    }

    get getMetricsRequest () {
        return {
            dataSetId: config.dataSetId,
            segmentationId: projections.segmentationId,
        };
    }

    get projectionTotals () {
        return projections.projectionTotals;
    }

    @Watch('projectionTotals', { immediate: true, deep: true })
    onProjectionTotalsChange () {
        if (this.onReservingClassPage) {
            this.selectedMetric = this.projectionTotals.scenarioMovements.metric;
            this.showAsPercentage = this.projectionTotals.scenarioMovements.showAsPercentage;
            return;
        }
        if (this.projectionTotals?.scenarioDiagnostics) {
            this.selectedMetric = this.projectionTotals.scenarioDiagnostics.metric;
            this.showAsPercentage = this.projectionTotals.scenarioDiagnostics.showAsPercentage;
        }
    }

    async getMetrics(): Promise<void> {
        this.loading = true;
        Http.post<any>(
            `${process.env.VUE_APP_API_ENDPOINT_PREDICT}/GetTableConfigs`,
            this.getMetricsRequest,
            { apiScope: [process.env.VUE_APP_API_SCOPE_PREDICT] },
        ).then((tableConfig) => {
            this.metrics = [...tableConfig.data.tableViews[0].resultsLookup, ...tableConfig.data.tableViews[0].scenarioDifferenceLookup];
            this.loading = false;
        }).catch(() => {
            updateUI.updateErrorModal((new ErrorModal('', 'reserving-class', 'Reserving Class')));
        });
    }

    async updateScenarioDiagnostics(): Promise<void> {
        Http.patch<any>(
            `${process.env.VUE_APP_API_ENDPOINT_PREDICT}/reservingClasses/UpdateScenarioDiagnostics`,
            {
                scenarioDiagnostics: {
                    metric: !this.selectedMetric ? null : this.selectedMetric,
                    showAsPercentage: this.showAsPercentage,
                },
                segmentationId: projections.segmentationId,
                dataSetId: config.dataSetId,
                scenarioId: projections.scenarioId,
            },
            { apiScope: [process.env.VUE_APP_API_SCOPE_PREDICT], showSuccessMessage: true },
        ).then(() => {
            actionProjections.getProjectionTotals(true).then(() => {
                this.$emit('close-modal');
            });
        }).catch((error) => {
            this.$notify({
                data: { compact: true },
                title: 'Failed to get roll forward template.',
                type: 'error',
                text: error.response.data || '',
            });
        });
    }

    async updateScenarioMovements(): Promise<void> {
        Http.patch<any>(
            `${process.env.VUE_APP_API_ENDPOINT_PREDICT}/reservingClasses/UpdateScenarioMovements`,
            {
                scenarioMovements: {
                    metric: !this.selectedMetric ? null : this.selectedMetric,
                    showAsPercentage: this.showAsPercentage,
                },
                segmentationId: projections.segmentationId,
                dataSetId: config.dataSetId,
                scenarioId: projections.scenarioId,
            },
            { apiScope: [process.env.VUE_APP_API_SCOPE_PREDICT], showSuccessMessage: true },
        ).then(() => {
            actionProjections.getProjectionTotals(true).then(() => {
                updateUI.updateRefreshPage();
                this.$emit('close-movements-modal');
                this.$emit('close-modal');
            });
        }).catch((error) => {
            this.$notify({
                data: { compact: true },
                title: 'Failed to get roll forward template.',
                type: 'error',
                text: error.response.data || '',
            });
        });
    }

    apply() {
        if (this.onReservingClassPage) {
            this.updateScenarioMovements();
        } else {
            this.updateScenarioDiagnostics();
        }
    }

    get onReservingClassPage () {
        return this.$route.name === 'reservingClass';
    }

    get modalHeader ():string {
        if (this.onReservingClassPage) {
            return 'Choose metric for history of movements';
        }
        return 'Apply metric totals';
    }
}
