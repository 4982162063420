export default class ValueFormat {
    name?: string;

    text?: string;

    prefix?: string;

    suffix?: string;

    nDp?: number;

    constructor (name: string, text: string, prefix: string, suffix: string, nDp: number) {
        this.name = name;
        this.text = text;
        this.prefix = prefix;
        this.suffix = suffix;
        this.nDp = nDp;
    }
}
