import vue from 'vue';
import TrendFilter from '@/common/TrendFilter';
import ActiveFilter from '@/common/ActiveFilter';
import LinkConfig from '../common/LinkConfig';

interface Store {
    active: { [key: string]: ActiveFilter },
    config: TrendFilter[],
    default: { [key: string]: ActiveFilter },
    linked: LinkConfig,
}

export const filters: Store = vue.observable({
    active: {},
    config: [],
    default: {},
    linked: null,
});

export const updateFilters = {
    resetFilter() {
        filters.config = [];
    },
    setupFilter(filter: TrendFilter[]) {
        filters.config = filter;
    },
    updateFilterValues(filterIndex: number, values: string[]) {
        filters.config[filterIndex].values = values;
    },
    updateFilter(filterIndex: number, value: string) {
        filters.config[filterIndex].values.push(value);
    },
    removeFilterValue(filterIndex: number, valueIndex: number) {
        vue.delete(filters.config[filterIndex].values, valueIndex);
    },
    updateLinked(linked: LinkConfig | null) {
        filters.linked = linked;
    },
};

export const updateActiveFilters = {
    resetFilter() {
        filters.active = {};
    },
    setFilters(filter: { [key: string]: ActiveFilter }) {
        filters.active = {};
        vue.set(filters, 'active', filter);
    },
    setupFilter(key: string, filter: object) {
        vue.set(filters.active, key, filter);
    },
    updateFilterValues(key: string, values: string[]) {
        vue.set(filters.active[key], 'values', values);
        // filters.active[key].values = values;
    },
    updateFilter(key: string, value: string) {
        filters.active[key].values.push(value);
    },
    setFilterType(key: string, type: string) {
        vue.set(filters.active[key], 'filterType', type);
    },
    removeFilterValue(key: string, value: string) {
        if (filters.active[key].values.length > 1) {
            const index = filters.active[key].values.findIndex((i: string) => i === value);
            if (index > -1) filters.active[key].values.splice(index, 1);
        } else {
            vue.delete(filters.active, key);
        }
    },
    removeFilter(key: string) {
        vue.delete(filters.active, key);
    },
    updateNumberFilter(key: string, valueKey: string, value: number) {
        filters.active[key].values[0][valueKey] = value;
    },
    removeNumberFilterValue(key: string, value: string) {
        if (Object.prototype.hasOwnProperty.call(filters.active, key)) {
            if (Object.entries(filters.active[key].values[0].constructor === Object)) {
                vue.delete(filters.active, key);
            }
        }
    },
    resetDefaults() {
        filters.active = {};
        filters.config = [];
        filters.default = {};
        filters.linked = null;
    },
};

export const updateDefaultFilters = {
    setupFilter(key: string, filter: object) {
        vue.set(filters.default, key, filter);
    },
};
