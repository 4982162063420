export default class EditComments {
    id: string;

    DataSetId: string;

    commentId: string;

    date: string;

    replyId: string;

    comment: string;

    constructor (id: string, DataSetId: string, commentId: string, date: string, replyId: string, comment: string) {
        this.id = id;
        this.DataSetId = DataSetId;
        this.commentId = commentId;
        this.date = date;
        this.replyId = replyId;
        this.comment = comment;
    }
}
