
import {
    Component, Vue, Prop, Watch,
} from 'vue-property-decorator';
import PrettyCheck from 'pretty-checkbox-vue/check';
import Multiselect from 'vue-multiselect';
import { reservingClassFiltersAndSorting } from '@/observables/ReservingClassTableFiltersAndSorting';
import DateTimeOption from '@/common/DateTimeOption';
import FilterQuery from '@/common/ReservingClasses/FilterQuery';
import { scrollToSelected } from '@/functions/tools';

const cloneDeep = require('clone-deep');

interface SortOption {
    name: string;
    text: string;
}

@Component({ components: { PrettyCheck, Multiselect } })
export default class FilterAndSortingPopoverContent extends Vue {
    @Prop() filterConfig!: any;

    @Prop() filter?: any;

    @Prop(Boolean) headerFilter?: boolean;

    @Prop(Boolean) sort?: boolean;

    public scrollToSelected = scrollToSelected;

    public sortOptions: SortOption[] = [{ name: 'ascending', text: 'ASC' }, { name: 'descending', text: 'DSC' }];

    public filterOptions: SortOption[] = [{ name: 'name', text: 'Select by name' }, { name: 'string', text: 'Match by string' }];;

    public filterBetween: Record<string, number|null> = { from: null, to: null }

    public fromCheck = false;

    public toCheck = false;

    public textFilter: SortOption[] = [];

    public selectedFilters: string[] = [];

    public selectedSort: string = '';

    public selectedFilterType: string = 'name';

    public stringMatchFilters = [
        { label: 'Name starts with', name: 'begins' },
        { label: 'Name contains', name: 'contains' },
        { label: 'Name ends with', name: 'ends' },
    ]

    public stringFilterType = this.stringMatchFilters[1];

    public stringFilter = '';

    public closePopover(): void {
        if ([
            this.hasSortChanged,
            this.hasSelectedFiltersChanged,
            this.hasBetweenFiltersChanged,
            this.hasStringFilter,
        ].some((elem) => elem === true)) return;
        if (this.sort) {
            this.$emit('update-sort-popover-open', false);
        } else { this.$emit('update-popover-open', false); }
    }

    public oldFilterBetween: Record<string, number|null> = { from: null, to: null }

    public oldSelectedFilters: string[] = [];

    public oldSelectedSort: string = ''

    get hasMultipleSelectableDropdown():boolean {
        return this.filterConfig.text === 'Reserving Class'
                || (this.filterConfig.formatGroup === 'string' && this.filterConfig.filterOptions
                    && this.filterConfig.filterOptions.length > 3);
    }

    get uniqueFilterOptions () {
        return [...new Set(this.filterConfig.filterOptions.map((item) => item.name))];
    }

    get reservingClassFiltersAndSorting() {
        return reservingClassFiltersAndSorting;
    }

    get dataType() {
        return this.filterConfig.formatGroup;
    }

    get sortedDateOptions() {
        if (this.dataType === 'dateTime' && this.filterConfig.filterOptions) {
            const sorted = cloneDeep(this.filterConfig.filterOptions).sort((a, b) => a.name - b.name);
            const uniqueSorted = [...new Map(sorted.map((item) => [item.name, item])).values()];
            return uniqueSorted;
        }
        return [];
    }

    handleSelectDateOption(selectedOption: DateTimeOption, target: string) {
        Vue.set(this.filterBetween, target, selectedOption.name);
    }

    selectedDateOption(target: string) {
        const optionIndex = this.sortedDateOptions.findIndex((dateOption) => dateOption.name === this.filterBetween[target]);
        if (optionIndex > -1) return this.sortedDateOptions[optionIndex];
        return null;
    }

    handleDelectDateOption(target: string) {
        Vue.set(this.filterBetween, target, null);
    }

    get isTableHeaderActive() {
        const hasFilter = this.reservingClassFiltersAndSorting.filter.some((filter) => filter.text === this.filterConfig.text);
        const hasSort = this.reservingClassFiltersAndSorting.sort.some((sort) => sort.text === this.filterConfig.text);
        const hasBetweenFilter = this.reservingClassFiltersAndSorting.filter.some((betweenFilter) => betweenFilter.text === this.filterConfig.text);
        return { hasFilter: this.dataType === 'string' ? hasFilter : false, hasSort, hasBetweenFilter: [...this.numberTypes, 'dateTime'].includes(this.dataType) ? hasBetweenFilter : false };
    }

    get sortQuery() {
        return {
            name: this.filterConfig.name, order: this.selectedSort, text: this.filterConfig.text, filterConfig: this.filterConfig,
        };
    }

    get filterQuery(): FilterQuery {
        const newQuery = {
            values: undefined,
            stringMatchType: undefined,
            stringMatch: undefined,
            ends: undefined,
            name: this.filterConfig.name,
            text: this.filterConfig.text,
            filterConfig: this.filterConfig,
            filterOption: this.filterConfig.filterOptions,
        };
        if (this.selectedFilterType === 'name') {
            newQuery.values = this.selectedFilters;
        } else {
            newQuery.stringMatch = this.stringFilter;
            newQuery.stringMatchType = this.stringFilterType.name;
        }

        return newQuery;
    }

    get betweenFilterQuery() {
        const filterBetweenQuery: any = { ...this.filterQuery };
        if (this.filterBetween.to || this.filterBetween.from || [0].includes(this.filterBetween.to) || [0].includes(this.filterBetween.from)) {
            filterBetweenQuery.values = [this.filterBetween];
            return filterBetweenQuery;
        }
        return filterBetweenQuery;
    }

    public toggleSort(sort: string) {
        if (this.selectedSort === sort) {
            this.selectedSort = '';
            return;
        }
        this.selectedSort = sort;
    }

    public numberTypes: string[] = ['percentage', 'number', 'ratio', 'currency', 'trendConfidencePercentage'];

    public toggleSelectedFilter(filter: string) {
        const index = this.selectedFilters.indexOf(filter);
        if (index === -1) {
            return this.selectedFilters.push(filter);
        } return this.selectedFilters.splice(index, 1);
    }

    get isBetweenFilterActive(): boolean {
        return (this.filterBetween.from !== null && this.filterBetween.to !== null);
    }

    public activeFilterOptionStyle(filterOption) {
        return this.selectedFilters.includes(filterOption.name) || this.filter?.values.includes(filterOption.name) || this.selectedFilters.includes(filterOption.text);
    }

    @Watch('oldFilterBetween', { deep: true })
    setSlider() {
        if (this.oldFilterBetween?.to === null) {
            this.toCheck = false;
        } else this.toCheck = true;
        if (this.oldFilterBetween?.from === null) {
            this.fromCheck = false;
        } else this.fromCheck = true;
    }

    public applyFilterAndSorting(): void {
        if (this.hasSortChanged) {
            if (this.selectedSort === '') {
                this.$emit('update-sorting', { sortQuery: this.sortQuery, action: 'remove' });
            } else this.$emit('update-sorting', { sortQuery: { ...this.sortQuery }, action: 'add' });
        }

        if (this.dataType === 'string' && this.hasSelectedFiltersChanged) {
            if (this.selectedFilters.length === 0) {
                this.$emit('update-filters', { filterQuery: cloneDeep(this.filterQuery), action: 'remove' });
            } else this.$emit('update-filters', { filterQuery: cloneDeep(this.filterQuery), action: 'add' });
        }

        if (this.dataType === 'string' && this.hasStringFilter) {
            this.$emit('update-filters', { filterQuery: cloneDeep(this.filterQuery), action: 'add' });
        }

        if ([...this.numberTypes, 'dateTime'].includes(this.dataType) && this.hasBetweenFiltersChanged) {
            if (this.filterBetween.from === null && this.filterBetween.to === null) {
                this.$emit('update-filters', { filterQuery: cloneDeep(this.betweenFilterQuery), action: 'remove' });
            } else this.$emit('update-filters', { filterQuery: cloneDeep(this.betweenFilterQuery), action: 'add' });
        }
    }

    public cancelApplyFilterAndSorting(): void {
        this.filterBetween = { ...this.oldFilterBetween };
        this.selectedFilters = [...this.oldSelectedFilters];
        this.selectedSort = this.oldSelectedSort;
        this.$emit('hide');
        // TODO take account for the to/forcheck
    }

    @Watch('reservingClassFiltersAndSorting', { deep: true, immediate: true })
    updateSharedData() {
        const sortStore = this.reservingClassFiltersAndSorting.sort;
        const sortIndex = sortStore.findIndex((elem) => elem.text === this.filterConfig.text);
        if (this.dataType === 'string') {
            if (this.filter?.values?.length) {
                this.oldSelectedFilters = [...this.filter.values];
                this.selectedFilters = [...this.filter.values];
                this.updateTextFilter();
            }
        }
        if ([...this.numberTypes, 'dateTime'].includes(this.dataType)) {
            if (this.filter?.values) {
                this.oldFilterBetween = cloneDeep(this.filter?.values[0]);
                this.filterBetween = cloneDeep(this.filter?.values[0]);
            }
        }
        if (sortIndex > -1) {
            this.oldSelectedSort = sortStore[sortIndex].order;
            this.selectedSort = sortStore[sortIndex].order;
        }

        if (sortIndex === -1) {
            this.selectedSort = '';
            this.oldSelectedSort = '';
        }
    }

    @Watch('fromCheck', { deep: true })
    setFromToNull() {
        if (!this.fromCheck && this.filterBetween.from !== null) this.filterBetween.from = null;
    }

    @Watch('toCheck', { deep: true })
    setToToNull() {
        if (!this.toCheck && this.filterBetween.to !== null) this.filterBetween.to = null;
    }

    created() {
        if (this.filter && this.filter.stringMatch && this.filter.stringMatchType) {
            this.stringFilter = this.filter.stringMatch;
            this.stringMatchFilters.forEach((filt) => {
                if (filt.name === this.filter.stringMatchType) {
                    this.stringFilterType = filt;
                }
            });
            this.selectedFilterType = 'string';
        }
    }

    @Watch('filter', { deep: true })
    resetIfNoFilter() {
        if (this.filter === null) {
            this.filterBetween = { from: null, to: null };
            this.selectedFilters = [];
            this.oldFilterBetween = { from: null, to: null };
            this.toCheck = false;
            this.fromCheck = false;
            this.oldSelectedFilters = [];
            this.textFilter = [];
            this.stringFilter = '';
            this.stringFilterType = this.stringMatchFilters[1];
        }
        // else if (this.filter.stringMatch && this.filter.stringMatchType) {
        //     this.stringFilter = this.filter.stringMatch;
        //     this.stringFilterType = this.filter.stringMatchType;
        //     this.selectedFilterType = 'string';
        // }
    }

    get hasSortChanged() {
        return this.oldSelectedSort !== this.selectedSort;
    }

    get hasSelectedFiltersChanged() {
        return this.oldSelectedFilters.sort().join(',') !== this.selectedFilters.sort().join(',');
    }

    get hasBetweenFiltersChanged() {
        if ([...this.numberTypes, 'dateTime'].includes(this.dataType)) return (this.oldFilterBetween?.to !== this.filterBetween?.to) || (this.oldFilterBetween?.from !== this.filterBetween?.from);
        return false;
    }

    get hasStringFilter() {
        return this.stringFilter.length > 0 && this.stringFilterType && (this.filter == null || this.stringFilter !== this.filter.stringMatch || this.stringFilterType.name !== this.filter.stringMatchType);
    }

    get hasOptionChaged() {
        return [
            this.hasSortChanged,
            this.hasSelectedFiltersChanged,
            this.hasBetweenFiltersChanged,
            this.hasStringFilter,
        ].some((elem) => elem === true);
    }

    @Watch('hasOptionChaged', { deep: true, immediate: true })
    allowPopoverAutoHide() {
        this.$emit('update-auto-hide', !this.hasOptionChaged);
    }

    @Watch('textFilter')
    addTextFilters() {
        if (this.textFilter.length > 0) {
            this.selectedFilters = this.textFilter.map((elem) => elem.name);
        } else { this.selectedFilters = []; }
    }

    private updateTextFilter(): void {
        if (this.selectedFilters.length > 0) {
            this.textFilter = this.selectedFilters.map((elem) => ({ name: elem, text: elem }));
        } else this.textFilter = [];
    }

    public toggleFilterType(newType: string): void {
        this.selectedFilterType = newType;
        if (newType === 'string') {
            this.textFilter = [];
        } else {
            this.stringFilter = '';
            this.stringFilterType = this.stringMatchFilters[1];
        }
    }
}
