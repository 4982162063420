export default class ErsIdentifiers {
    segmentationId: string;

    scenarioId: string;

    reservingClass: string;

    ersSection: string;

    filters: object;

    constructor(segmentationId: string, scenarioId: string, reservingClass: string, ersSection: string, filters: object) {
        this.segmentationId = segmentationId;
        this.scenarioId = scenarioId;
        this.reservingClass = reservingClass;
        this.ersSection = ersSection;
        this.filters = filters;
    }
}
