
import { Component, Vue } from 'vue-property-decorator';
import QueryDataSet from '@/common/QueryDataSet';
import { config, updateConfig } from '@/observables/Config';
import { filters } from '@/observables/Filters';
import { mainData, updateMainData } from '@/observables/MainData';
import { trends } from '@/observables/Trends';
import { ui, updateUI } from '@/observables/UI';
import ErrorModal from '@/common/ErrorModal';
import Http from '@/auth/api/apiHelper';

@Component
export default class ChartControls extends Vue {
    first () {
        updateConfig.updateStart(0);
        this.updateChartData();
    }

    next () {
        updateConfig.updateStart(this.nextStart);
        this.updateChartData();
    }

    previous () {
        updateConfig.updateStart(this.previousStart);
        this.updateChartData();
    }

    async updateChartData () {
        try {
            let chartCount: number = ui.columns * ui.rows;

            if (config.drilldownLevel) {
                chartCount -= 1;
            }
            updateUI.setUpdatingCharts(true);
            const url: string = `${process.env.VUE_APP_API}/api/QueryFilterDataSet`;
            const query: QueryDataSet = new QueryDataSet(
                config.dataSetId,
                config.start,
                chartCount,
                filters.active,
                config.tags,
                trends.active,
                [config.sortOption],
                config.drilldownConfig.chartId,
                true,
            );
            const filteredData = await Http.post<any>(url, query, { apiScope: [process.env.VUE_APP_B2C_SCOPE] });

            if (config.drilldownLevel > 0) {
                filteredData.data.unshift(config.drilldownConfig);
            }
            updateMainData.setMainData(filteredData.data);
            updateUI.setUpdatingCharts(false);
        } catch (error) {
            updateUI.updateErrorModal((new ErrorModal('', 'welcome', 'Welcome')));
            updateUI.setUpdatingCharts(false);
        }
    }

    get nextStart (): number {
        return this.start + this.max - (config.drilldownLevel > 0 ? 1 : 0);
    }

    get previousStart (): number {
        return this.start - (this.max - (config.drilldownLevel > 0 ? 1 : 0));
    }

    get start (): number {
        return config.start;
    }

    get max (): number {
        return ui.columns * ui.rows;
    }

    get totalChartCount () {
        return config.chartCount;
    }

    get chartCount () {
        return mainData.chartData.length - (config.drilldownLevel > 0 ? 1 : 0);
    }
}
