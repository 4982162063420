
import { Vue, Component } from 'vue-property-decorator';
import multiselect from 'vue-multiselect';
import { ui, updateUI } from '@/observables/UI';
import Http from '@/auth/api/apiHelper';
import { filters } from '@/observables/Filters';
import { trends } from '@/observables/Trends';
import { config } from '@/observables/Config';
import Card from '@/components/layout/card/Card.vue';
import ErrorModal from '@/common/ErrorModal';
import Choice from '@/common/Choice';
import { scrollToSelected } from '@/functions/tools';
import { SetShortcut } from '@/functions/WelcomeShortcuts';
import BaseButton from '../../base/BaseButton.vue';
import LoaderModal from './LoaderModal.vue';

const cloneDeep = require('clone-deep');

interface IWelcomeQuery {
    welcomeId:string;

    text: string;

    canDelete: boolean;
}

interface IApiResponse {
    isValid: boolean;

    statusMessage: string;
}

@Component({
    components: {
        multiselect,
        BaseButton,
        LoaderModal,
        Card,
    },
})
export default class WelcomeButtonsModal extends Vue {
    loading = false;

    public scrollToSelected = scrollToSelected;

    // #region - Dropdown options
    existingOptions: Array<IWelcomeQuery> = null;

    selectedWelcome: IWelcomeQuery = null;

    created() {
        this.getWelcomeButtons();
        this.loadDataSetOptions();
    }

    get currentShortcut(): Choice | null {
        return ui.currentShortcut || null;
    }

    get expanded(): boolean {
        return this.selectedType === 'Edit' || this.selectedType === 'Remove' || this.selectedType === 'Add';
    }

    get showTextInput(): boolean {
        return this.selectedType === 'Edit' || this.selectedType === 'Add';
    }

    get showSelectedText(): boolean {
        return this.selectedType === 'Edit' || this.selectedType === 'Remove';
    }

    getWelcomeButtons (): void {
        if (this.existingOptions === null) {
            this.loading = true;
            Http.post<Array<IWelcomeQuery>>(
                `${process.env.VUE_APP_API}/api/GetWelcomeButtons`,
                {
                    dataSetId: config.dataSetId,
                },
                {
                    apiScope: [process.env.VUE_APP_B2C_SCOPE],
                },
            ).then((chartConfig) => {
                this.existingOptions = chartConfig.data.filter((option) => option.canDelete !== false);
                this.loading = false;
            }).catch(() => {
                this.loading = false;
                this.statusMessage = 'An error has occurred.';
            });
        }
    }
    // #endregion

    selectedType = null;

    updateType (type: string, shortcut?: IWelcomeQuery): void {
        this.selectedType = type;
        if (type !== 'Add') {
            this.selectedWelcome = shortcut;
            this.updateSelections(shortcut);
        }
        if (this.statusMessage !== null) this.statusMessage = null;
        // if (type === 'Edit' || type === 'Remove' || type === 'Add') this.getWelcomeButtons();
    }

    viewShortcut(option: IWelcomeQuery) {
        this.currentShortcut.text = option.text;
        const shortcutIndex = this.shortcutConfigs.findIndex((shortcut) => shortcut.welcomeId === option.welcomeId);
        if (shortcutIndex !== -1) {
            SetShortcut(this.shortcutConfigs[shortcutIndex]);
            updateUI.updateCurrentShortcut(cloneDeep(this.shortcutConfigs[shortcutIndex]));
        }
    }

    statusMessage: string = null;

    welcomeName: string = null;

    welcomeId: string = null;

    confirmRemoval: string = null;

    // #region format trend and filters for api call

    get filters() : any {
        return filters;
    }

    get formattedFilters(): any {
        const formattedFilters = [];
        Object.keys(filters.active).forEach((key) => {
            const filterObject = {
                name: key,
                values: filters.active[key].values,
            };
            formattedFilters.push(filterObject);
        });
        return formattedFilters.length > 0 ? formattedFilters : null;
    }

    get trends(): any {
        return trends;
    }

    get formattedTrends(): any {
        const formattedTrends = {};
        Object.keys(trends.active).forEach((trendKey) => {
            const trendObject = {
                show: true,
            };
            Object.keys(trends.active[trendKey]).forEach((trendSubKey) => {
                if (trends.active[trendKey][trendSubKey].values[0]) {
                    trendObject[trendSubKey] = trends.active[trendKey][trendSubKey].values[0];
                }
            });

            formattedTrends[trendKey] = trendObject;
        });
        return Object.keys(formattedTrends).length > 0 ? formattedTrends : null;
    }

    get labels(): any {
        return config.labels;
    }

    get formattedLabels(): any {
        return this.labels.map((label) => (
            {
                name: label.item,
                labelSelect: label.checked || false,
            }
        ));
    }

    get currentTags () {
        return config.tags;
    }

    // #endregion

    doesShortcutExist (shortcut: string) {
        return this.existingOptions.some((option) => option.text.toLowerCase() === shortcut.toLowerCase());
    }

    updateWelcomeButtons () {
        this.loading = true;
        this.statusMessage = null;

        if (this.doesShortcutExist(this.welcomeName) && this.selectedType === 'Add') {
            this.statusMessage = 'Shortcut already exists.';
            this.loading = false;
            return;
        }

        const request = {
            dataSetId: config.dataSetId,
            welcomeId: this.welcomeId,
            text: this.welcomeName,
            grid: [ui.rows, ui.columns],
            dataLabels: this.formattedLabels,
            filter: this.formattedFilters,
            trend: this.formattedTrends,
            sort: [config.sortOption],
            tags: this.currentTags,
        };
        Http.post<IApiResponse>(
            `${process.env.VUE_APP_API}/api/UpdateWelcomeButtons`,
            request,
            { apiScope: [process.env.VUE_APP_B2C_SCOPE] },
        ).then((response) => {
            if (response.data.isValid) {
                // this.restoreSelection();
                this.statusMessage = 'Shortcuts updated. These are available for you in the welcome screen.';
                this.existingOptions = null;
                // update top bar name if it's the current shortcut
                if (this.welcomeId === this.currentShortcut.welcomeId) {
                    updateUI.updateCurrentShortcutName(this.welcomeName);
                }
            } else {
                this.statusMessage = response.data.statusMessage;
            }
            this.getWelcomeButtons();
            this.loadDataSetOptions();
            this.loading = false;
        }).catch(() => {
            this.statusMessage = 'An error has occurred.';
            this.loading = false;
        });
    }

    canDelete = false;

    updateSelections (selection: IWelcomeQuery) {
        this.welcomeName = selection.text;
        this.welcomeId = selection.welcomeId;
        this.canDelete = selection.canDelete;
    }

    removeWelcomeButton () {
        this.statusMessage = null;
        this.loading = true;
        this.confirmRemoval = '';

        const request = {
            dataSetId: config.dataSetId,
            welcomeId: this.welcomeId,
        };
        Http.post<IApiResponse>(
            `${process.env.VUE_APP_API}/api/RemoveWelcomeButton`,
            request,
            { apiScope: [process.env.VUE_APP_B2C_SCOPE] },
        ).then((response) => {
            if (response.data.isValid) {
                this.restoreSelection();
                this.existingOptions = null;
                this.statusMessage = 'The shortcut was successfully removed.';
            } else {
                this.statusMessage = response.data.statusMessage;
            }
            this.loading = false;
        }).catch(() => {
            this.loading = false;
            this.statusMessage = 'An error has occurred.';
        });
    }

    restoreSelection () {
        this.selectedType = null;
        this.selectedWelcome = null;
        this.welcomeName = null;
        this.welcomeId = null;
        this.confirmRemoval = '';
    }

    get selectedOption () {
        switch (this.selectedType) {
        case 'Add':
            return {
                title: 'Add a new shortcut based on your current view.',
                text: 'Add new shortcut',
                disabled: this.welcomeName === null,
                func: this.updateWelcomeButtons,
            };
        case 'Edit':
            return {
                title: 'Edit an existing shortcut with your current view or rename an existing shortcut.',
                text: 'Update',
                disabled: this.welcomeName === null || this.welcomeId === null,
                func: this.updateWelcomeButtons,
            };
        case 'Remove':
            return {
                title: 'Remove an existing shortcut.',
                text: 'Remove',
                disabled: this.welcomeId === null || this.confirmRemoval !== 'Remove' || !this.canDelete,
                func: this.removeWelcomeButton,
            };
        default:
            return null;
        }
    }

    shortcutConfigs = [];

    async loadDataSetOptions () {
        return Http.post<any>(
            `${process.env.VUE_APP_API}/api/QueryChartConfig`,
            {
                dataSetId: config.dataSetId,
            },
            {
                apiScope: [process.env.VUE_APP_B2C_SCOPE],
            },
        ).then((configs) => {
            this.shortcutConfigs = configs.data.welcomeButtons;
        }).catch(() => {
            updateUI.updateErrorModal((new ErrorModal('', 'welcome', 'Welcome Page')));
        });
    }
}
