import Trend from '@/common/Chart/Trend';
import Info from '@/common/Chart/Info';
import Data from '@/common/Chart/Data';

export default class MainConfig {
    chartId: string;

    info!: Info;

    data?: Data;

    series?: { [key: string]: any };

    seriesOptions?: { [key: string]: any };

    trend?: { [key: string]: { [key: string]: Trend } };

    xAxis?: { categories: string[] }

    yAxisType?: string;

    yText?: string;

    constructor(chartId: string, info: Info, data: Data, series: { [key: string]: any }, trend: { [key: string]: { [key: string]: Trend } }) {
        this.chartId = chartId;
        this.info = info;
        this.data = data;
        this.series = series;
        this.trend = trend;
    }
}
