
import { Vue, Component } from 'vue-property-decorator';
import VTooltip from 'v-tooltip';
import { tooltipSettings } from '@/functions/objects';
import { ui, updateUI } from '@/observables/UI';
import { config } from '@/observables/Config';
import { projections } from '@/observables/Projections';
import ProjectionsConfigSideBar from '@/components/layout/projections/ProjectionsConfigSideBar.vue';
import { loggedIn } from '@/auth/MsalAuth';

Vue.use(VTooltip, tooltipSettings);

@Component({ components: { ProjectionsConfigSideBar } })
export default class SideBar extends Vue {
    extendBarToggle (type: string) {
        if (ui.sideBarType === type) {
            updateUI.updateSideBarType('');
            updateUI.toggleSideBar();
        } else {
            updateUI.updateSideBarType(type);
            updateUI.setShowSideBar(true);
        }
        return type;
    }

    get isProjectionsView(): boolean {
        return this.$route.meta?.routeIsPredict;
    }

    get routeRequiresAuth(): boolean {
        return this.$route.meta?.requiresAuth;
    }

    get segmentationName () {
        return projections.segmentationName;
    }

    get currentPage () {
        return config.currentPage;
    }

    activeItem (type: string) {
        return ui.sideBarType === type;
    }

    get loggedIn (): boolean {
        return loggedIn();
    }

    get currentRoute () {
        return this.$route;
    }
}
