
import { Component, Vue } from 'vue-property-decorator';
import { config } from '@/observables/Config';
import ProjectionsReviewSaveModal from '../modals/ProjectionsReviewSaveModel.vue';

interface ProjectionSteps {
    text: string;
    url: string;
    name: string;
}
Component.registerHooks([
    'beforeRouteEnter',
    'beforeRouteLeave',
]);

@Component({
    components: {
        ProjectionsReviewSaveModal,
    },
})
export default class ProjectionsConfigNavigation extends Vue {
    public projectionLoading: boolean = false;

    beforeRouteEnter (_to, from, next) {
        next((vm) => {
            vm.projectionLoading = false;
        });
    }

    beforeRouteLeave (_to, from, next) {
        next((vm) => {
            vm.projectionLoading = true;
        });
    }

    public projectionSteps: ProjectionSteps[] = [
        { text: 'Patterns Select', url: 'patterns-select', name: 'patternsSelect' },
        { text: 'Patterns Trending', url: 'patterns-trending', name: 'patternsTrending' },
        { text: 'Patterns Exclusions', url: 'patterns-exclusions', name: 'patternsExclusions' },
        { text: 'Initial Expecteds', url: 'initial-expecteds', name: 'initialExpecteds' },
        { text: 'Method Selection', url: 'method-selection', name: 'methodSelection' },
        { text: 'Review & Save', url: 'projections-review', name: 'projectionsReview' },
    ];

    public showSaveModal: boolean = false;

    reviewAction () {
        this.showSaveModal = true;
    }

    get permissions () {
        return config.permissions;
    }
}
