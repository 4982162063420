export default class ErrorModal {
    errorMsg?: string;

    backTo?: string;

    backToText?: string;

    canClose?: boolean;

    warning?: boolean;

    success?: boolean;

    question?: string;

    confirmText?: string;

    cancelText?: string;

    confirmCallBack?: ()=> void;

    cancelCallBack?: ()=> void;

    constructor(
        errorMsg: string,
        backTo: string,
        backToText: string,
        canClose: boolean = false,
        warning: boolean = false,
        success = false,
        question: string = '',
        confirmText: string = '',
        cancelText: string = '',
        confirmCallBack = () => null,
        cancelCallBack = () => null,
    ) {
        this.errorMsg = errorMsg;
        this.backTo = backTo;
        this.backToText = backToText;
        this.canClose = canClose;
        this.warning = warning;
        this.success = success;
        this.question = question;
        this.confirmText = confirmText;
        this.cancelText = cancelText;
        this.confirmCallBack = confirmCallBack;
        this.cancelCallBack = cancelCallBack;
    }
}
